import { red } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const BangCircle = ({ width = '16px', height = '16px', fill = red[80] }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.19385 10.0737H8.92676L9.19971 3.75781H6.9209L7.19385 10.0737ZM7.19385 11.2734C6.96956 11.4723 6.85742 11.7284 6.85742 12.0415C6.85742 12.3547 6.96956 12.6128 7.19385 12.8159C7.42236 13.0148 7.70589 13.1143 8.04443 13.1143C8.38298 13.1143 8.66439 13.0148 8.88867 12.8159C9.11719 12.6128 9.23145 12.3547 9.23145 12.0415C9.23145 11.7284 9.11719 11.4723 8.88867 11.2734C8.66439 11.0703 8.38298 10.9688 8.04443 10.9688C7.70589 10.9688 7.42236 11.0703 7.19385 11.2734Z"
      fill={fill}
    />
  </svg>
)

export { BangCircle }
