import { Eyebrow, grey, white } from '@pelotoncycle/design-system'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type TTransformReturnType = ReactNode | string

type TTransforms<T> = {
  [K in keyof T]: (arg: T[K], row?: T) => TTransformReturnType
}

type TCustomHeaders<T> = {
  [K in keyof T]: string
}

interface TWithId {
  id: string | number
}

type TProps<DataItem> = {
  data: ReadonlyArray<DataItem>
  columns: (keyof DataItem)[]
  customHeaders?: Partial<TCustomHeaders<DataItem>>
  transforms?: Partial<TTransforms<DataItem>>
  testId?: string
  variant?: 'solid' | 'outline'
  onRowClick?: (params: TWithId) => void
}

// type ReactNode = ReactElement | string | number | ReactFragment | ReactPortal | boolean | null | undefined;
function isReactNodeCompatible(element: unknown): element is ReactNode {
  const renderablePrimitiveTypes = ['string', 'number', 'boolean', 'null', 'undefined']

  return renderablePrimitiveTypes.indexOf(typeof element) > -1
}

const QueryResultTable = <TDataItem extends TWithId>({
  data,
  columns,
  customHeaders,
  transforms,
  testId,
  variant,
  onRowClick,
}: TProps<TDataItem>) => {
  const { t } = useTranslation()
  const StyledTable = variant === 'solid' ? SolidTable : OutlineTable

  if (!data) return <div>No data</div>

  const renderDataForColumn = (rowData: TDataItem, column: keyof TDataItem) => {
    const transform = transforms && transforms[column]

    let display
    if (transform && typeof transform === 'function') {
      display = transform(rowData[column], rowData)
    } else if (isReactNodeCompatible(rowData[column])) {
      // using the type assertion below only after typeguard confirms
      // that we have a value react knows how to render
      display = rowData[column] as unknown as ReactNode
    }

    return <div>{display}</div>
  }

  return (
    <StyledTable data-testid={testId}>
      <thead>
        <tr>
          {columns.map(colHead => {
            let colName = String(colHead)

            if (customHeaders?.[colHead]) {
              colName = customHeaders[colHead] as string
            }

            return (
              <Eyebrow key={colHead as string} is="th" size="small" display="table-cell">
                {t(`table_columns.${colName}`)}
              </Eyebrow>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row: TDataItem) => {
          const { id } = row

          return (
            <tr
              key={id}
              onClick={() => onRowClick && onRowClick({ id })}
              style={onRowClick ? { cursor: 'pointer' } : {}}
            >
              {columns.map(col => {
                const c = String(col)

                return <td key={`${c}-${row.id}`}>{renderDataForColumn(row, col)}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

const SolidTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 8px;
  padding-bottom: 80px;

  thead > tr {
    white-space: nowrap;
    background-color: transparent;
    height: 24px;
  }

  tr {
    height: 80px;
    background-color: ${white};
  }
  th {
    padding-left: 16px;
  }
  td {
    padding-left: 16px;
    line-height: 80px;
  }
`

const OutlineTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  color: ${grey[90]};

  tr {
    border-top: 1px solid ${grey[40]};
    border-bottom: 1px solid ${grey[40]};

    &:last-child {
      border-top: none;
      border-bottom: none;
    }
  }

  th,
  td {
    border: 1px solid ${grey[40]};
    border-top: none;
    border-bottom: none;
    padding: 16px 24px;

    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
`

export { QueryResultTable }
