import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { NotFoundPage } from 'ui/components'
import {
  BenefitAdmin,
  Documents,
  GettingStarted,
  Support,
  Templates,
  Troubleshooting,
  Insights,
  Challenges,
} from 'ui/components/@web/pages'
import { isBrowser } from 'utils'
import {
  BENEFIT_ADMIN_ROUTE,
  INSIGHTS_ROUTE,
  CHALLENGES_BASE_ROUTE,
  CHALLENGES_ROUTE,
  DOCUMENTS_ROUTE,
  ROOT_REDIRECT,
  SUPPORT_GETTING_STARTED_ROUTE,
  SUPPORT_ROUTE,
  SUPPORT_TEMPLATES_ROUTE,
  SUPPORT_TROUBLESHOOTING_ROUTE,
} from 'utils/constants/routes'

const Routes = () => {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (isBrowser) {
        window.scrollTo(0, 0)
      }
    })

    return unlisten
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      <Route path={DOCUMENTS_ROUTE}>
        <Documents />
      </Route>

      <Route exact path={INSIGHTS_ROUTE}>
        <Insights />
      </Route>

      <Route exact path={CHALLENGES_ROUTE}>
        <Challenges />
      </Route>

      <Redirect exact from={CHALLENGES_BASE_ROUTE} to={INSIGHTS_ROUTE} />

      <Route path={BENEFIT_ADMIN_ROUTE}>
        <BenefitAdmin />
      </Route>

      <Route exact path={SUPPORT_ROUTE}>
        <Support />
      </Route>

      <Route exact path={SUPPORT_GETTING_STARTED_ROUTE}>
        <GettingStarted />
      </Route>

      <Route exact path={SUPPORT_TROUBLESHOOTING_ROUTE}>
        <Troubleshooting />
      </Route>

      <Route exact path={SUPPORT_TEMPLATES_ROUTE}>
        <Templates />
      </Route>

      <Redirect exact from="/" to={ROOT_REDIRECT} />

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

export { Routes }
