import { black } from '@pelotoncycle/design-system'
import { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'ui/style'

const cssVariables = css`
  :root {
    --header-height: 72px;
    --theme-primary: ${black};
  }
`

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${cssVariables}

  body {
    font-family: 'Roboto', sans-serif;
    height: 100%;
  }
`

export { GlobalStyle }
