import { useApolloClient } from '@apollo/client'
import { FileWithPath } from 'file-selector'
import React from 'react'
import { FileRejection } from 'react-dropzone'
import { fileFactory, fileRunFactory } from '../factories'
import { GET_FILES } from '../queries'
import { File } from '../queries/types/File'
import { Files } from '../queries/types/Files'
import { FileStatus, FileRunStatus } from '../types/graphql-global-types'
import { useRestHeaders } from './useRestHeaders'

type TCallback = () => void
type TCallbackWithArg = (fileId: string) => void

type UseUploadFileProps = {
  onSuccess?: TCallbackWithArg
  onFailure?: TCallback
  clearAlerts: TCallback
  writeToCache: boolean
}

export type AddFileFunc = (
  acceptedFiles: FileWithPath[],
  rejectedFiles: FileRejection[],
) => void

const { REACT_APP_API_HOST = '' } = process.env

const UPLOAD_REST_ENDPOINT = `${REACT_APP_API_HOST}/api/file-upload`

const useUploadFile = ({
  onSuccess,
  onFailure,
  clearAlerts,
  writeToCache = false,
}: UseUploadFileProps) => {
  const { getHeaders } = useRestHeaders()
  const client = useApolloClient()
  const [responseData, setResponseData] = React.useState<File>()
  const [requestError, setRequestError] = React.useState<string | null>(null)
  const [newFileUploading, setNewFileUploading] = React.useState(false)

  // TS wants a synchronous function passed to Dropzone's onDrop, hence all the .then chaining
  const uploadFile: AddFileFunc = (acceptedFiles = [], rejectedFiles = []) => {
    clearAlerts()

    if (rejectedFiles.length) {
      return null
    }

    if (acceptedFiles.length) {
      const [file] = acceptedFiles

      if (file) {
        setNewFileUploading(true)
        const formData = new FormData()
        formData.append('file', file)

        const requestOptions = {
          method: 'POST',
          body: formData,
          headers: {},
        }

        getHeaders()
          .then(hdrs => {
            requestOptions.headers = hdrs

            fetch(UPLOAD_REST_ENDPOINT, requestOptions)
              .then(response => {
                const { ok, status, statusText } = response
                if (ok) {
                  return response.json()
                }
                throw new Error(JSON.stringify({ status, statusText }))
              })
              .then((res: File) => {
                if (res && res.file) {
                  setResponseData(res)
                  const uploadId = res?.file?.id
                  const queryOptions = { query: GET_FILES }

                  if (onSuccess) onSuccess(uploadId)

                  if (writeToCache) {
                    const filesInCache =
                      client.readQuery<Files>(queryOptions)?.files ?? []

                    const placeholderFileRun = fileRunFactory.build({
                      id: uploadId,
                      status: FileRunStatus.RECEIVING,
                      created: new Date().toISOString(),
                    })

                    const placeholderFile = fileFactory.build(
                      {
                        id: uploadId,
                        name: '--',
                        status: FileStatus.RUNNING,
                      },
                      { associations: { fileRuns: [placeholderFileRun] } },
                    )

                    client.writeQuery({
                      ...queryOptions,
                      data: {
                        files: [placeholderFile, ...filesInCache],
                      },
                    })
                  }
                }

                setNewFileUploading(false)
              })
              .catch(err => {
                setNewFileUploading(false)
                if (typeof err === 'string') {
                  setRequestError(err)
                }
                if (onFailure) onFailure()
              })
          })
          .catch(e => {
            /* eslint-disable-next-line no-console */
            console.log(e)
          })
      }
    }

    return null
  }

  const uploadedFile = responseData?.file

  return {
    uploadFile,
    uploadedFile,
    error: requestError,
    newFileUploading,
  }
}

export { useUploadFile }
