import { gql } from '@apollo/client'
import { ELIGIBLE_PERSON_FIELDS } from '../fragments'

const GET_ELIGIBLE_PERSONS_SEARCH = gql`
  ${ELIGIBLE_PERSON_FIELDS}
  query EligiblePersons($searchInput: EligiblePersonInput!, $numOfResults: Int!) {
    eligiblePersonSearch(searchInput: $searchInput, numOfResults: $numOfResults) {
      ...EligiblePersonFields
    }
  }
`

const GET_ELIGIBLE_PERSON = gql`
  ${ELIGIBLE_PERSON_FIELDS}
  query EligiblePerson($id: String!, $partnerHashId: String) {
    eligiblePersonById(personId: $id, partnerHashId: $partnerHashId) {
      ...EligiblePersonFields
    }
  }
`

const GET_ELIGIBLE_PERSON_LOOKUP = gql`
  ${ELIGIBLE_PERSON_FIELDS}
  query EligiblePersonLookup(
    $searchTerm: String!
    $searchFields: [EligiblePersonSearchFields]!
    $numOfResults: Int!
  ) {
    eligiblePersonLookup(
      searchTerm: $searchTerm
      searchFields: $searchFields
      numOfResults: $numOfResults
    ) {
      ...EligiblePersonFields
    }
  }
`

export { GET_ELIGIBLE_PERSONS_SEARCH, GET_ELIGIBLE_PERSON, GET_ELIGIBLE_PERSON_LOOKUP }
