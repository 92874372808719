import { grey } from '@pelotoncycle/design-system'
import { FileWithFileRuns } from 'data/fragments/types/FileWithFileRuns'
import { FileStatus } from 'data/types/graphql-global-types'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { formatDate } from 'utils'
import { BENEFIT_ADMIN_UPLOADS_ROUTE } from 'utils/constants/routes'
import { Box, Flex, StatusIcon, InlineStatus } from '../../..'
import { useWindowWidth } from '../../../../hooks'
import { BangCircle } from '../../../svg'

const FAILED_DISPLAY = '--'
const hoverClass = 'isHover'

const FileNameWithHover = ({ name, fileId }: { name: string; fileId: string }) => {
  const { t } = useTranslation()
  const [isNameTooLong, setIsNameTooLong] = useState(false)
  const { isAboveMobile } = useWindowWidth()
  const tdRef = useRef<HTMLDivElement>(null)
  const maxWidth = isAboveMobile ? 250 : 200
  // "disable" the link to the file details until file has been processed b/c polling

  useEffect(() => {
    if (tdRef && tdRef.current) {
      const nameTooLong = tdRef.current.scrollWidth >= maxWidth
      setIsNameTooLong(nameTooLong)
    }
  }, [tdRef, maxWidth])

  return (
    <Box position="relative" width={`${maxWidth}px`}>
      <WithOverflow
        maxWidth={maxWidth}
        ref={tdRef}
        onMouseEnter={() => isNameTooLong && tdRef.current?.classList.add(hoverClass)}
        onMouseLeave={() => isNameTooLong && tdRef.current?.classList.remove(hoverClass)}
      >
        {name}
      </WithOverflow>
      <Box height="24px" />
      <Box>
        <StyledLink
          data-testid="details-page-link"
          to={`${BENEFIT_ADMIN_UPLOADS_ROUTE}/${fileId}`}
        >
          {t('details')}
        </StyledLink>
      </Box>
    </Box>
  )
}

const Errors = ({ count }: { count: number }) => {
  const { t } = useTranslation()

  return (
    <WithIcon alignItems="center">
      {!!count && <BangCircle />}
      <span>{t('countXErrors', { count })}</span>
    </WithIcon>
  )
}

const Status = ({ status }: { status: FileStatus }) => {
  const { t } = useTranslation()
  const text = t(`api.file_status.${status.toLowerCase()}`)

  return (
    <InlineStatus text={text} width="88px">
      <StatusIcon status={status} />
    </InlineStatus>
  )
}

const defaultDisplayIfFailed = (status: FileStatus) =>
  status === FileStatus.FAILED && FAILED_DISPLAY

const tableHeadings = [
  {
    key: 'name',
    translationKey: 'file.file_name',
    render: (fileData: FileWithFileRuns) => {
      return <FileNameWithHover name={fileData.name} fileId={fileData.id} />
    },
  },
  {
    key: 'uploaded',
    translationKey: 'file.uploaded',
    render: (fileData: FileWithFileRuns) => {
      const fileRun = fileData?.fileRuns?.[0]

      return fileRun?.created && typeof fileRun.created === 'string'
        ? `${formatDate(new Date(fileRun.created))}`
        : 'no date given'
    },
  },
  {
    key: 'totalRecords',
    translationKey: 'file.total',
    render: (fileData: FileWithFileRuns) =>
      defaultDisplayIfFailed(fileData.status) || `${fileData.dataRows || 0}`,
  },
  {
    key: 'dropped',
    translationKey: 'file.dropped',
    render: (fileData: FileWithFileRuns) =>
      defaultDisplayIfFailed(fileData.status) ||
      `${fileData?.fileRuns?.[0].eligibilityRemoved || 0}`,
  },
  {
    key: 'added',
    translationKey: 'file.added',
    render: (fileData: FileWithFileRuns) =>
      defaultDisplayIfFailed(fileData.status) ||
      `${fileData?.fileRuns?.[0].eligibilityTotalAdded || 0}`,
  },
  {
    key: 'errors',
    translationKey: 'file.errors',
    render: (fileData: FileWithFileRuns) => {
      if (fileData.status === FileStatus.FAILED) {
        return FAILED_DISPLAY
      }

      const errorCount = fileData?.fileRuns?.[0].totalRowErrors || 0

      return <Errors count={errorCount} />
    },
  },
  {
    key: 'status',
    translationKey: 'status',
    render: (fileData: FileWithFileRuns) => {
      return <Status status={fileData.status} />
    },
  },
]

const WithIcon = styled(Flex)`
  svg + span {
    padding-left: 6px;
  }
`

const WithOverflow = styled.div<{ maxWidth: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ maxWidth }) => `${maxWidth}px`}};
  margin: 0 -4px;
  padding: 0 4px;
  position: absolute;
  display: inline;
  height: 24px;

  &.${hoverClass} {
      background-color: ${grey[30]};
      cursor: default;
      overflow: initial;
      text-overflow: initial;
      max-width: initial;
  }
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${grey[90]};

  &:hover {
    text-decoration: underline;
    color: ${grey[70]};
  }
`

export { tableHeadings }
