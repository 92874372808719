import { gql } from '@apollo/client'
import { FILE_RUN_FIELDS, FILE_RUN_WITH_FILE_RUN_ERROR_FIELDS } from './fileRun'

const FILE_FIELDS = gql`
  fragment FileFields on File {
    id
    name
    dataRows
    status
    errors
    rolledBackOn
  }
`

const FILE_WITH_FILE_RUNS = gql`
  ${FILE_FIELDS}
  ${FILE_RUN_FIELDS}
  fragment FileWithFileRuns on File {
    ...FileFields
    fileRuns {
      ...FileRunFields
    }
  }
`

const FILE_WITH_FILE_RUNS_AND_ERRORS = gql`
  ${FILE_FIELDS}
  ${FILE_RUN_WITH_FILE_RUN_ERROR_FIELDS}
  fragment FileWithFileRunsAndErrors on File {
    ...FileFields
    fileRuns {
      ...FileRunWithFileRunErrorFields
    }
  }
`
export { FILE_FIELDS, FILE_WITH_FILE_RUNS, FILE_WITH_FILE_RUNS_AND_ERRORS }
