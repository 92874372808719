import styled from 'styled-components'
import { IconProps } from './IconTypes'

interface ProcessingProps extends IconProps {
  animate?: boolean
  animationIterationCount?: string
}

const Processing = ({
  width = '14px',
  height = '14px',
  animate = false,
  animationIterationCount,
}: ProcessingProps) => (
  <AnimatedSvg
    width={width}
    height={height}
    animate={animate}
    animationIterationCount={animationIterationCount}
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M4.10938 10.8906C4.10938 10.3359 3.66406 9.89062 3.10938 9.89062C2.5625 9.89062 2.10938 10.3359 2.10938 10.8906C2.10938 11.4375 2.5625 11.8906 3.10938 11.8906C3.66406 11.8906 4.10938 11.4375 4.10938 10.8906ZM8 12.5C8 11.9453 7.55469 11.5 7 11.5C6.44531 11.5 6 11.9453 6 12.5C6 13.0547 6.44531 13.5 7 13.5C7.55469 13.5 8 13.0547 8 12.5ZM2.5 7C2.5 6.44531 2.05469 6 1.5 6C0.945313 6 0.5 6.44531 0.5 7C0.5 7.55469 0.945313 8 1.5 8C2.05469 8 2.5 7.55469 2.5 7ZM11.8906 10.8906C11.8906 10.3359 11.4375 9.89062 10.8906 9.89062C10.3359 9.89062 9.89063 10.3359 9.89063 10.8906C9.89063 11.4375 10.3359 11.8906 10.8906 11.8906C11.4375 11.8906 11.8906 11.4375 11.8906 10.8906ZM4.35938 3.10937C4.35938 2.42187 3.79688 1.85937 3.10938 1.85937C2.42188 1.85937 1.85938 2.42187 1.85938 3.10937C1.85938 3.79688 2.42188 4.35937 3.10938 4.35937C3.79688 4.35937 4.35938 3.79688 4.35938 3.10937ZM13.5 7C13.5 6.44531 13.0547 6 12.5 6C11.9453 6 11.5 6.44531 11.5 7C11.5 7.55469 11.9453 8 12.5 8C13.0547 8 13.5 7.55469 13.5 7ZM8.5 1.5C8.5 0.671874 7.82813 -9.53674e-07 7 -9.53674e-07C6.17188 -9.53674e-07 5.5 0.671874 5.5 1.5C5.5 2.32812 6.17188 3 7 3C7.82813 3 8.5 2.32812 8.5 1.5ZM12.6406 3.10937C12.6406 2.14844 11.8516 1.35937 10.8906 1.35937C9.92188 1.35937 9.14063 2.14844 9.14063 3.10937C9.14063 4.07812 9.92188 4.85937 10.8906 4.85937C11.8516 4.85937 12.6406 4.07812 12.6406 3.10937Z"
      fill="#A5A5A5"
    />
  </AnimatedSvg>
)

const AnimatedSvg = styled.svg<{ animate?: boolean; animationIterationCount?: string }>`
  animation: ${props =>
    props.animate
      ? `rotate 1.4s ${props.animationIterationCount || 'infinite'} linear`
      : 'none'};

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export { Processing }
