import { useAuth0 } from '@auth0/auth0-react'
import { Body, Support } from '@pelotoncycle/design-system'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorContainer } from './ErrorContainer'

type TRequestErrorProps = {
  error: { message: string }
}

const AuthError = ({ error }: TRequestErrorProps) => {
  const { t } = useTranslation()
  const { logout } = useAuth0()
  const defaultMessage = t('errors.unexpected_error')
  const message = error.message || defaultMessage
  const [willLogout, setWillLogout] = useState(false)

  useEffect(() => {
    let logoutTimeout: ReturnType<typeof setTimeout>
    let msgTimeout: ReturnType<typeof setTimeout>
    if (error) {
      msgTimeout = setTimeout(() => setWillLogout(true), 1500)
      logoutTimeout = setTimeout(() => logout(), 3000)
    }

    return () => {
      clearTimeout(msgTimeout)
      clearTimeout(logoutTimeout)
    }
  }, [error, logout])

  return (
    <ErrorContainer>
      <Body>{message}</Body>
      {willLogout && <Support size="large">{t('errors.logging_out')}</Support>}
    </ErrorContainer>
  )
}

export { AuthError }
