import { white, Link, Body } from '@pelotoncycle/design-system'
import { useLazyFinishers } from 'data/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { media } from 'ui/responsive'

type Props = {
  challengeId: string
}

type StatusProps = {
  status: string
}

const Status: React.FC<StatusProps> = ({ status }) => (
  <ExportStatus size="small">{status}</ExportStatus>
)

const FinishersExport: React.FC<Props> = ({ challengeId }) => {
  const { t } = useTranslation()
  const { exportFinishers, loading, data, error } = useLazyFinishers({ challengeId })

  let content
  if (!data?.finishers && error) {
    content = <Status status={t('insights.export_failed')} />
  } else if (loading) {
    content = <Status status={t('insights.export_loading')} />
  } else {
    content = (
      <ExportLink
        variant="body"
        size="small"
        onClick={() => {
          if (!loading && !error) {
            exportFinishers()
          }
        }}
      >
        {t('insights.export_list')}
      </ExportLink>
    )
  }

  return <Container>{content}</Container>
}

const Container = styled.div`
  margin: -0.5rem 0 1.25rem;
  text-align: center;

  ${media.atDesktop`
    margin: 0.5rem 0 0;
  `}
`

const ExportLink = styled(Link)`
  color: ${white};
`

const ExportStatus = styled(Body)`
  color: ${white};
  font-size: 13px;
`

export { FinishersExport }
