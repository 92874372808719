import { Support } from '@pelotoncycle/design-system'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const Disclaimer = () => {
  return (
    <Container>
      <Support as="p" size="small">
        <Trans
          i18nKey="insights.disclaimer"
          components={{
            br: <br />,
          }}
        />
      </Support>
    </Container>
  )
}

const Container = styled.div`
  max-width: 530px;
`

export { Disclaimer }
