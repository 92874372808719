import { BreakpointWidths } from '@pelotoncycle/design-system'
import { ValidCssWidths } from '@pelotoncycle/design-system/types'
import styled from 'styled-components'
import { Flex } from './Flex'

type TInlineStatusProps = {
  children: React.ReactNode
  text: string
  width?: ValidCssWidths
}

const InlineStatus = ({ children, text, width }: TInlineStatusProps) => {
  return (
    <Flex alignItems="center">
      {children}
      {text && <StyledText width={width}>{text}</StyledText>}
    </Flex>
  )
}

const { desktop } = BreakpointWidths
const iconPadding = '6px'

const StyledText = styled.div<{ width: TInlineStatusProps['width'] }>`
  width: ${({ width }) => width || 'auto'};
  margin-left: ${iconPadding};
  overflow: hidden;
  white-space: initial;

  @media (min-width: ${desktop}px) {
    width: auto;
    max-width: ${({ width }) => width || 'none'};
  }
`
export { InlineStatus }
