import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from 'ui/components/AuthProvider'
import { ApolloClientProvider } from './ApolloClientProvider'
import { App } from './App'
// import { reportWebVitals } from './reportWebVitals'

interface WindowWithCypress extends Window {
  Cypress?: unknown
}

const customWindow: WindowWithCypress = window
const cacheLocation = customWindow.Cypress ? 'localstorage' : 'memory'
const container = document.getElementById('root')
/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
const root = createRoot(container!)

root.render(
  <Router>
    <AuthProvider useRefreshTokens cacheLocation={cacheLocation}>
      <ApolloClientProvider>
        <App />
      </ApolloClientProvider>
    </AuthProvider>
  </Router>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
