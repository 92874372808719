import { useEffect } from 'react'

const documentExists = document !== null

const useInputChange = () => {
  const checkPrimaryInputChange = (input: string) => {
    if (documentExists) {
      const { classList } = document.body
      const keyboardClass = 'keyboard-outline'
      const mouseAndTouchClass = 'mouse-and-touch-outline'

      switch (input) {
        case 'mouse':
        case 'touch':
          classList.remove(keyboardClass)
          classList.add(mouseAndTouchClass)
          break
        case 'keyboard':
          classList.remove(mouseAndTouchClass)
          classList.add(keyboardClass)
          break
        default:
      }
    }
  }

  useEffect(() => {
    // EVENT LISTENERS TO TOGGLE OUTLINES
    if (documentExists) {
      document.addEventListener('mousedown', () => checkPrimaryInputChange('mouse'))
      document.addEventListener('touchstart', () => checkPrimaryInputChange('touch'))
      document.addEventListener('keydown', () => checkPrimaryInputChange('keyboard'))
    }
  }, [])
}

export { useInputChange }
