import { gql } from '@apollo/client'

const FILE_RUN_ERROR_FIELDS = gql`
  fragment FileRunErrorFields on FileRunErrors {
    fatalErrors {
      name
      column
      rows
      count
    }
    rowErrors {
      name
      column
      rows
      count
    }
  }
`

const FILE_RUN_FIELDS = gql`
  fragment FileRunFields on FileRun {
    id
    created
    status
    eligibilityUnchanged
    eligibilityAdded
    eligibilityTotalAdded
    eligibilityRemoved
    eligibilityWillBeRemoved
    totalRowsValid
    totalRowErrors
  }
`

const FILE_RUN_WITH_FILE_RUN_ERROR_FIELDS = gql`
  ${FILE_RUN_FIELDS}
  ${FILE_RUN_ERROR_FIELDS}
  fragment FileRunWithFileRunErrorFields on FileRun {
    ...FileRunFields
    fileRunErrors {
      ...FileRunErrorFields
    }
  }
`

export { FILE_RUN_FIELDS, FILE_RUN_WITH_FILE_RUN_ERROR_FIELDS }
