import { gql } from '@apollo/client'

const GET_EMAIL_TEMPLATES = gql`
  query EmailTemplates {
    emailTemplates {
      id
      name
      templateName
      description
    }
  }
`

export { GET_EMAIL_TEMPLATES }
