import { Eyebrow, Label, white, grey, yellow, red } from '@pelotoncycle/design-system'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import {
  FileWithFileRunsAndErrors_fileRuns_fileRunErrors_rowErrors as TRowErrors,
  FileWithFileRunsAndErrors_fileRuns_fileRunErrors_fatalErrors as TFatalErrors,
} from 'data/fragments/types/FileWithFileRunsAndErrors'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { media } from '../responsive'
import { AccordionChevron } from './AccordionChevron'
import { BangCircle } from './svg'

type TActionRequired = boolean
type TAccordionRowProps = {
  title: string
  rows: number[] | null
  count: number | null
  testId: string
  index: number
  actionRequired?: TActionRequired
}

type TErrors = TRowErrors | TFatalErrors
type TErrorsSectionProps = {
  errors: TErrors[]
  title: string
  actionRequired?: TActionRequired
  translationKey: 'fatal_errors' | 'row_errors'
}

const AccordionItemContent = ({
  testId,
  title,
  rows,
  count,
  index,
  actionRequired,
}: TAccordionRowProps) => {
  const { t } = useTranslation()
  const { isExpanded } = useAccordionItemContext()

  const panelText = t('file_details.rows', {
    rows: rows && rows.join(', '),
    count: Number(rows && rows.length),
  })

  return (
    <>
      <StyledRow data-testid={testId}>
        <BangCircle fill={actionRequired ? red[80] : yellow[50]} />
        {title && (
          <Label size="large" weight="medium">
            {title}
          </Label>
        )}
        {rows && count && (
          <>
            <StyledAccordionButton aria-label={`error-details-${index}`}>
              <AccordionChevron isExpanded={isExpanded} />
            </StyledAccordionButton>
            <Label is="span" weight="regular" size="large" textColor={grey[70]}>
              {t('file_details.countXOccurrences', { count })}
            </Label>
          </>
        )}
      </StyledRow>
      <StyledAccordionPanel>
        <Label size="large" weight="medium" textColor={grey[70]}>
          {panelText}
        </Label>
      </StyledAccordionPanel>
    </>
  )
}

const ErrorsSection = ({
  errors,
  title,
  actionRequired,
  translationKey,
}: TErrorsSectionProps) => {
  const { t } = useTranslation()
  const sectionId = actionRequired ? 'fatal-errors-section' : 'row-errors-section'

  return (
    <section data-testid={sectionId}>
      <Eyebrow size="small" textColor={grey[90]}>
        {title}
      </Eyebrow>
      <Accordion multiple collapsible>
        {errors.map((err, index) => {
          const { name, column, rows, count } = err

          let key = name
          if (column) {
            key += `_${column}`
          }

          const translatedError = t(
            [`${translationKey}.${name}`, 'row_errors.generic_message'],
            { column, name },
          )

          return (
            <StyledAccordionItem key={key}>
              <AccordionItemContent
                index={index}
                testId={key}
                title={translatedError}
                rows={rows}
                count={count}
                actionRequired={actionRequired}
              />
            </StyledAccordionItem>
          )
        })}
      </Accordion>
      {actionRequired && (
        <StyledCallout>
          <Label is="p" weight="medium">
            {t('file_details.action_required')}
          </Label>
        </StyledCallout>
      )}
    </section>
  )
}

const StyledAccordionItem = styled(AccordionItem)`
  background-color: ${white};
  padding: 16px 24px;
  margin-top: 8px;

  &:first-of-type {
    margin-top: 16px;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  min-height: 48px;

  & > svg:first-of-type {
    min-width: 16px;
    margin-right: 16px;
    margin-top: 8px;
  }

  & > h3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
  }

  & > span {
    margin: 16px 0 0 32px;
  }

  ${media.atTablet`
    margin-top: 0;
    align-items: center;

    & > svg:first-of-type {
      margin-top: 0;
    }

    & > h3 {
      flex-basis: 70%;
    }

    & > span {
      margin: 0;
      margin-left: auto;
    }
  `}

  ${media.atDesktop`
    & > h3 {
      flex-basis: 75%;
    }
  `}
`

const StyledAccordionButton = styled(AccordionButton)`
  margin-left: auto;
  margin-top: 4px;

  ${media.atTablet`
    order: 4;
    margin-top: 0;
    margin-left: 16px;
  `}
`

const StyledAccordionPanel = styled(AccordionPanel)`
  margin: 16px 32px 8px;

  ${media.atTablet`
    margin: 16px 32px 8px;
  `}
`
const StyledCallout = styled.div`
  display: inline-block;
  padding: 8px;
  background-color: ${red[80]};
  color: white;
  text-align: center;
`
export { ErrorsSection }
