import { grey, black, white } from '@pelotoncycle/design-system'
import {
  ListboxInput,
  ListboxOption,
  ListboxPopover,
  ListboxList,
  useListboxContext,
  ListboxButton,
} from '@reach/listbox'
import '@reach/listbox/styles.css'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from './Box'
import { Check, Chevron } from './svg'

type SelectProps = {
  width: string
  defaultValue: string
  options: ReactNode[]
  handleChange: (v: string) => void
}

type ListboxButtonProps = {
  width?: string
}

type OptionProps = {
  pl?: string
}

const Options = ({ options }: { options: ReactNode[] }) => {
  const { value } = useListboxContext()

  return (
    <>
      {options.map(option => {
        const isSelected = value === option
        const paddingLeft = isSelected ? '16px' : '40px'

        return (
          <StyledOption key={option as string} value={option} pl={paddingLeft}>
            {isSelected && (
              <Box marginRight="12px" width="12px">
                <Check />
              </Box>
            )}
            {option}
          </StyledOption>
        )
      })}
    </>
  )
}

const Select = ({ defaultValue = '', options, width, handleChange }: SelectProps) => {
  const { t } = useTranslation()
  const ariaLabel = t('sort_by_options')

  return (
    <ListboxInput
      defaultValue={defaultValue}
      onChange={handleChange}
      aria-label={ariaLabel}
    >
      <StyledButton arrow={<Chevron />} width={width} />
      <StyledPopover>
        <ListboxList>
          <Options options={options} />
        </ListboxList>
      </StyledPopover>
    </ListboxInput>
  )
}

const StyledButton = styled(ListboxButton)<ListboxButtonProps>`
  cursor: pointer;
  width: ${p => p.width};
  font-size: 14px;
  color: ${grey[70]};
  border: 1px solid ${grey[70]};
  outline: none;
  font-weight: 500;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-left: 16px;
  background-color: ${white};

  [data-reach-listbox-arrow] {
    height: inherit;
    border-left: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }
`

const StyledPopover = styled(ListboxPopover)`
  padding: 0;
  background-color: ${white};
  box-shadow: 8px 8px 30px 0 rgb(0 0 0 / 8%);
  border: none;
  &:focus-within {
    outline: none;
    box-shadow: 8px 8px 30px 0 rgb(0 0 0 / 8%);
  }
`

const StyledOption = styled(ListboxOption)<OptionProps>`
  display: flex;
  align-items: center;
  letter-spacing: 0.35px;
  line-height: 21px;
  padding: 16px 0;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: ${p => p.pl || '40px'};
  color: ${black};
  font-size: 14px;
  font-weight: 100;

  &[aria-selected='true'],
  &[data-current] {
    background-color: ${grey[30]};
    color: ${black};
    font-weight: 100;
  }
`

const SelectContainer = styled.div`
  li:first-child {
    display: none;
  }
`

export { Select, SelectContainer }
