import { Factory } from 'fishery'
import { Partner_partner_contacts as TPartnerContact } from '../queries/types/Partner'

const partnerContactFactory = Factory.define<TPartnerContact>(({ sequence }) => ({
  __typename: 'Contact',
  id: `${sequence}`,
  firstName: `Jay${sequence}`,
  lastName: `Foles${sequence}`,
  emailAddress: `j.foles${sequence}@example.com`,
}))

export { partnerContactFactory }
