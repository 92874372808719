import { ApolloError } from '@apollo/client'
import React from 'react'
import { Loading, RequestError } from '.'

type RenderResponseProps = {
  loading: boolean
  error?: ApolloError
  children: React.ReactNode
}

const RenderResponse = ({ loading, error, children }: RenderResponseProps) => {
  if (loading) return <Loading />
  if (error) return <RequestError error={error} />

  /* eslint-disable-next-line react/jsx-no-useless-fragment */
  return <>{children}</>
}

export { RenderResponse }
