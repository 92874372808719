import { LinkButton } from '@pelotoncycle/design-system'
import { useChallenge } from 'data/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from 'ui/responsive'
import { Module } from '../Module'
import { ParticipantsMetric, FinishersMetric, DaysMetric } from './ProgressMetric'
import { Selector } from './Selector'

type Props = {
  challengeId: string
  selection: string
  challengeSelect: (challenge: string) => void
}

const Challenge: React.FC<React.PropsWithChildren<Props>> = ({
  challengeId,
  selection,
  challengeSelect,
}) => {
  const { t } = useTranslation()
  const { loading, error, challenge } = useChallenge({ challengeId })
  const cta = (
    <CtaContainer>
      <Selector selection={selection} challengeSelect={challengeSelect} />
      <Link to={`/insights/challenges/${challengeId}`}>
        <ViewChallengeCta width="adaptive" text={t('insights.view_challenge_details')} />
      </Link>
    </CtaContainer>
  )

  if (loading) {
    return <Module dark label="insights.challenges" cta={cta} loading padding="40px" />
  }

  if (error || !challenge) {
    return null
  }

  return (
    <div data-testid="challenge">
      <Module dark label={challenge.title} cta={cta} padding="40px">
        <Inner>
          <ParticipantsMetric challengeId={challengeId} />
          <FinishersMetric challengeId={challengeId} />
          <DaysMetric challengeId={challengeId} />
        </Inner>
      </Module>
    </div>
  )
}

const Inner = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  ${media.atDesktop`
    flex-direction: row;
  `}
`

const CtaContainer = styled.div`
  display: flex;
  margin-top: 1.25rem;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 550px) {
    flex-direction: row;
    justify-content: flex-start;
  }

  ${media.atDesktop`
    margin-top: 0;
  `}
`

const ViewChallengeCta = styled(LinkButton)`
  margin-left: 0;
  margin-top: 0.5rem;
  width: 100%;

  @media (min-width: 550px) {
    margin-left: 0.5rem;
    margin-top: 0;
  }
`

export { Challenge }
