import { Factory } from 'fishery'
import {
  FileRunErrorFields,
  FileRunErrorFields_fatalErrors as FatalErrorFields,
  FileRunErrorFields_rowErrors as RowErrorFields,
} from '../fragments/types/FileRunErrorFields'

const fatalErrorNames = [
  'invalid_filetype',
  'duplicate_column_values_on_file',
  'missing_required_column',
  'no_schema_found_for_partner',
  'no_programs_found_for_partner',
  'no_pipeline_found_for_partner',
]

const rowErrorNames = [
  'validation_error',
  'missing_required_value_for_field',
  'term_date_before_effective_date',
]

const rowErrorColumns = ['first_name', 'effective_date', 'termination_date']

const fatalErrorsFactory = Factory.define<FatalErrorFields>(({ sequence }) => {
  const index = sequence - 1
  const nameIndex = sequence <= fatalErrorNames.length ? index : 0
  const name = fatalErrorNames[nameIndex]

  return {
    __typename: 'FatalErrorType',
    name,
    column: 'internal_id',
    rows: null,
    count: null,
  }
})

const rowErrorsFactory = Factory.define<RowErrorFields>(({ sequence }) => {
  const index = sequence - 1
  const nameIndex = sequence <= rowErrorNames.length ? index : 0
  const colIndex = sequence <= rowErrorColumns.length ? index : 0
  const name = rowErrorNames[nameIndex]
  const column = rowErrorColumns[colIndex]

  return {
    __typename: 'RowErrorType',
    name,
    column,
    rows: [1],
    count: 1,
  }
})

const fileRunErrorsFactory = Factory.define<FileRunErrorFields>(() => ({
  __typename: 'FileRunErrors',
  fatalErrors: [],
  rowErrors: [],
}))

const failedFileRunErrorsFactory = fileRunErrorsFactory.params({
  rowErrors: [],
  fatalErrors: [fatalErrorsFactory.build()],
})

const succeededWithErrorsFileRunErrorsFactory = fileRunErrorsFactory.params({
  fatalErrors: [],
  rowErrors: rowErrorsFactory.buildList(2),
})

const failedWithRowErrorsFileRunErrorsFactory = fileRunErrorsFactory.params({
  fatalErrors: [fatalErrorsFactory.build()],
  rowErrors: rowErrorsFactory.buildList(2),
})

export {
  fatalErrorsFactory,
  fileRunErrorsFactory,
  failedFileRunErrorsFactory,
  failedWithRowErrorsFileRunErrorsFactory,
  succeededWithErrorsFileRunErrorsFactory,
}
