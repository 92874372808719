import {
  from,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import { errorLink, retryLink } from 'data/apolloProviderConfigs'

const {
  REACT_APP_CF_CLIENT_ACCESS_ID = '',
  REACT_APP_CF_CLIENT_ACCESS_SECRET = '',
  REACT_APP_API_URL = '',
  NODE_ENV,
} = process.env

const isLocalHost = REACT_APP_API_URL.indexOf('localhost') > -1

const cloudflareHeaders = {
  'CF-Access-Client-Id': REACT_APP_CF_CLIENT_ACCESS_ID,
  'CF-Access-Client-Secret': REACT_APP_CF_CLIENT_ACCESS_SECRET,
}

type TApolloProviderProps = {
  children: React.ReactNode
}

const ApolloClientProvider = ({ children }: TApolloProviderProps) => {
  const { getAccessTokenSilently, logout } = useAuth0()

  const onErrorLink = errorLink({ handleError: logout })

  const headerLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently().catch(() => {
      // force user logout if token refresh request fails
      logout()
    })

    // dont set cloudflare headers if localhost b/c BE isn't accepting them locally
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    return {
      headers: {
        ...headers,
        ...(!isLocalHost ? { ...cloudflareHeaders } : {}),
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    }
  })

  const httpLink = new HttpLink({ uri: `${REACT_APP_API_URL}` })

  const link = from([onErrorLink, retryLink, headerLink, httpLink])

  // this is the alleged default, but seems to only work if you manually set it on the client
  const connectToDevTools = NODE_ENV !== 'production'

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // Cache redirect. Allows queries requesting eligiblePerson records to read cached results of EligiblePersons search query
          eligiblePerson: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'EligiblePerson',
                id: args && args.id,
              })
            },
          },
        },
      },
    },
  })

  const options = {
    cache,
    link,
    connectToDevTools,
    name: 'portal',
    version: '1.0.0',
  }

  const client = new ApolloClient(options)

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export { ApolloClientProvider }
