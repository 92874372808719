import { Factory } from 'fishery'
import { ReportsByDate_reportsByDate as TReports } from '../queries/types/ReportsByDate'
import { ReportReportType } from '../types/graphql-global-types'

const date = new Date()
const year = date.getUTCFullYear()

const reportByDateFactory = Factory.define<TReports>(({ sequence }) => ({
  id: `${sequence}`,
  partnerName: `Partner ${sequence}`,
  startDate: `${year}-06-01`,
  rowCount: 1000,
  approved: true,
  reportType: ReportReportType.EER,
  __typename: 'Report',
}))

export { reportByDateFactory }
