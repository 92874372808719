import { Headline, Support, typography } from '@pelotoncycle/design-system'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Page, Box } from '.'

type Props = {
  dark?: boolean
}

const NotFoundPage: React.FC<Props> = ({ dark = false }) => {
  const { t } = useTranslation()

  const history = useHistory()

  return (
    <Page testId="not-found-page">
      <Box textAlign="center" paddingTop="27vh">
        <Headline as="h1" size="medium" marginBottom="40px">
          {t('not_found_main')}
        </Headline>
        <Support
          size="large"
          as="p"
          textColor={dark ? typography.lightest : typography.darkest}
        >
          <Trans
            i18nKey="not_found_support"
            components={[
              <GoBack key="go-back" as="button" onClick={() => history.goBack()} />,
            ]}
          />
        </Support>
      </Box>
    </Page>
  )
}

const GoBack = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
`

export { NotFoundPage }
