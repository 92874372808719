/* eslint-disable react/jsx-props-no-spreading */
import { Headline, grey } from '@pelotoncycle/design-system'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { AccordionChevron } from './AccordionChevron'
import { Box } from './Box'

type ContentType = {
  question: string
  answer: string[] | React.ReactNode
}

const AccordionRow = ({ question, answer }: ContentType) => {
  const { t } = useTranslation()
  const { isExpanded } = useAccordionItemContext()
  const answerContent = typeof answer === 'string' ? t(answer) : answer

  return (
    <>
      <StyledButton>
        <FaqItem>{t(question)}</FaqItem>
        <AccordionChevron isExpanded={isExpanded} />
      </StyledButton>
      <StyledPanel>{answerContent}</StyledPanel>
    </>
  )
}

const FaqAccordion = ({ content }: { content: ContentType[] }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box padding="10px" borderBottom="2px solid" borderColor={grey[90]}>
        <Headline>{t('faqs')}</Headline>
      </Box>
      <Accordion multiple collapsible>
        {content.map(({ question, answer }: ContentType) => (
          <StyledItem key={question}>
            <AccordionRow key={question} {...{ question, answer }} />
          </StyledItem>
        ))}
      </Accordion>
    </>
  )
}

const FaqItem = styled.span`
  max-width: 90%;
  text-align: left;
`

const StyledItem = styled(AccordionItem)`
  color: ${grey[90]};
  border-bottom: 1px solid ${grey[40]};
`

const textStyles = css`
  letter-spacing: 0.4px;
  font-size: 16px;
  line-height: 1.5em;
`

const StyledButton = styled(AccordionButton)`
  ${textStyles}
  cursor: pointer;
  border: none;
  background-color: inherit;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledPanel = styled(AccordionPanel)`
  ${textStyles}
  transition: all 250 ease;
  font-weight: 300;
  padding: 10px 10px 20px;
`

export { FaqAccordion }
