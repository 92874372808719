import { ApolloError } from '@apollo/client'
import * as React from 'react'
import { FileStatus } from '../types/graphql-global-types'
import { useFile } from './useFile'

type TContext = {
  pollForFileId: (arg: string) => void
  uploadStatus?: FileStatus
  isUploading: boolean
  error?: ApolloError
}

type TPollForFileProviderProps = {
  children: React.ReactNode
}

const DEFAULTS = {
  POLL_INTERVAL: 1000,
  MAX_POLL_DURATION: 1800000,
}

const { SUCCEEDED, SUCCEEDED_WITH_ERRORS, FAILED, RUNNING } = FileStatus
const PROCESSING_COMPLETE_STATUSES = [SUCCEEDED, SUCCEEDED_WITH_ERRORS, FAILED]

const PollForFileContext = React.createContext<TContext | undefined>(undefined)

const PollForFileProvider = ({ children }: TPollForFileProviderProps) => {
  const [fileIdToPoll, setFileIdToPoll] = React.useState<string>()

  const { data, error, startPolling, stopPolling } = useFile({ fileId: fileIdToPoll })

  const uploadStatus = data?.file?.status
  const isUploading = uploadStatus === RUNNING
  const processingComplete =
    uploadStatus && PROCESSING_COMPLETE_STATUSES.indexOf(uploadStatus) > -1

  const pollForFileId = (fileId: string) => {
    setFileIdToPoll(fileId)
  }

  // stop polling if we encounter a gql error
  React.useEffect(() => {
    if (error) stopPolling()
  }, [error, stopPolling])

  React.useEffect(() => {
    let pollingTimeout: ReturnType<typeof setTimeout>
    if (fileIdToPoll && !error) {
      startPolling(DEFAULTS.POLL_INTERVAL)
      pollingTimeout = setTimeout(stopPolling, DEFAULTS.MAX_POLL_DURATION)
    }

    return function cleanup() {
      clearTimeout(pollingTimeout)
    }
  }, [fileIdToPoll, startPolling, stopPolling, error])

  React.useEffect(() => {
    if (processingComplete) {
      setFileIdToPoll(undefined)
      stopPolling()
    }
  }, [fileIdToPoll, processingComplete, stopPolling])

  const context = React.useMemo(
    () => ({
      pollForFileId,
      uploadStatus,
      isUploading,
      error,
    }),
    [error, isUploading, uploadStatus],
  )

  return (
    <PollForFileContext.Provider value={context}>{children}</PollForFileContext.Provider>
  )
}

function usePollForFile() {
  const context = React.useContext(PollForFileContext)

  if (context === undefined) {
    throw new Error('usePollForFile must be used within a PollForFileProvider')
  }

  return context
}

export { PollForFileContext, PollForFileProvider, usePollForFile }
