/* eslint-disable react/jsx-props-no-spreading */
import { Headline, white } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { SUPPORT_ROUTE } from 'utils/constants/routes'
import { LinkBackTo, Flex, StyledPage, Box } from '../../..'
import { media } from '../../../../responsive'

type LayoutProps = {
  children: React.ReactNode
  headline: string
  testId?: string
  pageStyles?: {
    padding: string
  }
}

const defaultPageStyles = {
  padding: '0 16px',
}

const PageLayout = ({
  children,
  headline,
  testId,
  pageStyles = defaultPageStyles,
}: LayoutProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isMainSupportPage = pathname === SUPPORT_ROUTE

  return (
    <Flex
      flexDirection="column"
      height="calc(100vh - var(--header-height))"
      data-testid={testId}
      as="main"
    >
      <Header padding="16px">
        {!isMainSupportPage && (
          <LinkBackTo to={SUPPORT_ROUTE} title={t('back_to_help_and_support')} />
        )}
        <Headline as="h1" size="small">
          {headline}
        </Headline>
      </Header>
      <ContentLayout>
        <StyledPage {...pageStyles}>{children}</StyledPage>
      </ContentLayout>
    </Flex>
  )
}

const ContentLayout = styled(Box)`
  flex: 1;
  padding: 56px 0;
  background-color: ${white};
`

const Header = styled(StyledPage)`
  width: 100%;
  padding: 16px;

  ${media.atTablet`
    padding: 48px 16px;
  `}
`

export { PageLayout }
