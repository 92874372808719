import { Headline } from '@pelotoncycle/design-system'
import { useTranslation } from 'react-i18next'
import { LoadingEllipsis, Flex } from '.'

const LoadingScreen = () => {
  const { t } = useTranslation()

  return (
    <Flex
      height="100vh"
      padding="64px 0"
      justifyContent="center"
      data-testid="loading-screen"
      as="main"
      aria-live="polite"
      aria-busy="true"
    >
      <Flex alignItems="baseline">
        <Headline size="small">{t('loading')}</Headline>
        <LoadingEllipsis />
      </Flex>
    </Flex>
  )
}

export { LoadingScreen }
