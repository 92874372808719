import React from 'react'
import styled from 'styled-components'
import { media } from 'ui/responsive'
import { Data } from '../../types'
import { Item } from './Item'

type LegendProps = {
  data: Data
  colorScale: string[]
}

const Legend: React.FC<React.PropsWithChildren<LegendProps>> = ({ data, colorScale }) => {
  const sum = data.reduce((memo, d) => memo + d.y, 0)

  return (
    <Container>
      {data.map((d, i) => (
        <ItemStyled
          key={d.x as number}
          color={colorScale[i % colorScale.length]}
          label={d.label || ''}
          percentage={Math.floor((d.y / sum) * 100)}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${media.atDesktop`
    display: block;
  `}
`

const ItemStyled = styled(Item)`
  margin-bottom: 1.25rem;
`

export { Legend }
