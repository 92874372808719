import { Box } from '../Box'

type ErrorContainerProps = {
  children: React.ReactNode
}

const ErrorContainer = ({ children }: ErrorContainerProps) => (
  <Box padding="64px 0" textAlign="center" data-testid="error-container">
    {children}
  </Box>
)

export { ErrorContainer }
