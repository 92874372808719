import { Button } from '@pelotoncycle/design-system'
import { useEligiblePersonsSearch } from 'data/hooks'
import { EligiblePerson_eligiblePersonById as TEligiblePerson } from 'data/queries/types/EligiblePerson'
import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Flex, SearchCombobox } from 'ui/components'
import '@reach/combobox/styles.css'
import { media } from '../../../../responsive'
import { SearchResultsTable } from './SearchResultsTable'

type TIdList = string[]

type TIndexablePersonRef = { [key: string]: TEligiblePerson }

const MembersSearch = () => {
  const { t } = useTranslation()
  const [idList, setIdList] = useState<TIdList>([])
  const [idsUpdated, setIdsUpdated] = useState<TIdList>([])
  const [search, { data }] = useEligiblePersonsSearch()
  const searchField = 'emailAddress'

  const optionData = useRef({} as TIndexablePersonRef)

  const results = useMemo(
    () => data?.eligiblePersonSearch || [],
    [data?.eligiblePersonSearch],
  )

  const mappedResults = results.map(i => {
    const value = i[searchField]
    const disabled = idList.indexOf(i.id) > -1

    return { value, disabled }
  })

  // Persist eligiblePerson data across multiple search results in order to
  // match a select value from the combobox with an eligible person
  useEffect(() => {
    if (results) {
      results.forEach(r => {
        const key = r[searchField]
        optionData.current[key] = r
      })
    }
  }, [results])

  const handleSelect = (selected: string) => {
    const member = optionData.current?.[selected]
    const { id } = member
    setIdList(currentState => {
      if (currentState.indexOf(id) > -1) {
        return currentState
      }

      return [...currentState, id]
    })
  }

  const handleClearList = () => {
    setIdsUpdated([])
    setIdList([])
  }

  const handleUpdated = (id: string) => {
    setIdsUpdated(currentIds => {
      if (currentIds.indexOf(id) > -1) return currentIds

      return [...currentIds, id]
    })
  }

  const updatedMemberCount = idsUpdated.length

  const handleInputChange = useCallback(
    (searchInput: string) => {
      search({
        variables: {
          searchInput: { [searchField]: searchInput },
          numOfResults: 5,
        },
      }).catch(e => {
        /* eslint-disable-next-line no-console */
        console.log('handleInputChange search error -> ', e)
      })
    },
    [searchField, search],
  )

  const hasItemsInList = !!idList.length

  return (
    <div data-testid="member-search">
      <Flex justifyContent="space-between" marginBottom="16px" alignItems="center">
        <SearchCombobox
          onChange={handleInputChange}
          onSelect={handleSelect}
          results={mappedResults}
          placeholder={t('benefit_admin.search_employees')}
        />

        {!!updatedMemberCount && (
          <StyledTotal data-testid="updates-total">
            {t('benefit_admin.total_updated', { count: updatedMemberCount })}
          </StyledTotal>
        )}

        {hasItemsInList && (
          <Button
            data-testid="clear-list"
            text={t('benefit_admin.clear_list')}
            color="dark"
            variant="outline"
            width="adjustable"
            size="large"
            onClick={handleClearList}
          />
        )}
      </Flex>

      <SearchResultsTable results={idList} onUpdateItem={handleUpdated} />
    </div>
  )
}

const StyledTotal = styled.span`
  font-size: 14px;
  margin-right: 8px;
  text-align: center;

  ${media.atTablet`
    font-size: 16px;
  `}
`

export { MembersSearch }
