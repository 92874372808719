import styled from 'styled-components'
import { Chevron, ChevronProps } from './svg'

type TChevProps = {
  isExpanded?: boolean
}

const AccordionChevron = ({ isExpanded }: TChevProps) => (
  <StyledChevron rotate={isExpanded ? '180' : '0'} />
)

const StyledChevron = styled(Chevron)<ChevronProps>`
  cursor: pointer;
  transform: rotate(${p => p.rotate}deg);
  transition: all 250ms ease;
`

export { AccordionChevron }
