import { gql } from '@apollo/client'
import { PARTNER_WITH_CONTACTS_FIELDS } from '../fragments'

const GET_PARTNER = gql`
  ${PARTNER_WITH_CONTACTS_FIELDS}
  query Partner($id: String!) {
    partner(id: $id) {
      ...PartnerWithContactFields
      eligibleTotal
      enrolledTotal
    }
  }
`

export { GET_PARTNER }
