import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  SUPPORT_GETTING_STARTED_ROUTE,
  SUPPORT_TROUBLESHOOTING_ROUTE,
  SUPPORT_TEMPLATES_ROUTE,
} from 'utils/constants/routes'
import { PageLayout } from '.'
import { BlockLink, FaqAccordion, Flex } from '../../..'
import { useWindowWidth } from '../../../../hooks'
import { media } from '../../../../responsive'
import { AlertTriangle, CheckCircleOutline, Spreadsheet } from '../../../svg'

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-style: italic;

  &:hover {
    text-decoration: underline;
  }
`

const faqContent = [
  {
    question: 'support.faqContent.why_file_failed.question',
    answer: (
      <Trans i18nKey="support.faqContent.why_file_failed.answer">
        <StyledLink to={SUPPORT_GETTING_STARTED_ROUTE}>0</StyledLink>
      </Trans>
    ),
  },
  {
    question: 'support.faqContent.file_format.question',
    answer: (
      <Trans i18nKey="support.faqContent.file_format.answer">
        <StyledLink to={SUPPORT_TEMPLATES_ROUTE}>0</StyledLink>
      </Trans>
    ),
  },
  {
    question: 'support.faqContent.multiple_files.question',
    answer: 'support.faqContent.multiple_files.answer',
  },
  {
    question: 'support.faqContent.upload_cadence.question',
    answer: 'support.faqContent.upload_cadence.answer',
  },
  {
    question: 'support.faqContent.add_new_people.question',
    answer: 'support.faqContent.add_new_people.answer',
  },
  {
    question: 'support.faqContent.how_to_remove.question',
    answer: 'support.faqContent.how_to_remove.answer',
  },
  {
    question: 'support.faqContent.other_issues_or_questions.question',
    answer: 'support.faqContent.other_issues_or_questions.answer',
  },
  {
    question: 'support.faqContent.termination_file_info.question',
    answer: 'support.faqContent.termination_file_info.answer',
  },
  {
    question: 'support.faqContent.termination_file_use.question',
    answer: 'support.faqContent.termination_file_use.answer',
  },
  {
    question: 'support.faqContent.termination_file_past_date.question',
    answer: 'support.faqContent.termination_file_past_date.answer',
  },
  {
    question: 'support.faqContent.termination_file_format.question',
    answer: 'support.faqContent.termination_file_format.answer',
  },
]

const Support = () => {
  const { t } = useTranslation()
  const { isAboveMobile } = useWindowWidth()

  return (
    <PageLayout headline={t('help_and_support')} testId="support-page">
      <LinkContainer data-testid="support-links">
        <BlockLink
          to={SUPPORT_GETTING_STARTED_ROUTE}
          marginRight={isAboveMobile ? '24px' : undefined}
          text={t('get_started_eligibility')}
          Icon={CheckCircleOutline}
        />
        <BlockLink
          to={SUPPORT_TROUBLESHOOTING_ROUTE}
          marginRight={isAboveMobile ? '24px' : undefined}
          text={t('troubleshooting')}
          Icon={AlertTriangle}
        />
        <BlockLink
          to={SUPPORT_TEMPLATES_ROUTE}
          text={t('templates')}
          Icon={Spreadsheet}
        />
      </LinkContainer>
      <div data-testid="support-faq">
        <FaqAccordion content={faqContent} />
      </div>
    </PageLayout>
  )
}

const LinkContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 56px;

  ${media.atTablet`
    flex-direction: row;
  `}
`

export { Support }
