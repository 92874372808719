import { Body, grey } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PageLayout, DownloadTemplateButton } from '.'
import { Box, Flex } from '../../..'
import { useWindowWidth } from '../../../../hooks'
import { Spreadsheet } from '../../../svg'
import { InfoSection } from './InfoSection'

type TRowProps = {
  template: string
}

const Row = ({ template }: TRowProps) => {
  const { isMobile } = useWindowWidth()

  return (
    <StyledRow isMobile={isMobile}>
      <Flex
        marginBottom={isMobile ? '16px' : '0'}
        alignItems="center"
        width={isMobile ? 'auto' : '100%'}
      >
        <Box marginRight={isMobile ? '8px' : '24px'} width="38px" height="40px">
          <Spreadsheet />
        </Box>
        <Body display="inline-block" size="small">
          {template}
        </Body>
      </Flex>
      <DownloadTemplateButton to={`/${template}`} />
    </StyledRow>
  )
}

const Templates = () => {
  const { t } = useTranslation()
  const templates = ['eligibility_template.csv', 'termination_template.csv']

  return (
    <PageLayout headline={t('templates')} testId="support-templates">
      <InfoSection heading={t('download_templates')} omitUnderline>
        {templates.map(template => (
          <Row key={template} template={template} />
        ))}
      </InfoSection>
    </PageLayout>
  )
}

const StyledRow = styled(Flex)<{ isMobile?: boolean }>`
  align-items: center;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  margin: 16px 0;
  padding: 24px 0;
  border-top: 1px solid ${grey[40]};

  &:last-child {
    border-bottom: 1px solid ${grey[40]};
  }
`

export { Templates }
