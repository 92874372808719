import { useQuery, useLazyQuery } from '@apollo/client'
import { EligiblePersons } from 'data/queries/types/EligiblePersons'
import {
  GET_ELIGIBLE_PERSONS_SEARCH,
  GET_ELIGIBLE_PERSON,
  GET_ELIGIBLE_PERSON_LOOKUP,
} from '../queries'
import { EligiblePersonVariables, EligiblePerson } from '../queries/types/EligiblePerson'
import {
  EligiblePersonLookupVariables,
  EligiblePersonLookup,
} from '../queries/types/EligiblePersonLookup'
import { EligiblePersonsVariables } from '../queries/types/EligiblePersons'

const useEligiblePersonsSearch = () =>
  useLazyQuery<EligiblePersons, EligiblePersonsVariables>(GET_ELIGIBLE_PERSONS_SEARCH)

const useEligiblePerson = ({ id, partnerHashId }: EligiblePersonVariables) => {
  const { loading, error, data } = useQuery<EligiblePerson>(GET_ELIGIBLE_PERSON, {
    variables: { id, partnerHashId },
  })

  return {
    loading,
    error,
    data,
  }
}

const useEligiblePersonLookup = () =>
  useLazyQuery<EligiblePersonLookup, EligiblePersonLookupVariables>(
    GET_ELIGIBLE_PERSON_LOOKUP,
  )

export { useEligiblePersonsSearch, useEligiblePersonLookup, useEligiblePerson }
