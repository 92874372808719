import { green } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const CheckCircleFill = ({
  width = '16px',
  height = '16px',
  fill = green[50],
}: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.7852 3.82532L12.1701 4.71895L7.62503 12.201L3.82593 8.40039L4.96274 7.19328L7.31177 9.54323L10.7852 3.82532Z"
      fill={fill}
    />
  </svg>
)

export { CheckCircleFill }
