import { useMutation } from '@apollo/client'
import { UPDATE_ELIGIBLE_PERSON } from '../mutations'
import { GET_ELIGIBLE_PERSON } from '../queries'

type TEligiblePersonProps = {
  id: string
  terminationDate?: string
}

const useEligiblePersonMutation = (eligiblePersonId: string) => {
  const [mutate, { loading, error }] = useMutation(UPDATE_ELIGIBLE_PERSON, {
    refetchQueries: [{ query: GET_ELIGIBLE_PERSON, variables: { id: eligiblePersonId } }],
  })

  // sending an empty string for terminationDate is the way to "unset" an existing terminationDate
  const updateEligiblePerson = ({ id, terminationDate }: TEligiblePersonProps) => {
    return mutate({
      variables: { id, updateData: { terminationDate } },
    })
  }

  return { updateEligiblePerson, error, loading }
}

export { useEligiblePersonMutation }
