import { black, grey } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import {
  BENEFIT_ADMIN_ROUTE,
  DOCUMENTS_ROUTE,
  INSIGHTS_ROUTE,
  CHALLENGES_ROUTE,
} from 'utils/constants/routes'
import { UserMenu, HeaderNavLink, Flex, MobileMenu } from '..'
import { useWindowWidth } from '../../hooks'
import { media } from '../../responsive'
import { Logo } from '../svg'

type StyledHeaderProps = {
  isChallengePage: boolean
}

const NavLinks = ({ handleClick }: { handleClick?: () => void }) => {
  const { t } = useTranslation()

  return (
    <Nav data-testid="navigation-links">
      <HeaderNavLink
        exact
        to={BENEFIT_ADMIN_ROUTE}
        onClick={handleClick}
        isActive={(_, { pathname }) => /^\/benefit-admin\//.test(pathname)}
      >
        {t('benefit_admin.benefit_admin')}
      </HeaderNavLink>
      <HeaderNavLink
        exact
        to={DOCUMENTS_ROUTE}
        onClick={handleClick}
        isActive={(_, { pathname }) => /^\/documents\//.test(pathname)}
      >
        {t('documents')}
      </HeaderNavLink>
      <HeaderNavLink
        exact
        to={INSIGHTS_ROUTE}
        onClick={handleClick}
        isActive={(_, { pathname }) => /^\/insights/.test(pathname)}
      >
        {t('insights.insights')}
      </HeaderNavLink>
    </Nav>
  )
}

const Header = () => {
  const { isMobile, isAboveMobile } = useWindowWidth()
  const match = useRouteMatch(CHALLENGES_ROUTE)
  const isChallengePage = !!match?.isExact

  return (
    <StyledHeader isChallengePage={isChallengePage}>
      <Link to="/">
        <Logo />
      </Link>
      {isAboveMobile && <NavLinks />}
      <Flex marginLeft="16px">
        <UserMenu />
        {isMobile && <MobileMenu />}
      </Flex>
    </StyledHeader>
  )
}

const StyledHeader = styled.header<StyledHeaderProps>`
  margin: 0 auto;
  background-color: ${props => (props.isChallengePage ? grey[90] : black)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  padding: 0 24px 0 32px;

  a {
    white-space: nowrap;

    &:first-child {
      margin-right: 40px;
    }
  }
`

const Nav = styled.nav`
  display: flex;
  flex-direction: column;

  ${media.atTablet`
    flex-direction: row;
    width: 100%;
  `}
`

export { Header, NavLinks }
