// current typing for User in auth0-react is any which causes TS errors throughout file
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useAuth0, Auth0ContextInterface } from '@auth0/auth0-react'
import { createInstance, OptimizelyProvider as Provider } from '@optimizely/react-sdk'
import React from 'react'

const OptimizelyProvider = ({ children }: { children: React.ReactNode }) => {
  const { user = {} }: Auth0ContextInterface = useAuth0()

  const optimizelyClient = createInstance({
    sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
  })

  const email = user.email ?? ''
  const id = user.sub ?? ''

  const optimizelyUser = {
    id,
    attributes: { email },
  }

  return (
    <Provider optimizely={optimizelyClient} user={optimizelyUser}>
      {children}
    </Provider>
  )
}

export { OptimizelyProvider }
