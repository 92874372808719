import { Partner_partner as TPartner } from 'data/queries/types/Partner'
import { Audiences, DateFormat } from 'data/types/graphql-global-types'
import { isValid, format } from 'date-fns'
import { utcDate, formatTimezoneOffset } from './date'

type TLookup = {
  [key in DateFormat as string]: string
}

export type TDeserializedPartner = Omit<TPartner, 'terminationDate'> & {
  terminationDate: Date | null
  welcomeEmailDateDate: Date | null
  welcomeEmailTime: Date | null
  welcomeEmailTimezone: string | null
  welcomeEmailTemplateId: string | null
  reminderEmailTemplateId: string | null
}

const ELIGIBILITY_METHODS = {
  DBO: 'DBO',
  FILE: 'Eligibility File',
  NONE: 'None',
}

const DATE_FORMAT_LOOKUP: TLookup = {
  [DateFormat.MMDDYYYY_SLASHES]: 'MM/DD/YYYY',
  [DateFormat.DDMMYYYY_SLASHES]: 'DD/MM/YYYY',
  [DateFormat.YYYYMMDD_SLASHES]: 'YYYY/MM/DD',
  [DateFormat.MMDDYYYY_DASHES]: 'MM-DD-YYYY',
  [DateFormat.DDMMYYYY_DASHES]: 'DD-MM-YYYY',
  [DateFormat.YYYYMMDD_DASHES]: 'YYYY-MM-DD',
}

// BE unable to return a null value for dateFormat b/c....graphene?
// They are returning 'NONE', which we want to treat the same as null
const dateFormatExists = (dateFormat?: string | null) => {
  return dateFormat && dateFormat !== 'NONE'
}

const deserializePartner = {
  dateFormat: (dateFormat?: string | null) => {
    let value = dateFormat || ''
    if (Object.keys(DATE_FORMAT_LOOKUP).indexOf(value) > -1) {
      value = DATE_FORMAT_LOOKUP[value]
    } else if (dateFormat === 'NONE') {
      // see also: dateFormatExists
      value = ''
    }

    return value
  },
  domains: (domains: TPartner['domains']) => {
    return (Array.isArray(domains) && domains.join(', ')) || ''
  },
  eligibilityMethod: (partner: TPartner) => {
    // Derive type of Eligibilty Method from partner data
    // const dateFormatExists = partner?.dateFormat && partner.dateFormat !== 'NONE'
    const hasDateFormat = dateFormatExists(partner?.dateFormat)

    let eligibilityMethodType
    if (partner && partner?.domainOnboarding) {
      eligibilityMethodType = ELIGIBILITY_METHODS.DBO
    } else if (hasDateFormat) {
      eligibilityMethodType = ELIGIBILITY_METHODS.FILE
    } else if (!hasDateFormat && !!partner?.slug) {
      eligibilityMethodType = ELIGIBILITY_METHODS.NONE
    }

    return eligibilityMethodType
  },
  welcomeEmailDateDate: (date: string | null) => {
    let welcomeEmailDateDate = null

    if (date && isValid(new Date(date))) {
      welcomeEmailDateDate = utcDate(date)
    }

    return welcomeEmailDateDate
  },
  welcomeEmailTime: (date: string | null) => {
    let welcomeEmailTime = null

    if (date && isValid(new Date(date))) {
      welcomeEmailTime = utcDate(date)
    }

    return welcomeEmailTime
  },
  welcomeEmailTimezone: (date: string | null) => {
    if (date) return 'UTC' // compute this to be sure we're getting -0000 ?

    return null
  },
  terminationDate: (date?: string | null) => {
    let termDate = null

    if (date && isValid(new Date(date))) {
      termDate = utcDate(date)
    }

    return termDate
  },
}

const serializePartner = {
  dateFormat: (input?: string | null) => {
    const normalizedDate = Object.keys(DATE_FORMAT_LOOKUP).find(key => {
      return DATE_FORMAT_LOOKUP[key] === input
    })

    if (normalizedDate) {
      return normalizedDate as DateFormat
    }

    return null
  },
  domains: (input?: { domain: string }[] | null) => {
    if (Array.isArray(input)) {
      return input.map(d => d.domain.trim())
    }

    return null
  },
  buildDateFromDateParts: ({
    date,
    time,
    timezone,
  }: {
    date: Date | null
    time: Date | null
    timezone: string | null
  }): string | null => {
    if (!date || !time || !timezone) return null

    // TODO - test w/ null values
    const year = date.getFullYear()
    const month = date.getMonth()
    const dt = date.getDate()
    const d = new Date(year, month, dt, time.getHours(), time.getMinutes())

    const dateString = format(d, 'yyyy-MM-dd HH:mm:ss')
    const offsetString = formatTimezoneOffset(new Date(year, month, dt), timezone)

    const dateWithOffset = `${dateString}${offsetString}`
    const newWelcomeEmailDate = new Date(dateWithOffset)

    return newWelcomeEmailDate.toISOString()
  },
}

const isRapidPartner = (partner?: TPartner | null) => {
  return Boolean(partner && partner?.partnerType === Audiences.partnership)
}

const isCorporatePartner = (partner?: TPartner | null) => {
  return Boolean(partner && partner?.partnerType === Audiences.corporate)
}

const formatProgramName = (partnerName: string) => {
  const spaces = /\s+/g
  const cleanedName = partnerName.slice(0, 15).replace(spaces, '-').toLowerCase()

  return `${cleanedName}-benefit-program`
}

const getAltBenefitName = (benefitName: string) => {
  const name = benefitName.toLowerCase()
  switch (name) {
    case 'digital membership':
      return 'Digital Subscription'
    case 'device membership':
      return 'All-Access Membership'
    case 'introductory all-access membership':
      return 'Guide Subscription'
    default:
      return benefitName
  }
}

export {
  formatProgramName,
  dateFormatExists,
  deserializePartner,
  serializePartner,
  isRapidPartner,
  isCorporatePartner,
  getAltBenefitName,
  DATE_FORMAT_LOOKUP,
  ELIGIBILITY_METHODS,
}
