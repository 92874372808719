import { Auth0Provider, CacheLocation } from '@auth0/auth0-react'
import { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

type TAuthProviderProps = {
  children: ReactNode
  useRefreshTokens?: boolean
  connection?: string
  cacheLocation?: CacheLocation
}

type TRedirectCallbackProps = {
  returnTo?: string
}

const DEFAULT_CACHE_LOCATION = 'memory'

export const AuthProvider = ({
  children,
  useRefreshTokens,
  connection,
  cacheLocation = DEFAULT_CACHE_LOCATION,
}: TAuthProviderProps) => {
  const {
    REACT_APP_AUTH0_DOMAIN = '',
    REACT_APP_AUTH0_CLIENT_ID = '',
    REACT_APP_AUTH0_AUDIENCE = '',
  } = process.env

  const history = useHistory()

  const onRedirectCallback = (appState?: TRedirectCallbackProps) => {
    const nextPath = appState?.returnTo || window.location.pathname
    history.push(nextPath)
  }

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      audience={REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={cacheLocation}
      useRefreshTokens={useRefreshTokens}
      connection={connection}
    >
      {children}
    </Auth0Provider>
  )
}
