import { grey } from '@pelotoncycle/design-system'

type tickLabelProps = {
  fill?: string
  fontFamily?: string
  fontSize?: string
  fontWeight?: number
}

const tickLabel = ({
  fill = grey[80],
  fontFamily = 'Roboto,brandon-grotesque,sans-serif',
  fontSize = '12px',
  fontWeight = 500,
}: tickLabelProps): tickLabelProps => ({
  fill,
  fontFamily,
  fontSize,
  fontWeight,
})

const truncateWithDecimal = (x: number, divisor: number) => {
  const val = parseFloat((x / divisor).toFixed(1))

  // chop off decimal value if decimal value is 0 (e.g. 1.0 => 1)
  if (val % Math.trunc(val) > 0) {
    return val
  }

  return Math.trunc(val)
}

const rangeTickFormat = (x: number) => {
  // thousand to ten thousand
  if (x >= 1e3 && x < 1e5) {
    return `${truncateWithDecimal(x, 1e3)}K`
  }
  // hundred thousand
  if (x >= 1e5 && x < 1e6) {
    return `${Math.trunc(x / 1e3)}K`
  }
  // million to ten million
  if (x >= 1e6 && x < 1e8) {
    return `${truncateWithDecimal(x, 1e6)}M`
  }
  // hundred million
  if (x >= 1e8 && x < 1e9) {
    return `${Math.trunc(x / 1e6)}M`
  }
  // billion to ten billion
  if (x >= 1e9 && x < 1e11) {
    return `${truncateWithDecimal(x, 1e9)}B`
  }
  // hundred billion
  if (x >= 1e11 && x < 1e12) {
    return `${Math.trunc(x / 1e9)}B`
  }

  // less than a thousand, or >= trillion
  return x.toLocaleString()
}

const defaultTickLabel = tickLabel({})

export { tickLabel, defaultTickLabel, rangeTickFormat }
