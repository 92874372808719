import { BreakpointWidths } from '@pelotoncycle/design-system'
import { useState, useEffect, useCallback } from 'react'
import { isBrowser } from 'utils'

const { tablet, desktop } = BreakpointWidths

const useWindowWidth = () => {
  const minBreakpointMatch = useCallback(
    (bp: number) => isBrowser && window.matchMedia(`(min-width: ${bp}px)`).matches,
    [],
  )
  const maxBreakpointMatch = useCallback(
    (bp: number) =>
      isBrowser && window.matchMedia(`(max-width: calc(${bp}px - 1px))`).matches,
    [],
  )

  const [minScreenWidths, setMinScreenWidths] = useState({
    isMobile: maxBreakpointMatch(tablet),
    isTablet: minBreakpointMatch(tablet) && maxBreakpointMatch(desktop),
    isDesktop: minBreakpointMatch(desktop),
  })

  useEffect(() => {
    const handler = () => {
      setMinScreenWidths({
        isMobile: maxBreakpointMatch(tablet),
        isTablet: minBreakpointMatch(tablet) && maxBreakpointMatch(desktop),
        isDesktop: minBreakpointMatch(desktop),
      })
    }

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [minBreakpointMatch, maxBreakpointMatch])

  const { isMobile, isTablet, isDesktop } = minScreenWidths

  return {
    isAboveMobile: isTablet || isDesktop,
    isBelowDesktop: isMobile || isTablet,
    ...minScreenWidths,
  }
}

export { useWindowWidth }
