import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  BENEFIT_ADMIN_ROUTE as BASE_PATH,
  BENEFIT_ADMIN_UPLOADS_ROUTE,
  BENEFIT_ADMIN_UPLOAD_DETAILS_ROUTE,
  BENEFIT_ADMIN_END_BENEFIT_ROUTE,
} from 'utils/constants/routes'
import { Page, SubNav } from '../../..'
import { FileDetail } from '../FileDetail'
import { FileList } from '../FileList'
import { MembersSearch } from '../MembersSearch'

const BenefitAdmin = () => {
  const { t } = useTranslation()

  const SUBPATHS = [
    {
      path: BENEFIT_ADMIN_UPLOADS_ROUTE,
      displayName: t('benefit_admin.upload_eligibility_file'),
    },
    {
      path: BENEFIT_ADMIN_END_BENEFIT_ROUTE,
      displayName: t('benefit_admin.remove_employee_benefit'),
    },
  ]

  return (
    <Page title={t('benefit_admin.benefit_admin')} testId="benefit-admin-page">
      <SubNav paths={SUBPATHS} />

      <Switch>
        <Route exact path={BENEFIT_ADMIN_UPLOADS_ROUTE}>
          <FileList />
        </Route>
        <Route path={BENEFIT_ADMIN_UPLOAD_DETAILS_ROUTE}>
          <FileDetail />
        </Route>
        <Route path={BENEFIT_ADMIN_END_BENEFIT_ROUTE}>
          <MembersSearch />
        </Route>
        <Redirect from={BASE_PATH} to={BENEFIT_ADMIN_UPLOADS_ROUTE} />
      </Switch>
    </Page>
  )
}

export { BenefitAdmin }
