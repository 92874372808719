import { white } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const X = ({ width = '18px', height = '18px', fill = white }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.027 9L17.787 16.7604C18.071 17.0433 18.071 17.5042 17.787 17.7871C17.645 17.929 17.459 18 17.274 18C17.088 18 16.902 17.929 16.76 17.7871L9 10.0277L1.24 17.7871C1.098 17.929 0.912 18 0.727 18C0.541 18 0.355 17.929 0.213 17.7871C-0.071 17.5042 -0.071 17.0433 0.213 16.7604L7.973 9L0.213 1.24059C-0.071 0.956681 -0.071 0.496834 0.213 0.212929C0.497 -0.0699767 0.956 -0.0699767 1.24 0.212929L9 7.97334L16.76 0.212929C17.044 -0.0709763 17.504 -0.0709763 17.787 0.212929C18.071 0.496834 18.071 0.956681 17.787 1.24059L10.027 9Z"
        fill={fill}
      />
    </svg>
  )
}

export { X }
