export const BENEFIT_ADMIN_ROUTE = '/benefit-admin'
export const BENEFIT_ADMIN_END_BENEFIT_ROUTE = `${BENEFIT_ADMIN_ROUTE}/remove`
export const BENEFIT_ADMIN_UPLOADS_ROUTE = `${BENEFIT_ADMIN_ROUTE}/uploads`
export const BENEFIT_ADMIN_UPLOAD_DETAILS_ROUTE = `${BENEFIT_ADMIN_UPLOADS_ROUTE}/:fileId`

export const INSIGHTS_ROUTE = '/insights'
export const CHALLENGES_BASE_ROUTE = '/insights/challenges'
export const CHALLENGES_ROUTE = '/insights/challenges/:challengeId'

export const DOCUMENTS_ROUTE = '/documents'
export const DOCUMENTS_ENROLLMENT_ROUTE = `${DOCUMENTS_ROUTE}/enrollment`

export const SUPPORT_ROUTE = '/support'
export const SUPPORT_GETTING_STARTED_ROUTE = `${SUPPORT_ROUTE}/getting-started`
export const SUPPORT_TEMPLATES_ROUTE = `${SUPPORT_ROUTE}/templates`
export const SUPPORT_TROUBLESHOOTING_ROUTE = `${SUPPORT_ROUTE}/troubleshooting`

export const ROOT_REDIRECT = BENEFIT_ADMIN_UPLOADS_ROUTE
