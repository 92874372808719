import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import {
  GET_TOTALS,
  GET_CLASS_TYPES,
  GET_CLASSES_COMPLETED,
  GET_WORKOUT_MINUTES,
  GET_MINUTES_PER_ENGAGED,
  GET_PARTNER_BY_USER,
  GET_ENROLLED,
  GET_ENGAGED,
} from '../queries'
import { ClassesCompleted } from '../queries/types/ClassesCompleted'
import {
  ClassTypes,
  ClassTypes_classTypes as TClassTypes,
} from '../queries/types/ClassTypes'
import { Engaged } from '../queries/types/Engaged'
import { Enrolled } from '../queries/types/Enrolled'
import {
  MinutesPerEngaged,
  MinutesPerEngaged_minutesPerEngaged as TMetric,
  MinutesPerEngagedVariables as TMetricVariables,
} from '../queries/types/MinutesPerEngaged'
import { PartnerByUser } from '../queries/types/PartnerByUser'
import { Totals } from '../queries/types/Totals'
import { WorkoutMinutes } from '../queries/types/WorkoutMinutes'

type OptionalPartnerId = {
  partnerId?: string
}

const INSIGHTS_EARLIEST_DATA_AVAILABILITY = '2021-06-01'
// if a metric has a value less than the threshold, the backend returns the metric's value as zero
const INSIGHTS_DATA_THRESHOLD = 20
const epoch = new Date(INSIGHTS_EARLIEST_DATA_AVAILABILITY)
const today = new Date()

const stripTz = (date: string) => new Date(`${date}T00:00:00`)

const cleanTimeSeriesData = (data: (TMetric | null)[], variables: TMetricVariables) => {
  return data
    .filter(val => !!val)
    .map((val, i) => {
      if (val && typeof val.date === 'string') {
        const date = stripTz(val.date)
        let label

        if (variables.period === 'weekly') {
          label = format(date, 'MMM d')
        } else {
          label = format(date, 'MMM')
        }

        return {
          x: i + 1,
          y: val.count,
          label,
        }
      }

      return val
    })
}

const useTotals = ({ partnerId }: OptionalPartnerId = {}) => {
  const { loading, error, data } = useQuery<Totals>(GET_TOTALS, {
    variables: { partnerId },
  })

  const totals = data?.totals || []

  const cleanTotals: Record<string, number> = totals.reduce((memo, val) => {
    if (val && val.count > 0) {
      return { [val.name]: val.count, ...memo }
    }

    return memo
  }, {})
  const hasData = !!Object.keys(cleanTotals).length

  const getTotal = (key: string) => cleanTotals[key] || 0

  const hasTotal = (key: string) => cleanTotals.hasOwnProperty(key)

  return {
    loading,
    error,
    data,
    hasData,
    getTotal,
    hasTotal,
  }
}

const MAX_CLASS_TYPES = 5
const truncateClassTypes = (classTypes: TClassTypes[]) => {
  const cleanClassTypes = classTypes
    .sort((a, b) => {
      if (b.count > a.count) {
        return 1
      }
      if (a.count > b.count) {
        return -1
      }

      return 0
    })
    .map((val, i) => {
      return {
        x: i,
        y: val.count,
        label: val.tag || '',
      }
    })

  let truncatedClassTypes = cleanClassTypes
  if (cleanClassTypes.length > MAX_CLASS_TYPES) {
    truncatedClassTypes = cleanClassTypes.slice(0, MAX_CLASS_TYPES - 1)
    const otherClassTypes = cleanClassTypes.slice(MAX_CLASS_TYPES - 1).reduce(
      (memo, val) => {
        return { ...memo, y: memo.y + val.y }
      },
      { x: MAX_CLASS_TYPES - 1, y: 0, label: 'other' },
    )
    truncatedClassTypes.push(otherClassTypes)
  }

  return truncatedClassTypes
}

const useClassTypes = ({ partnerId }: OptionalPartnerId = {}) => {
  const { loading, error, data } = useQuery<ClassTypes>(GET_CLASS_TYPES, {
    variables: { partnerId },
  })

  const classTypes = data?.classTypes || []

  const cleanData = classTypes.filter(val => !!val && val.count > 0)
  const truncatedClassTypes = truncateClassTypes(cleanData)
  const hasData = !!truncatedClassTypes.length
  const total = truncatedClassTypes.reduce((memo, val) => {
    return memo + val.y
  }, 0)

  return {
    loading,
    error,
    data: truncatedClassTypes,
    total,
    hasData,
  }
}

const useClassesCompleted = ({ partnerId }: OptionalPartnerId = {}) => {
  const { loading, error, data } = useQuery<ClassesCompleted>(GET_CLASSES_COMPLETED, {
    variables: {
      startDate: format(epoch, 'yyyy-MM-dd'),
      endDate: format(today, 'yyyy-MM-dd'),
      period: 'weekly',
      partnerId,
    },
  })

  const classesCompleted = data?.classesCompleted || []

  const cleanClassesCompleted = classesCompleted
    .filter(val => !!val)
    .map(val => {
      if (val && val.date && typeof val.date === 'string') {
        return {
          x: stripTz(val.date).getTime(),
          y: val.count,
        }
      }

      return val
    })
    .reverse()
  const hasData = !!cleanClassesCompleted.length

  return { loading, error, data: cleanClassesCompleted, hasData }
}

const useWorkoutMinutes = ({ partnerId }: OptionalPartnerId = {}) => {
  const { loading, error, data } = useQuery<WorkoutMinutes>(GET_WORKOUT_MINUTES, {
    variables: {
      startDate: format(epoch, 'yyyy-MM-dd'),
      endDate: format(today, 'yyyy-MM-dd'),
      period: 'weekly',
      partnerId,
    },
  })

  const workoutMinutes = data?.workoutMinutes || []

  const cleanWorkoutMinutes = workoutMinutes
    .filter(val => !!val)
    .map(val => {
      if (val && val.date && typeof val.date === 'string') {
        return {
          x: stripTz(val.date).getTime(),
          y: val.count,
        }
      }

      return val
    })
    .reverse()
  const hasData = !!cleanWorkoutMinutes.length

  return { loading, error, data: cleanWorkoutMinutes, hasData }
}

const useMinutesPerEngaged = (variables: TMetricVariables) => {
  const { loading, error, data } = useQuery<MinutesPerEngaged>(GET_MINUTES_PER_ENGAGED, {
    variables,
  })

  const minutesPerEngaged = data?.minutesPerEngaged || []

  const cleanMinutesPerEngaged = cleanTimeSeriesData(minutesPerEngaged, variables)
  const hasData = !!cleanMinutesPerEngaged.length

  return { loading, error, data: cleanMinutesPerEngaged, hasData }
}

const useEnrolledEngaged = (variables: TMetricVariables) => {
  const {
    loading: enrolledLoading,
    error: enrolledError,
    data: enrolledData,
  } = useQuery<Enrolled>(GET_ENROLLED, { variables })
  const {
    loading: engagedLoading,
    error: engagedError,
    data: engagedData,
  } = useQuery<Engaged>(GET_ENGAGED, { variables })

  let enrolled = enrolledData?.enrolled || []
  enrolled = Array.from(enrolled).reverse()
  const cleanEnrolled = cleanTimeSeriesData(enrolled as TMetric[], variables)

  let engaged = engagedData?.engaged || []
  engaged = Array.from(engaged).reverse()
  const cleanEngaged = cleanTimeSeriesData(engaged as TMetric[], variables)

  const hasData = !!cleanEnrolled.length && !!cleanEngaged.length

  return {
    loading: enrolledLoading || engagedLoading,
    error: enrolledError || engagedError,
    hasData,
    enrolled: cleanEnrolled,
    engaged: cleanEngaged,
  }
}

const usePartnerByUser = () => {
  const { loading, error, data } = useQuery<PartnerByUser>(GET_PARTNER_BY_USER)

  return { loading, error, data }
}

const useHasDashboard = ({ partnerId }: OptionalPartnerId = {}) => {
  const {
    loading: totalsLoading,
    hasData: hasTotalsData,
    getTotal,
  } = useTotals({ partnerId })
  const { loading: classTypesLoading } = useClassTypes({ partnerId })
  const { loading: classesCompletedLoading } = useClassesCompleted({ partnerId })
  const { loading: workoutMinutesLoading } = useWorkoutMinutes({ partnerId })

  const loading =
    totalsLoading || classTypesLoading || classesCompletedLoading || workoutMinutesLoading
  const hasData = hasTotalsData && getTotal('enrolled_total') >= INSIGHTS_DATA_THRESHOLD
  const hasDashboard = loading || hasData

  return hasDashboard
}

export {
  epoch,
  today,
  useTotals,
  useClassTypes,
  useClassesCompleted,
  useWorkoutMinutes,
  useHasDashboard,
  usePartnerByUser,
  useMinutesPerEngaged,
  useEnrolledEngaged,
  truncateClassTypes,
}
