import { Headline } from '@pelotoncycle/design-system'
import { useTop5Classes, useTop5Instructors } from 'data/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Bar, Data, Datum } from 'ui/components/Insights/graphs'

type Props = {
  title: string
  data: Data
}

type TopFiveProps = {
  challengeId: string
}

const cleanDuplicateLabels = (data: Data) =>
  data.reduce((memo, datum) => {
    const hasLabel = (l: string) => (d: Datum) => d?.label === l
    const label = datum?.label || ''

    let newLabel = label
    let i = 0
    while (memo.findIndex(hasLabel(newLabel)) >= 0) {
      i += 1
      newLabel = `${label} (${i})`
    }

    memo.push({
      ...datum,
      label: newLabel,
    })

    return memo
  }, [] as Datum[])

const TopFiveMetric: React.FC<Props> = ({ title, data }) => {
  const { t } = useTranslation()

  const cleanData = cleanDuplicateLabels(data)

  return (
    <Container>
      <Title size="small">{t(title)}</Title>
      <Bar
        title={t(title)}
        tooltipLabel="insights.class"
        barWidth={48}
        dark
        data={cleanData}
      />
    </Container>
  )
}

const TopFiveInstructors: React.FC<TopFiveProps> = ({ challengeId }) => {
  const { hasTop5Instructors, top5Instructors } = useTop5Instructors({ challengeId })

  if (!hasTop5Instructors) {
    return null
  }

  return <TopFiveMetric title="insights.top_five_instructors" data={top5Instructors} />
}

const TopFiveClassTypes: React.FC<TopFiveProps> = ({ challengeId }) => {
  const { hasTop5Classes, top5Classes } = useTop5Classes({ challengeId })

  if (!hasTop5Classes) {
    return null
  }

  return <TopFiveMetric title="insights.top_five_class_types" data={top5Classes} />
}

const Container = styled.div`
  background: #26282c;
  border: 2px solid #393c3f;
  border-radius: 4px;
  padding: 30px 40px 40px;
  margin-bottom: 1.5rem;
`

const Title = styled(Headline)``

export { TopFiveInstructors, TopFiveClassTypes, cleanDuplicateLabels }
