import { Factory } from 'fishery'
import { FileRunWithFileRunErrorFields } from '../fragments/types/FileRunWithFileRunErrorFields'
import { FileRunStatus } from '../types/graphql-global-types'
import {
  failedFileRunErrorsFactory,
  fileRunErrorsFactory,
  succeededWithErrorsFileRunErrorsFactory,
} from './fileRunErrors'

const baseParams = {
  created: '2021-02-01T21:21:29.805990+00:00',
  eligibilityUnchanged: 0,
  eligibilityAdded: 0,
  eligibilityTotalAdded: 0,
  eligibilityRemoved: 0,
  eligibilityWillBeRemoved: 0,
  totalRowsValid: 0,
  totalRowErrors: 0,
}

const fileRunFactory = Factory.define<FileRunWithFileRunErrorFields>(({ sequence }) => ({
  __typename: 'FileRun',
  ...baseParams,
  id: `${sequence}`,
  status: FileRunStatus.SUCCESS,
  fileRunErrors: fileRunErrorsFactory.build(),
}))

const runningFileRunFactory = fileRunFactory.params({
  ...baseParams,
  fileRunErrors: fileRunErrorsFactory.build(),
})

const failedFileRunFactory = fileRunFactory.params({
  ...baseParams,
  fileRunErrors: failedFileRunErrorsFactory.build(),
})

const succeededWithErrorsFileRunFactory = fileRunFactory.params({
  ...baseParams,
  fileRunErrors: succeededWithErrorsFileRunErrorsFactory.build(),
})

export {
  fileRunFactory,
  failedFileRunFactory,
  runningFileRunFactory,
  succeededWithErrorsFileRunFactory,
}
