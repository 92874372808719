import { black } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const Check = ({ width = '12px', height = '12px', fill = black }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00379 8.61634L1.448 5.05917L0 6.5967L5.40119 12L12 1.13705L10.2379 0L5.00379 8.61634Z"
      fill={fill}
    />
  </svg>
)

export { Check }
