import { grey, data } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowWidth } from 'ui/hooks'
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory'
import { Tooltip } from './Tooltip'
import { Data, LabelData } from './types'
import { defaultTickLabel, rangeTickFormat } from './utils'

type LineProps = {
  data: Data
  tooltipLabel: string
}

type MultiLineProps = {
  title: string
  enrolled: LineProps
}

const MultiLine: React.FC<React.PropsWithChildren<MultiLineProps>> = ({
  title,
  enrolled,
}) => {
  const { t } = useTranslation()
  const { isAboveMobile } = useWindowWidth()
  const showMobile = !isAboveMobile
  const tickValues = enrolled.data.map(({ label: l }) => l)

  const enrolledData = enrolled.data.map(({ x, y }) => ({
    x,
    y,
    symbol: 'circle',
    t: enrolled.tooltipLabel,
  }))

  const Container = (
    <VictoryVoronoiContainer
      title={title}
      voronoiBlacklist={['enrolledLine', 'engagedLine']}
      labels={(d: LabelData) =>
        t(d.datum.t as string, { num: d.datum.y?.toLocaleString() })
      }
      labelComponent={<Tooltip />}
      radius={25}
    />
  )

  return (
    <VictoryChart
      width={showMobile ? 400 : 1000}
      height={400}
      domainPadding={{ y: 50 }}
      containerComponent={Container}
    >
      <VictoryAxis
        dependentAxis
        tickCount={4}
        tickFormat={rangeTickFormat}
        style={{
          axis: {
            stroke: 'none',
          },
          tickLabels: defaultTickLabel,
          grid: {
            stroke: grey[40],
          },
        }}
      />
      <VictoryAxis
        tickValues={tickValues}
        tickCount={showMobile ? 4 : tickValues.length}
        style={{ axis: { stroke: grey[40] }, tickLabels: defaultTickLabel }}
      />
      <VictoryLine
        name="enrolledLine"
        data={enrolledData}
        style={{
          data: {
            stroke: data.orange,
            strokeWidth: 1,
          },
        }}
      />
      <VictoryScatter
        name="enrolledScatter"
        data={enrolledData}
        size={4}
        style={{ data: { fill: data.orange } }}
      />
    </VictoryChart>
  )
}

export { MultiLine }
