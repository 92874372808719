import { data } from '@pelotoncycle/design-system'
import { useEnrolledEngaged } from 'data/hooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { useWindowWidth } from 'ui/hooks'
import { media } from 'ui/responsive'
import { defaultSelection, DateRange } from './DateRangeSelector'
import { MultiLine, Datum } from './graphs'
import { Item } from './graphs/Donut/Legend/Item'
import { Module } from './Module'

type Props = {
  defaultDateRange?: DateRange
}

const EnrolledEngagedLineChart: React.FC<React.PropsWithChildren<Props>> = ({
  defaultDateRange = defaultSelection,
}) => {
  const { t } = useTranslation()
  const { partnerId } = usePartnerContext()
  const { isBelowDesktop } = useWindowWidth()
  const [range, setRange] = useState<DateRange>(defaultDateRange)
  const { loading, error, hasData, enrolled } = useEnrolledEngaged({
    ...range,
    partnerId,
  })
  const dateRangeSelect = (dateRange: DateRange) => setRange(dateRange)

  if (loading) {
    return (
      <Module
        loading
        emptyHeight="350px"
        label="insights.enrolled_engaged_users"
        dateRangeSelectId="enrolledEngaged"
        dateRangeSelect={dateRangeSelect}
      />
    )
  }
  if (error || !hasData) {
    return (
      <Module
        empty
        emptyHeight="350px"
        label="insights.enrolled_engaged_users"
        dateRangeSelectId="enrolledEngaged"
        dateRangeSelect={dateRangeSelect}
      />
    )
  }

  const legend = (
    <Legend>
      <ItemStyled color={data.orange} label={t('insights.enrolled_key')} />
    </Legend>
  )

  return (
    <Module
      label="insights.enrolled_engaged_users"
      dateRangeSelectId="enrolledEngaged"
      dateRangeSelect={dateRangeSelect}
      legend={!isBelowDesktop && legend}
    >
      <MultiLine
        title={t('insights.enrolled_engaged_users')}
        enrolled={{ data: enrolled as Datum[], tooltipLabel: 'insights.enrolled' }}
      />
      {isBelowDesktop && legend}
    </Module>
  )
}

const Legend = styled.div`
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;

  ${media.atDesktop`
    margin-top: 0;
  `}
`

const ItemStyled = styled(Item)`
  padding: 0 1rem;
  margin-bottom: 0;
`

export { EnrolledEngagedLineChart }
