import { grey } from '@pelotoncycle/design-system'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from '@reach/combobox'
import { useEffect, useState, useRef, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDebounce } from '../hooks'
import { Search } from './svg'

type TResultOption = {
  value: string
  disabled: boolean
}

type TProps = {
  onChange: (value: string) => void
  onSelect: (value: string) => void
  placeholder?: string
  results: TResultOption[]
  showSearchIcon?: boolean
}
type TDisabledOptionProps = {
  text: string
}

const DisabledOption = ({ text }: TDisabledOptionProps) => {
  return (
    <StyledDisabledOption data-testid="disabled-option" data-reach-combobox-option>
      {text}
    </StyledDisabledOption>
  )
}

const SearchCombobox = ({
  onChange,
  results,
  onSelect,
  placeholder,
  showSearchIcon = true,
}: TProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchInput, setSearchInput] = useState('')
  const SEARCH_DELAY = 300
  const debouncedSearchInput = useDebounce<string>(searchInput, SEARCH_DELAY)
  const { t } = useTranslation()
  const defaultPlaceholder = t('search')
  const hasResults = results && !!results.length

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    setSearchInput(searchTerm.trim())
  }

  useEffect(() => {
    if (debouncedSearchInput) {
      onChange(debouncedSearchInput)
    }
  }, [debouncedSearchInput, onChange])

  const focusInput = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <StyledCombobox onSelect={onSelect}>
      {showSearchIcon && (
        <SvgContainer onClick={focusInput}>
          <Search />
        </SvgContainer>
      )}
      <ComboboxInput
        value={searchInput}
        placeholder={placeholder || defaultPlaceholder}
        onChange={handleSearchInputChange}
        ref={inputRef}
      />
      <ComboboxPopover>
        {hasResults ? (
          <StyledComboboxList>
            {results.map(({ value, disabled }) => {
              return disabled ? (
                <DisabledOption key={value} text={value} />
              ) : (
                <ComboboxOption key={value} value={value}>
                  <ComboboxOptionText />
                </ComboboxOption>
              )
            })}
          </StyledComboboxList>
        ) : (
          <NoResults>{t('no_results')}</NoResults>
        )}
      </ComboboxPopover>
    </StyledCombobox>
  )
}

const StyledCombobox = styled(Combobox)`
  height: 56px;
  flex-grow: 1;
  border: 1px solid ${grey[70]};
  border-radius: 2px;
  background-color: white;
  margin-right: 24px;
  max-width: 500px;
  position: relative;

  [data-reach-combobox-input] {
    width: 100%;
    height: 100%;
    padding-left: 16px;
    padding-right: 48px;
  }

  [data-reach-combobox-list] {
    font-size: 24px;
    list-style: none;
    font-size: 16px;
  }
`

const StyledComboboxList = styled(ComboboxList)`
  font-size: 24px;
  list-style: none;
  font-size: 16px;

  [data-reach-combobox-option] {
    padding: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const NoResults = styled.div`
  font-size: 24px;
  list-style: none;
  font-size: 16px;
  color: grey[90];
  padding: 16px;
  font-style: italic;
`
const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: text;
`

const StyledDisabledOption = styled.div`
  color: ${grey[50]};

  &:hover {
    background-color: white;
    cursor: default;
  }
`

export { SearchCombobox }
