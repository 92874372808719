import { gql } from '@apollo/client'

const APPROVE_REPORTS = gql`
  mutation ApproveReports($reportIds: [String!]!) {
    approveReports(reportIds: $reportIds) {
      requestOk
      requestErrors {
        errorCode
        errorCodeName
        message
        field
      }
    }
  }
`

export { APPROVE_REPORTS }
