export enum Period {
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export type DateRange = {
  startDate: string
  endDate: string
  period: Period
}
