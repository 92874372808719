import { black, white } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

type TProps = IconProps & {
  primaryFill?: string
  backgroundFill?: string
}

const XCircle = ({
  width = '16px',
  height = '16px',
  primaryFill = black,
  backgroundFill = white,
}: TProps) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill={backgroundFill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.67075 7.99981L11.0638 5.59765C11.2487 5.41224 11.2449 5.11471 11.0554 4.93306C10.8666 4.75204 10.5626 4.75642 10.3764 4.94183L8.00001 7.32708L5.62363 4.94183C5.43867 4.75642 5.1353 4.75204 4.94521 4.93306C4.75513 5.11471 4.75129 5.41224 4.93625 5.59765L7.32927 7.99981L4.93625 10.402C4.75129 10.588 4.75513 10.8855 4.94521 11.0666C5.03802 11.1555 5.15962 11.2 5.27994 11.2C5.40475 11.2 5.52955 11.1524 5.62363 11.0578L8.00001 8.67255L10.3764 11.0578C10.4711 11.1524 10.5953 11.2 10.7201 11.2C10.841 11.2 10.962 11.1555 11.0554 11.0666C11.2449 10.8855 11.2487 10.588 11.0638 10.402L8.67075 7.99981Z"
      fill={primaryFill}
    />
  </svg>
)

export { XCircle }
