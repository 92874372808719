import { black } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const AlertTriangle = ({ width = '49px', height = '42px', fill = black }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 49 42" fill="none">
    <path
      d="M3.97071 41.6509H44.0361C45.501 41.6509 46.7979 40.8853 47.5054 39.6021C48.2129 38.3208 48.1694 36.8169 47.4033 35.6016L27.355 1.8457C26.6235 0.689941 25.3706 0 24.0034 0C22.6362 0 21.3833 0.689941 20.6367 1.86963L0.617197 35.5791C-0.162588 36.8169 -0.206045 38.3208 0.501475 39.6021C1.20899 40.8853 2.50587 41.6509 3.97071 41.6509ZM2.32325 36.623L22.3413 2.91504C22.7095 2.3335 23.3154 2 24.0034 2C24.6914 2 25.2974 2.3335 25.6504 2.89111L45.6973 36.6455C46.083 37.2578 46.1045 38.0015 45.7544 38.6362C45.4038 39.272 44.7617 39.6509 44.0361 39.6509H3.97071C3.24513 39.6509 2.60304 39.272 2.25245 38.6362C1.90235 38.0015 1.92384 37.2578 2.32325 36.623Z"
      fill={fill}
    />
    <path
      d="M22.5843 25.1374L22.1692 18.8142C22.0881 17.5419 21.6621 15.4856 22.6155 14.4519C23.3411 13.6567 25.0591 13.52 25.5803 14.6221C26.0234 15.9171 26.1214 17.3089 25.8643 18.6552L25.3088 25.1644C25.2849 25.7771 25.1533 26.3804 24.9202 26.9456C24.8285 27.1285 24.6894 27.2823 24.518 27.3905C24.3467 27.4986 24.1495 27.5569 23.948 27.559C23.7464 27.5611 23.5482 27.507 23.3746 27.4025C23.2011 27.2981 23.0589 27.1472 22.9635 26.9663C22.7439 26.3806 22.6159 25.7635 22.5843 25.1374V25.1374ZM24.0214 33.8254C23.5294 33.8226 23.0568 33.6288 22.7 33.2835C22.3432 32.9382 22.1288 32.4672 22.1006 31.9666C22.0724 31.4659 22.2324 30.973 22.548 30.5883C22.8637 30.2036 23.3114 29.9559 23.7999 29.8957C24.0623 29.8633 24.3285 29.8861 24.5819 29.9626C24.8354 30.0392 25.0708 30.1678 25.2736 30.3407C25.4764 30.5135 25.6423 30.7269 25.761 30.9676C25.8797 31.2083 25.9488 31.4713 25.964 31.7403C25.9792 32.0093 25.9401 32.2786 25.8492 32.5316C25.7583 32.7846 25.6175 33.0159 25.4354 33.2113C25.2534 33.4067 25.034 33.562 24.7908 33.6676C24.5476 33.7732 24.2858 33.8269 24.0214 33.8254V33.8254Z"
      fill={fill}
    />
  </svg>
)

export { AlertTriangle }
