/* eslint-disable react/jsx-props-no-spreading */
import { IconProps } from './IconTypes'

export type ChevronProps = IconProps & {
  rotate?: string
}

const Chevron = ({ width = '12px', height = '7px', ...styles }: ChevronProps) => (
  <svg width={width} height={height} viewBox="0 0 12 7" {...styles}>
    <title>Chevron</title>
    <path d="M5.981 4.92l-4.7-4.7A.75.75 0 0 0 .22 1.28l5.163 5.163a.748.748 0 0 0 .598.217.748.748 0 0 0 .6-.217l5.162-5.163a.75.75 0 0 0-1.06-1.06L5.98 4.92z" />
  </svg>
)

export { Chevron }
