import { white, black, grey } from '@pelotoncycle/design-system'
import styled from 'styled-components'
import { StepNavigationLink } from './Links'

export type TStep = {
  name: string
  path: string
  complete: boolean
}

type TStepsParams = {
  steps: TStep[]
}

const StepNavigation = ({ steps }: TStepsParams) => {
  return (
    <FlexNav>
      {steps.map(({ name, path, complete }, index) => {
        const primaryColor = complete ? black : grey[60]

        return (
          <StepNavigationLink
            key={name}
            stepNumber={String(index + 1)}
            text={name}
            to={path}
            color={primaryColor}
            complete={complete}
          />
        )
      })}
    </FlexNav>
  )
}

const FlexNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${white};
  height: 72px;

  > a {
    margin-left: 48px;
  }
`

export { StepNavigation }
