import { black } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const CheckCircleOutline = ({
  width = '43px',
  height = '43px',
  fill = black,
}: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 43 43" fill="none">
    <path
      d="M21.084 42.1681C9.45906 42.1681 0 32.709 0 21.084C0 9.45906 9.45906 0 21.084 0C25.1264 0 29.0595 1.15195 32.4579 3.33319C32.9026 3.61879 33.0329 4.21106 32.7473 4.65765C32.4598 5.10234 31.8676 5.23267 31.4229 4.94708C28.3331 2.96326 24.7584 1.91673 21.084 1.91673C10.5152 1.91673 1.91673 10.5152 1.91673 21.084C1.91673 31.6529 10.5152 40.2513 21.084 40.2513C31.6529 40.2513 40.2513 31.6529 40.2513 21.084C40.2513 20.4515 40.2207 19.8286 40.1612 19.2133C40.1095 18.6862 40.4948 18.2166 41.0219 18.1668C41.5585 18.1227 42.0186 18.5003 42.0684 19.0274C42.1336 19.704 42.1681 20.3902 42.1681 21.084C42.1681 32.709 32.709 42.1681 21.084 42.1681Z"
      fill={fill}
    />
    <path
      d="M19.5841 28.0012C19.3388 28.0012 19.0934 27.9073 18.9056 27.7214L10.2803 19.0961C9.90656 18.7224 9.90656 18.1147 10.2803 17.741C10.6541 17.3672 11.2617 17.3672 11.6354 17.741L19.5822 25.6877L39.9896 5.28032C40.3634 4.90656 40.971 4.90656 41.3448 5.28032C41.7185 5.65408 41.7185 6.26169 41.3448 6.63545L20.2607 27.7195C20.0748 27.9073 19.8295 28.0012 19.5841 28.0012Z"
      fill={fill}
    />
  </svg>
)

export { CheckCircleOutline }
