import { grey } from '@pelotoncycle/design-system'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Chevron } from './svg'

type TLinkBackToProps = {
  to: string
  title: string
  className?: string
}

const LinkBackTo = ({ to, title, className }: TLinkBackToProps) => (
  <StyledLink className={className} to={to} data-testid={`back-to-${to}`}>
    <Content>
      <StyledChevron />
      {title && title}
    </Content>
  </StyledLink>
)

const StyledLink = styled(Link)`
  display: inline-block;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: 14px;
  color: ${grey[80]};
`

const Content = styled.div`
  display: flex;
  align-items: center;
`

const StyledChevron = styled(Chevron)`
  fill: ${grey[50]};
  margin-right: 16px;
  transform: rotate(90deg);
`

export { LinkBackTo }
