import { useContext } from 'react'
import { PartnerContext } from './PartnerContext'

function usePartnerContext() {
  const context = useContext(PartnerContext)

  if (context === undefined) {
    throw new Error('usePartnerContext must be used within an PartnerProvider')
  }

  return context
}

export { usePartnerContext }
