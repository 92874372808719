import { gql } from '@apollo/client'

const CREATE_PROGRAM_BENEFIT = gql`
  mutation ProgramBenefitCreate($programId: ID!, $input: ProgramBenefitInput!) {
    programBenefitCreate(programId: $programId, programBenefitInput: $input) {
      requestOk
      programBenefit {
        id
      }
    }
  }
`

const UPDATE_PROGRAM_BENEFIT = gql`
  mutation ProgramBenefitUpdate($programBenefitId: ID!, $input: ProgramBenefitInput!) {
    programBenefitUpdate(
      programBenefitId: $programBenefitId
      programBenefitInput: $input
    ) {
      requestOk
    }
  }
`

export { CREATE_PROGRAM_BENEFIT, UPDATE_PROGRAM_BENEFIT }
