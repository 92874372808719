import { white, grey, Headline, Body, Support } from '@pelotoncycle/design-system'
import { useChallenge } from 'data/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Page, NotFoundPage, LoadingScreen, LinkBackTo } from 'ui/components'
import {
  ParticipantsMetric,
  FinishersMetric,
  DaysMetric,
  ClassesTakenMetric,
  MinutesCompletedMetric,
  TopFiveInstructors,
  TopFiveClassTypes,
} from 'ui/components/Insights/Challenges'
import { media } from 'ui/responsive'
import { useChallengeMap, useKeyForChallengeId } from './data'
import { Selector } from './Selector'
import bg from './static/pageBg.png'

interface RouteParams {
  challengeId: string
}

type Props = {
  challengeId: string
  selection: string
  challengeSelect: (challenge: string) => void
}

const Content: React.FC<Props> = ({ challengeId, selection, challengeSelect }) => {
  const { t } = useTranslation()
  const { challenge } = useChallenge({ challengeId })

  if (!challenge) {
    return null
  }

  return (
    <>
      <LinkBackToStyled to="/insights" title={t('insights.back_to_insights')} />
      <SelectorStyled selection={selection} challengeSelect={challengeSelect} />
      <HeadlineStyled size="extraLarge">{challenge.title}</HeadlineStyled>
      <BodyStyled size="large">{challenge.description}</BodyStyled>
      <Metrics>
        <Metric>
          <ParticipantsMetric challengeId={challengeId} showPercentage dark />
        </Metric>
        <Metric>
          <FinishersMetric challengeId={challengeId} showPercentage dark />
        </Metric>
        <Metric>
          <DaysMetric challengeId={challengeId} showPercentage dark />
        </Metric>
      </Metrics>
      <TotalMetrics>
        <Metric>
          <ClassesTakenMetric challengeId={challengeId} />
        </Metric>
        <Metric>
          <MinutesCompletedMetric challengeId={challengeId} />
        </Metric>
      </TotalMetrics>
      <TopFiveContainer>
        <TopFiveInstructors challengeId={challengeId} />
        <TopFiveClassTypes challengeId={challengeId} />
      </TopFiveContainer>
      <Disclaimer size="small">{t('insights.challenges_disclaimer')}</Disclaimer>
    </>
  )
}

const Dashboard = () => {
  const { challengeId } = useParams<RouteParams>()
  const history = useHistory()
  const { loading: mapLoading, error: mapError, challengeMap } = useChallengeMap()
  const selectedKey = useKeyForChallengeId(challengeId)
  const { loading, error, challenge } = useChallenge({ challengeId })

  let content
  if (loading || mapLoading) {
    content = <LoadingScreen />
  } else if (error || mapError || !challenge) {
    content = <NotFoundPage dark />
  } else {
    content = (
      <Content
        challengeId={challengeId}
        selection={selectedKey as string}
        challengeSelect={key => {
          history.push(`/insights/challenges/${challengeMap[key]}`)
        }}
      />
    )
  }

  return (
    <Container>
      <Dots />
      <PageStyled>{content}</PageStyled>
    </Container>
  )
}

const PageStyled = styled(Page)`
  position: relative;
`

const SelectorStyled = styled(Selector)`
  margin-bottom: 2rem;
`

const HeadlineStyled = styled(Headline)`
  margin-bottom: 1.5rem;
`

const BodyStyled = styled(Body)`
  margin-bottom: 4rem;
  color: ${grey[40]};
`

const Container = styled.div`
  position: relative;
  min-height: calc(100vh - var(--header-height));
  background: ${grey[90]};
  color: ${white};
`

const LinkBackToStyled = styled(LinkBackTo)`
  margin-bottom: 3rem;
  font-weight: 300;
  color: ${white};

  svg {
    fill: ${white};
  }
`

const Dots = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: url(${bg}) no-repeat top right;
  opacity: 1;
  overflow: hidden;
`

const Disclaimer = styled(Support)`
  max-width: 530px;
`

const Metrics = styled.div`
  display: grid;
  margin-bottom: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);

  ${media.atTablet`
    margin-bottom: 1.5rem;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.atDesktop`
    grid-template-columns: repeat(3, 1fr);
  `}
`

const TotalMetrics = styled(Metrics)`
  grid-template-columns: repeat(1, 1fr);

  ${media.atTablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const Metric = styled.div`
  display: flex;
  background: #26282c;
  border: 2px solid #393c3f;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 2.5rem 0;

  ${media.atTablet`
    max-width: none;
  `}
`

const TopFiveContainer = styled.div`
  margin-bottom: 4rem;
`

export { Dashboard }
