import { usePartnerByUser } from 'data/hooks/useInsights'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from 'ui/components'
import { Dashboard } from 'ui/components/Insights'
import { PartnerProvider } from 'ui/components/PartnerContext'

const Insights = () => {
  const { t } = useTranslation()
  const { data } = usePartnerByUser()
  const partnerName = data?.partnerByUser?.name || ''

  return (
    <Page title={t('insights.title', { partner: partnerName })} testId="insights-page">
      <PartnerProvider>
        <Dashboard />
      </PartnerProvider>
    </Page>
  )
}

export { Insights }
