import { gql } from '@apollo/client'
import { REPORT_FIELDS, REQUIRED_REPORT_FIELDS } from '../fragments/report'

const GET_REPORTS = gql`
  ${REQUIRED_REPORT_FIELDS}
  query Reports {
    reports {
      ...RequiredReportFields
    }
  }
`

const GET_REPORTS_BY_DATE = gql`
  ${REPORT_FIELDS}
  query ReportsByDate($date: Date!, $reportType: ReportTypes!) {
    reportsByDate(date: $date, reportType: $reportType) {
      ...ReportFields
    }
  }
`

export { GET_REPORTS, GET_REPORTS_BY_DATE }
