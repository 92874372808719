import { gql } from '@apollo/client'
import { FILE_WITH_FILE_RUNS } from '../fragments'

const GET_FILES = gql`
  ${FILE_WITH_FILE_RUNS}
  query Files {
    files {
      ...FileWithFileRuns
    }
  }
`

export { GET_FILES }
