import { gql } from '@apollo/client'

const GET_TOTALS = gql`
  query Totals($partnerId: String) {
    totals(partnerId: $partnerId) {
      name
      date
      count
    }
  }
`

const GET_CLASS_TYPES = gql`
  query ClassTypes($partnerId: String) {
    classTypes(partnerId: $partnerId) {
      name
      tag
      date
      count
    }
  }
`

const GET_CLASSES_COMPLETED = gql`
  query ClassesCompleted(
    $startDate: Date!
    $endDate: Date!
    $period: String
    $partnerId: String
  ) {
    classesCompleted(
      startDate: $startDate
      endDate: $endDate
      period: $period
      partnerId: $partnerId
    ) {
      name
      date
      count
    }
  }
`

const GET_WORKOUT_MINUTES = gql`
  query WorkoutMinutes(
    $startDate: Date!
    $endDate: Date!
    $period: String
    $partnerId: String
  ) {
    workoutMinutes(
      startDate: $startDate
      endDate: $endDate
      period: $period
      partnerId: $partnerId
    ) {
      name
      date
      count
    }
  }
`

const GET_MINUTES_PER_ENGAGED = gql`
  query MinutesPerEngaged(
    $startDate: Date!
    $endDate: Date!
    $period: String
    $partnerId: String
  ) {
    minutesPerEngaged(
      startDate: $startDate
      endDate: $endDate
      period: $period
      partnerId: $partnerId
    ) {
      name
      date
      count
    }
  }
`

const GET_PARTNER_BY_USER = gql`
  query PartnerByUser {
    partnerByUser {
      name
      hashId
      slug
    }
  }
`

const GET_ENROLLED = gql`
  query Enrolled(
    $startDate: Date!
    $endDate: Date!
    $period: String
    $partnerId: String
  ) {
    enrolled(
      startDate: $startDate
      endDate: $endDate
      period: $period
      partnerId: $partnerId
    ) {
      name
      date
      count
    }
  }
`

const GET_ENGAGED = gql`
  query Engaged($startDate: Date!, $endDate: Date!, $period: String, $partnerId: String) {
    engaged(
      startDate: $startDate
      endDate: $endDate
      period: $period
      partnerId: $partnerId
    ) {
      name
      date
      count
    }
  }
`

export {
  GET_TOTALS,
  GET_CLASS_TYPES,
  GET_PARTNER_BY_USER,
  GET_CLASSES_COMPLETED,
  GET_MINUTES_PER_ENGAGED,
  GET_WORKOUT_MINUTES,
  GET_ENROLLED,
  GET_ENGAGED,
}
