import { gql } from '@apollo/client'

const CREATE_PARTNER = gql`
  mutation PartnerCreate($input: PartnerInput!) {
    partnerCreate(partnerInput: $input) {
      requestOk
      partner {
        id
        name
      }
    }
  }
`

const UPDATE_PARTNER = gql`
  mutation PartnerUpdate($partnerId: ID!, $input: PartnerInput!) {
    partnerUpdate(partnerId: $partnerId, partnerInput: $input) {
      requestOk
    }
  }
`

export { CREATE_PARTNER, UPDATE_PARTNER }
