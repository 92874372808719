import { Eyebrow, Body, Support, grey } from '@pelotoncycle/design-system'
import { FileStatus } from 'data/types/graphql-global-types'
import React from 'react'
import styled from 'styled-components'
import { Box } from './Box'
import { Flex } from './Flex'
import { StatusIcon } from './StatusIcon'

interface TTileProps {
  subject: string
  title: string
  text: string
  subtext?: string
  status?: FileStatus
}

type TChildrenProp = {
  children: React.ReactNode
}

const TileTitle = ({ children }: TChildrenProp) => (
  <Eyebrow size="small" marginBottom="24px" display="block" textColor={grey[90]}>
    {children}
  </Eyebrow>
)

const Tile = ({ subject, title, text, subtext, status }: TTileProps) => {
  let titleDisplay = <TileTitle>{title}</TileTitle>

  if (status) {
    titleDisplay = (
      <Flex height="40px">
        <Box marginRight="8px">{title && <TileTitle>{title}</TileTitle>}</Box>
        <StatusIcon status={status} />
      </Flex>
    )
  }

  return (
    <StyledTile data-testid={`${subject}-tile`}>
      {title && titleDisplay}
      {text && (
        <Body size="large" textColor={grey[90]} marginBottom="4px">
          {text}
        </Body>
      )}
      {subtext && <Support as="p">{subtext}</Support>}
    </StyledTile>
  )
}

const StyledTile = styled.section`
  padding: 24px;
  background-color: white;
`
export { Tile }
