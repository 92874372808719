import { Factory } from 'fishery'
import { FileRunFields } from '../fragments/types/FileRunFields'
import { FileWithFileRuns } from '../fragments/types/FileWithFileRuns'
import { FileWithFileRunsAndErrors } from '../fragments/types/FileWithFileRunsAndErrors'
import { FileStatus } from '../types/graphql-global-types'
import {
  fileRunFactory,
  failedFileRunFactory,
  runningFileRunFactory,
  succeededWithErrorsFileRunFactory,
} from './fileRun'

const fileFactory = Factory.define<FileWithFileRuns>(({ sequence, associations }) => ({
  __typename: 'File',
  id: `${sequence}`,
  name: 'fake-upload.csv',
  dataRows: 0,
  status: FileStatus.SUCCEEDED,
  errors: {},
  fileRuns: associations.fileRuns || fileRunFactory.buildList(1),
  rolledBackOn: null,
}))

const runningFileFactory = fileFactory.params({
  name: 'processing.csv',
  status: FileStatus.RUNNING,
  fileRuns: runningFileRunFactory.buildList(1),
})

const failedFileFactory = fileFactory.params({
  name: 'failed.csv',
  status: FileStatus.FAILED,
  fileRuns: failedFileRunFactory.buildList(1),
})

const succeededWithErrorsFileFactory = fileFactory.params({
  name: 'has-errors.csv',
  status: FileStatus.SUCCEEDED_WITH_ERRORS,
  fileRuns: succeededWithErrorsFileRunFactory.buildList(1),
})

const rolledBackFileFactory = fileFactory.params({
  name: 'rolled-back.csv',
  status: FileStatus.ROLLED_BACK,
  rolledBackOn: '2022-01-31',
  fileRuns: succeededWithErrorsFileRunFactory.buildList(1),
})

class PollableFile {
  readonly file: FileWithFileRuns

  readonly fileRun: FileRunFields

  constructor(file: FileWithFileRunsAndErrors) {
    const fileRun = file?.fileRuns?.[0]

    this.file = file
    this.fileRun = fileRun as FileRunFields
  }

  incrementValues() {
    this.fileRun.eligibilityAdded += 1
    this.fileRun.eligibilityWillBeRemoved += 1
    const total = this.fileRun.eligibilityAdded + this.fileRun.eligibilityWillBeRemoved
    this.file.dataRows = total
    this.fileRun.eligibilityTotalAdded = total
    this.fileRun.totalRowsValid = total

    return { ...this.file }
  }

  get current() {
    return {
      ...this.file,
      fileRuns: [
        {
          ...this.fileRun,
        },
      ],
    }
  }

  set status(status: FileStatus) {
    this.file.status = status
  }
}

export {
  fileFactory,
  failedFileFactory,
  runningFileFactory,
  succeededWithErrorsFileFactory,
  rolledBackFileFactory,
  PollableFile,
}
