import { Body } from '@pelotoncycle/design-system'
import React from 'react'
import { Box } from './Box'

type TEmptyStateProps = {
  message?: string
}

const EmptyState = ({ message = '' }: TEmptyStateProps) => (
  <Box padding="48px 0" textAlign="center" data-testid="empty-state">
    <Body size="small">{message}</Body>
  </Box>
)

export { EmptyState }
