import { gql } from '@apollo/client'

const CREATE_PROGRAM = gql`
  mutation ProgramCreate($partnerId: ID!, $input: ProgramInput!) {
    programCreate(partnerId: $partnerId, programInput: $input) {
      requestOk
      program {
        id
      }
    }
  }
`

const UPDATE_PROGRAM = gql`
  mutation ProgramUpdate($programId: ID!, $input: ProgramInput!) {
    programUpdate(programId: $programId, programInput: $input) {
      requestOk
    }
  }
`

export { CREATE_PROGRAM, UPDATE_PROGRAM }
