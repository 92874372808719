import { Button, white } from '@pelotoncycle/design-system'
import { useEnrollmentReports, useEnrollmentReport } from 'data/hooks'
import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDownloadInBrowser } from 'ui/hooks'
import { Flex, Box, RenderResponse, EmptyState } from '../../..'
import { useAlerts, AlertVariants } from '../../../Alerts'

type TReportRowProps = {
  id: string
  name: string
  handleClick: () => void
  isDisabled: boolean
  isLoading: boolean
}

const FILE_EXTENSION = '.csv'

const Row = ({ id, name, handleClick, isDisabled, isLoading }: TReportRowProps) => {
  const { t } = useTranslation()

  return (
    <Flex
      backgroundColor={white}
      alignItems="center"
      justifyContent="space-between"
      marginBottom="16px"
      key={name}
      padding="0 16px"
      height="64px"
    >
      <H2>{name}</H2>

      <Box width="128px">
        <Button
          data-testid={`download-btn-${id}`}
          aria-label={t('enrollment_reports_page.download')}
          text={t('enrollment_reports_page.download')}
          onClick={handleClick}
          isDisabled={isDisabled}
          isLoading={isLoading}
          size="small"
          width="adaptive"
        />
      </Box>
    </Flex>
  )
}

const EnrollmentReports = () => {
  const { t } = useTranslation()
  const { addAlert } = useAlerts()
  const { loading, error, data } = useEnrollmentReports()
  const {
    getReport,
    reset,
    loading: reportLoading,
    error: reportError,
    data: report,
  } = useEnrollmentReport()

  const reports = data?.reports || []
  const hasReports = reports && !!reports.length
  // report comes from context, not directly from apollo cache
  const reportURL = report?.url
  const reportName = report?.name
  const reportFilename = reportName || 'report'
  const filenameWithExt = `${reportFilename}${FILE_EXTENSION}`

  useDownloadInBrowser({ url: reportURL, error: reportError, filename: filenameWithExt })

  const addErrorAlert = useCallback(() => {
    addAlert({
      variant: AlertVariants.error,
      message: t('enrollment_reports_page.download_error', { name: filenameWithExt }),
      autoClose: 8000,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportError])

  const handleDownloadClick = (hashId: string, name: string) => {
    getReport(hashId, name)
      .catch(addErrorAlert)
      .finally(() => {
        setTimeout(reset, 0)
      })
  }

  useEffect(() => {
    if (reportError) {
      addErrorAlert()
    }
  }, [reportError, addErrorAlert])

  return (
    <RenderResponse {...{ loading, error }}>
      {hasReports ? (
        <>
          {reports.map(({ hashId, filename }) => {
            return (
              <Row
                key={hashId}
                id={hashId}
                name={`${filename}${FILE_EXTENSION}`}
                handleClick={() => handleDownloadClick(hashId, filename)}
                isDisabled={reportLoading}
                isLoading={reportName === filename}
              />
            )
          })}
        </>
      ) : (
        <EmptyState message={t('enrollment_reports_page.no_reports_prompt')} />
      )}
    </RenderResponse>
  )
}

const H2 = styled.h2`
  margin-right: 80px;
`

export { EnrollmentReports }
