import { Body } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { sectionSpacing, LI, UL } from '.'
import { InfoSection } from '..'

const EffectiveTerminationDates = () => {
  const { t } = useTranslation()

  return (
    <InfoSection
      heading={t('effective_and_termination_dates')}
      marginBottom={sectionSpacing}
    >
      <Body size="small" marginBottom="24px">
        {t('support.effective_termination_dates.eligibility_defined')}
      </Body>
      <UL>
        <LI>
          {t('support.effective_termination_dates.effective_date')}
          <UL>
            <LI $indent>
              {t('support.effective_termination_dates.partner_go_live')}
              <UL>
                <LI $indent>
                  {t('support.effective_termination_dates.go_live_example')}
                </LI>
              </UL>
            </LI>
          </UL>
          <UL>
            <LI $indent>
              {t('support.effective_termination_dates.future_date')}
              <UL>
                <LI $indent>
                  {t('support.effective_termination_dates.future_date_example')}
                </LI>
              </UL>
            </LI>
          </UL>
          <UL>
            <LI $indent $addSpace>
              <Body size="small">
                <Trans
                  i18nKey="support.effective_termination_dates.default_to_current"
                  components={{ i: <em />, bold: <strong /> }}
                />
              </Body>
            </LI>
          </UL>
        </LI>
      </UL>

      <UL>
        <LI>
          {t('support.effective_termination_dates.termination_date')}
          <UL>
            <LI $indent>
              <Body size="small">
                <strong>{t('support.effective_termination_dates.leave_empty')}</strong>
              </Body>
              <UL>
                <LI $indent>
                  {t('support.effective_termination_dates.current_employee_example')}
                </LI>
                <LI $indent>{t('support.effective_termination_dates.resign_example')}</LI>
              </UL>
            </LI>
            <LI $indent>
              <Body size="small">
                <Trans
                  i18nKey="support.effective_termination_dates.no_longer_eligible_note"
                  components={{ i: <em />, bold: <strong /> }}
                />
              </Body>
            </LI>
          </UL>
        </LI>
      </UL>
    </InfoSection>
  )
}

export { EffectiveTerminationDates }
