import { useChallenges, useChallenge } from 'data/hooks'
import { format } from 'date-fns'
import { usePartnerContext } from 'ui/components/PartnerContext'

// maps a selector label (e.g. "Sep 1-30, 2022") to a challenge id (e.g. "91a252d038624ea9821f33a8543a9cb7")
type ChallengeIdMap = {
  [key: string]: string
}

const useChallengeMap = () => {
  const { partnerId } = usePartnerContext()
  const { loading, error, challenges } = useChallenges({ partnerId })

  let challengeMap

  if (loading || error || !challenges.length) {
    challengeMap = {}
  }

  challengeMap = challenges
    .sort((a, b) => {
      if (a.startTime > b.startTime) {
        return 1
      }
      if (a.startTime < b.startTime) {
        return -1
      }

      return 0
    })
    .reduce((memo: ChallengeIdMap, val) => {
      const start = format(new Date(`${val.startTime as string}T00:00:00`), 'MMM d')
      const end = format(new Date(`${val.endTime as string}T00:00:00`), 'MMM d, yyyy')

      let label = `${start} - ${end}`
      let i = 0
      while (memo.hasOwnProperty(label)) {
        i += 1
        label = `${start} - ${end} (${i})`
      }

      return { ...memo, [label]: val.id }
    }, {})

  return { loading, error, challengeMap }
}

const useChallengeKeys = () => {
  const { challengeMap } = useChallengeMap()

  return Object.keys(challengeMap)
}

const useLastChallengeKey = () => {
  const items = useChallengeKeys()

  return items[items.length - 1]
}

const useKeyForChallengeId = (challengeId: string) => {
  const { challengeMap } = useChallengeMap()
  const challengeKeys = useChallengeKeys()
  const key = challengeKeys.find(k => challengeMap[k] === challengeId)

  return key
}

const useChallengeIdForKey = (key: string) => {
  const { challengeMap } = useChallengeMap()
  const challengeId = challengeMap[key]

  return challengeId
}

const useChallengeForKey = (key: string) => {
  const challengeId = useChallengeIdForKey(key)
  const challenge = useChallenge({ challengeId })

  return challenge
}

export {
  useChallengeMap,
  useChallengeKeys,
  useLastChallengeKey,
  useChallengeIdForKey,
  useChallengeForKey,
  useKeyForChallengeId,
}
