import { useEffect } from 'react'

type TDownloadProps = {
  url: string | null | undefined
  error: string | null | undefined
  filename?: string
}
const useDownloadInBrowser = ({ url, error, filename }: TDownloadProps): void => {
  useEffect(() => {
    if (url && !error) {
      const anchor: HTMLAnchorElement = document.createElement('a')
      anchor.style.display = 'none'
      anchor.href = url
      if (filename) anchor.download = filename
      anchor.click()
    }
  }, [url, error, filename])
}

export { useDownloadInBrowser }
