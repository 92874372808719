import { BreakpointWidths } from '@pelotoncycle/design-system'
import { css } from 'styled-components'

const { tablet, desktop } = BreakpointWidths

const atTablet = (args: TemplateStringsArray) => css`
  @media (min-width: ${tablet}px) {
    ${css(args)}
  }
`

const atDesktop = (args: TemplateStringsArray) => css`
  @media (min-width: ${desktop}px) {
    ${css(args)}
  }
`

const media = { atTablet, atDesktop }

export { media }
