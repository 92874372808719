import { black } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const Calendar = ({ width = '20px', height = '20px', fill = black }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill={fill}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 0.5C6.5 0.223858 6.27614 0 6 0C5.72386 0 5.5 0.223858 5.5 0.5V1.5H2C0.89543 1.5 0 2.39543 0 3.5V7V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18V7V3.5C20 2.39543 19.1046 1.5 18 1.5H14.5V0.5C14.5 0.223858 14.2761 0 14 0C13.7239 0 13.5 0.223858 13.5 0.5V1.5H6.5V0.5ZM5.5 2.5V3.5C5.5 3.77614 5.72386 4 6 4C6.27614 4 6.5 3.77614 6.5 3.5V2.5H13.5V3.5C13.5 3.77614 13.7239 4 14 4C14.2761 4 14.5 3.77614 14.5 3.5V2.5H18C18.5523 2.5 19 2.94772 19 3.5V6.5H1V3.5C1 2.94772 1.44772 2.5 2 2.5H5.5ZM1 7.5V18C1 18.5523 1.44772 19 2 19H18C18.5523 19 19 18.5523 19 18V7.5H1Z"
      fill={fill}
    />
  </svg>
)

export { Calendar }
