import { Support } from '@pelotoncycle/design-system'
import React from 'react'
import styled from 'styled-components'
import { media } from 'ui/responsive'

type RowProps = {
  color: string
  label: string
  percentage?: number
  className?: string
}

type ColorProps = {
  color: string
}

const Item: React.FC<React.PropsWithChildren<RowProps>> = ({
  color,
  label,
  percentage,
  className,
}) => {
  return (
    <Container className={className}>
      <Color color={color} />
      <Label size="large">{`${label}${percentage ? ':' : ''}`}</Label>
      {percentage && (
        <>
          &nbsp;<Percentage size="large">{percentage}%</Percentage>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  padding: 0 1rem;
  align-items: center;

  ${media.atDesktop`
    padding: 0; 

    &:last-child {
      margin-bottom: 0;
    }
  `}
`

const Color = styled.div<ColorProps>`
  display: inline-block;
  margin-right: 0.75rem;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background: ${props => props.color};
  border-radius: 100%;
`

const Label = styled(Support)`
  text-transform: capitalize;
  line-height: 20px;
`

const Percentage = styled(Support)`
  font-weight: bold;
  line-height: 20px;
`

export { Item }
