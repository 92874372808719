import { useAuth0 } from '@auth0/auth0-react'
import '@reach/menu-button/styles.css'
import { white, grey, brand, secondaryFamily } from '@pelotoncycle/design-system'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
  MenuLink,
} from '@reach/menu-button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Avatar } from './svg'

const MENU_POINTER_OFFSET = 18
const MENU_POINTER_HEIGHT = 10

const UserMenu = () => {
  const { t } = useTranslation()
  const { logout } = useAuth0()

  return (
    <Menu>
      <StyledMenuButton aria-label="User Menu">
        <Avatar />
      </StyledMenuButton>
      <StyledMenuList
        position={(btn: DOMRect, popover: DOMRect) => {
          const leftVal = btn.x - popover.width + btn.width / 2 + MENU_POINTER_OFFSET
          const topVal = btn.bottom + MENU_POINTER_HEIGHT

          return {
            left: `${leftVal}px`,
            top: `${topVal}px`,
          }
        }}
      >
        <StyledMenuItems>
          <MenuLink to="/support" as={Link}>
            {t('user_menu.support')}
          </MenuLink>
          <MenuItem onSelect={() => logout({ returnTo: window.location.origin })}>
            {t('user_menu.sign_out')}
          </MenuItem>
        </StyledMenuItems>
      </StyledMenuList>
    </Menu>
  )
}

const StyledMenuItems = styled(MenuItems)`
  border: none;
  border-radius: inhert;

  &&:focus {
    box-shadow: none;
  }

  > :not(:last-child) {
    margin-bottom: 24px;
  }
`

const StyledMenuList = styled(MenuPopover)`
  background: ${white};
  border-radius: 4px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  border: none;
  white-space: nowrap;
  padding: 40px 78px 40px 40px;

  [data-reach-menu-item] {
    display: block;
    padding: 0;
    color: ${brand.darkest};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.025em;
    text-align: left;
    font-family: ${secondaryFamily};
    font-weight: 700;
    background: ${white};

    &[data-selected] {
      background: ${white};
      color: ${grey[70]};
      border-radius: 4px;
    }
  }

  :before {
    content: '';
    position: absolute;
    right: 8px;
    top: -${MENU_POINTER_HEIGHT}px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${white} transparent;
  }
`

const StyledMenuButton = styled(MenuButton)`
  background: none;
  border: none;
  cursor: pointer;
`

export { UserMenu }
