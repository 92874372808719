import { white, grey, data as dataColor } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowWidth } from 'ui/hooks'
import { VictoryBar, VictoryChart, VictoryAxis, VictoryVoronoiContainer } from 'victory'
import { Tooltip } from './Tooltip'
import { ChartProps, Datum } from './types'
import { defaultTickLabel, rangeTickFormat } from './utils'

type BarProps = {
  tooltipLabel: string
  barWidth?: number
  dark?: boolean
} & ChartProps

type BarDatum = {
  l?: string
} & Datum

type BarLabel = {
  datum: BarDatum
}

const Bar: React.FC<React.PropsWithChildren<BarProps>> = ({
  title,
  tooltipLabel,
  barWidth = 24,
  dark = false,
  data,
}) => {
  const { t } = useTranslation()
  const { isAboveMobile } = useWindowWidth()
  const tickValues = data.map(({ label: l }) => l)
  const barData = data.map(({ x, y, label: l }) => ({ x, y, l }))
  const showMobile = !isAboveMobile

  const Container = (
    <VictoryVoronoiContainer
      title={title}
      labels={(d: BarLabel) => {
        let text = `${d.datum.y?.toLocaleString()} ${t(tooltipLabel, {
          count: d.datum.y,
        })}`
        if (showMobile && d.datum.l) {
          text += `\nfor\n${d.datum.l}`
        }

        return text
      }}
      voronoiDimension="x"
      labelComponent={<Tooltip />}
    />
  )

  return (
    <VictoryChart
      width={showMobile ? 400 : 1000}
      height={400}
      domainPadding={40}
      containerComponent={Container}
    >
      <VictoryAxis
        dependentAxis
        tickCount={4}
        tickFormat={rangeTickFormat}
        style={{
          axis: {
            stroke: 'none',
          },
          tickLabels: {
            ...defaultTickLabel,
            fill: dark ? white : defaultTickLabel.fill,
          },
          grid: {
            stroke: dark ? grey[70] : grey[40],
          },
        }}
      />
      <VictoryAxis
        tickValues={tickValues}
        tickCount={showMobile ? 4 : data.length}
        style={{
          axis: { stroke: dark ? grey[70] : grey[40] },
          tickLabels: {
            ...defaultTickLabel,
            fill: dark ? white : defaultTickLabel.fill,
          },
        }}
        invertAxis
      />
      <VictoryBar
        data={barData}
        style={{ data: { fill: dataColor.teal } }}
        barWidth={barWidth}
        cornerRadius={3}
      />
    </VictoryChart>
  )
}

export { Bar }
