import { grey } from '@pelotoncycle/design-system'
import React from 'react'
import styled from 'styled-components'
import { media } from '../responsive'
import { NavigationLink } from './Links'

type TPath = {
  path: string
  displayName?: string
}

type TSubNavProps = {
  paths: TPath[]
  basePath?: string
  testId?: string
}

const SubNav = ({ testId = 'subnav', paths, basePath }: TSubNavProps) => {
  return (
    <StyledSubNav data-testid={testId}>
      {paths.map(({ path, displayName }) => {
        const toPath = basePath ? `${basePath}${path}` : `${path}`
        const displayValue = displayName || path

        return (
          <NavigationLink key={path} activeColor={grey[90]} color={grey[90]} to={toPath}>
            {displayValue}
          </NavigationLink>
        )
      })}
    </StyledSubNav>
  )
}

const StyledSubNav = styled.div`
  margin-bottom: 48px;
  text-align: center;

  a {
    margin-bottom: 36px;
  }

  ${media.atTablet`
    text-align: left;

    a {
      margin-bottom: 0;
    }
  `}
`

export { SubNav }
