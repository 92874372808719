import { Eyebrow, Display, Headline } from '@pelotoncycle/design-system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatDate } from 'utils'
import { Box, Card, Flex, Page, Select } from '../..'
import { media } from '../../../responsive'

const Dashboard = () => {
  // TODO-DATA: replace with date from BE data
  const mockDate = '12/07/2020'
  const [filterDate, setFilterDate] = useState(mockDate)
  const { t } = useTranslation()
  const formattedDate = formatDate(new Date(filterDate) || mockDate)
  const options = ['All time', 'Last Week', 'Last Month']

  return (
    <Page testId="dashboard-page">
      <Flex justifyContent="space-between" width="inherit" marginBottom="24px">
        <Box>
          <Headline as="h1" size="small" marginBottom="8px">
            {t('dashboard')}
          </Headline>
          <SubHeader>{t('as_of_x_date', { date: formattedDate })}</SubHeader>
        </Box>
        <Select
          defaultValue={options[0]}
          options={options}
          width="216px"
          handleChange={() => setFilterDate(mockDate)}
        />
      </Flex>
      <CardContainer>
        <DataCard>
          <Eyebrow as="p" marginBottom="16px">
            {t('total_eligibles')}
          </Eyebrow>
          <Display size="large">68,321</Display>
        </DataCard>
        <DataCard>
          <Eyebrow as="p" marginBottom="16px">
            {t('active_participants')}
          </Eyebrow>
          <Display size="large">54,341</Display>
        </DataCard>
      </CardContainer>
    </Page>
  )
}

const DataCard = styled(Card)`
  width: 100%;

  ${media.atTablet`
    width: 46%;
  `}
`

const CardContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`

const SubHeader = styled.p`
  font-size: 12px;
`

export { Dashboard }
