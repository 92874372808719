import { Body } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageLayout } from '.'

const Troubleshooting = () => {
  const { t } = useTranslation()

  return (
    <PageLayout headline={t('troubleshooting')} testId="support-troubleshooting">
      <Body size="small">{t('troubleshooting_contact_us')}</Body>
    </PageLayout>
  )
}

export { Troubleshooting }
