import { Body } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { sectionSpacing } from '.'
import { InfoSection } from '..'

const Overview = () => {
  const { t } = useTranslation()

  return (
    <InfoSection heading={t('overview')} marginBottom={sectionSpacing}>
      <Body size="small" marginBottom="24px">
        {t('support.overview.0')}
      </Body>
      <Body size="small" marginBottom="24px">
        {t('support.overview.1')}
      </Body>
      <Body size="small">{t('support.overview.2')}</Body>
    </InfoSection>
  )
}

export { Overview }
