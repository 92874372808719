import { ProgramBenefitCountry } from 'data/types/graphql-global-types'
import { Factory } from 'fishery'
import { Partner_partner_program_programBenefits as TProgramBenefit } from '../queries/types/Partner'
import { benefitFactory } from './benefit'
import { programFactory } from './program'

const programBenefitFactory = Factory.define<TProgramBenefit>(
  ({ sequence, associations }) => ({
    __typename: 'ProgramBenefit',
    id: `${sequence}`,
    country: ProgramBenefitCountry.US,
    subscriptionType: null,
    userPaysRaw: 100,
    partnerPaysRaw: 1000,
    discountRaw: null,
    currency: null,
    eligibleForActivePay: false,
    benefit: associations.benefit || benefitFactory.build(),
    program: associations.program || programFactory.build(),
  }),
)

export { programBenefitFactory }
