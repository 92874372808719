function get<T, P1 extends keyof NonNullable<T>>(obj: T, prop1: P1): NonNullable<T>[P1]

function get<
  T,
  P1 extends keyof NonNullable<T>,
  P2 extends keyof NonNullable<NonNullable<T>[P1]>,
>(obj: T, prop1: P1, prop2: P2): NonNullable<NonNullable<T>[P1]>[P2]

function get<T, P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2]>(
  obj: T,
  prop1: P1,
  prop2: P2,
  prop3: P3,
): T[P1][P2][P3]

function get<
  T,
  P1 extends keyof T,
  P2 extends keyof T[P1],
  P3 extends keyof T[P1][P2],
  P4 extends keyof T[P1][P2][P3],
>(obj: T, prop1: P1, prop2: P2, prop3: P3, prop4: P4): T[P1][P2][P3][P4]

function get<T>(obj: T, ...fields: []) {
  return fields.reduce((result, field) => result && result[field], obj)
}

export { get }
