import { useAuth0 } from '@auth0/auth0-react'
import { useDecision } from '@optimizely/react-sdk'
import { PollForFileProvider } from 'data/hooks'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AlertDisplay,
  AlertProvider,
  LoadingScreen,
  AuthError,
  Screen,
  DowntimeScreen,
  OptimizelyProvider,
} from 'ui/components'
import { Header } from 'ui/components/@web/'
import { useInputChange } from 'ui/hooks'
import { SHOW_DOWNTIME } from 'utils'
import { GlobalStyle } from './GlobalStyle'
import 'i18n/config'
import { Routes } from './Routes'

type ContentProps = {
  isAuthenticated: boolean
}

const Content = ({ isAuthenticated }: ContentProps) => {
  const [decision] = useDecision(SHOW_DOWNTIME)
  let content = null

  if (decision.enabled) {
    content = <DowntimeScreen />
  } else if (isAuthenticated) {
    content = (
      <>
        <Header />
        <AlertDisplay />
        <PollForFileProvider>
          <Routes />
        </PollForFileProvider>
      </>
    )
  }

  return content
}

const App = () => {
  useInputChange()
  const { isAuthenticated, loginWithRedirect, isLoading, error: authError } = useAuth0()

  const history = useHistory()
  const { pathname } = history.location

  // If no credentials, Redirect to Auth0 login screen
  const noCredentials = !isAuthenticated && !authError && !isLoading

  useEffect(() => {
    if (noCredentials) {
      const appStateForPostLoginRedirect = { appState: { returnTo: pathname } }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loginWithRedirect(appStateForPostLoginRedirect)
    }
  }, [noCredentials, loginWithRedirect, pathname])

  if (isLoading) return <LoadingScreen />
  if (authError) return <AuthError error={authError} />

  return (
    <OptimizelyProvider>
      <AlertProvider>
        <GlobalStyle />
        <Screen>
          <Content isAuthenticated={!!isAuthenticated} />
        </Screen>
      </AlertProvider>
    </OptimizelyProvider>
  )
}

export { App }
