import { useAuth0 } from '@auth0/auth0-react'

const { REACT_APP_CF_CLIENT_ACCESS_ID = '', REACT_APP_CF_CLIENT_ACCESS_SECRET = '' } =
  process.env

const useRestHeaders = () => {
  const { getAccessTokenSilently } = useAuth0()

  const getHeaders = async () => {
    const token = await getAccessTokenSilently()

    return {
      'CF-Access-Client-Id': REACT_APP_CF_CLIENT_ACCESS_ID,
      'CF-Access-Client-Secret': REACT_APP_CF_CLIENT_ACCESS_SECRET,
      Authorization: `Bearer ${token}`,
    }
  }

  return { getHeaders }
}

export { useRestHeaders }
