import { white } from '@pelotoncycle/design-system'
import React from 'react'
import styled from 'styled-components'
import { Box } from '.'

export const Tooltip = ({ text }: { text: string }) => (
  <StyledTooltip id="tooltip" role="tooltip">
    <TooltipText>{text}</TooltipText>
  </StyledTooltip>
)

// TODO-STYLE - sort out z-index heirarchy
const StyledTooltip = styled(Box)`
  position: absolute;
  padding: 8px 16px;
  text-align: center;
  background-color: ${white};
  border-radius: 4px;
  top: 100%;
  z-index: 2;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
`

const TooltipText = styled.span`
  font-size: 12px;
  font-weight: 300;
`
