import { format, parse, isValid } from 'date-fns'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset'

const DATE_DISPLAY_FORMAT = 'MM/dd/yyyy'
const DATE_API_FORMAT = 'yyyy-MM-dd'
const TIME_FORMAT = 'hh:mm aa'

const TIMEZONES = [
  'UTC',
  'US/Hawaii',
  'US/Alaska',
  'US/Pacific',
  'US/Mountain',
  'US/Central',
  'US/Eastern',
  'Europe/London',
  'Europe/Berlin',
  'Europe/Kiev',
  'Europe/Moscow',
]

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

// take a Date, return string as MM/DD/YYYY, e.g. 01/20/1984
const formatDate = (date: Date) => format(date, DATE_DISPLAY_FORMAT)

// take a Date, return string as MM/DD/YYYY, e.g. 01/20/1984
const formatTime = (date: Date) => format(date, TIME_FORMAT)

// take a Date and a timezone and return an offset value that is valid in a date string passed to the new Date constructor, .e.g "-0700"
const formatTimezoneOffset = (date: Date, timezone: string) => {
  const offset = getTimezoneOffset(timezone, date)

  // default to UTC offset
  let offsetString = '-0000'

  if (offset !== 0 && !Number.isNaN(offset)) {
    const isBehindGMT = offset < 0
    const distanceFromGMT = Math.abs(offset)
    const offsetInHours = distanceFromGMT / 1000 / 60 / 60
    // check NaN
    const offsetValue = `${offsetInHours}`.padStart(2, '0')
    const offsetDirection = isBehindGMT ? '-' : '+'

    offsetString = `${offsetDirection}${offsetValue}00`
  }

  return offsetString
}

// take a date in system time and convert to UTC
// e.g. datestring = "2000-01-01"
//      d = new Date(datestring) // converts to local time, e.g. ('1999-12-31 18:00:00')
//      formatUtcDate(d) // "2000-01-01"
const formatUtcDate = (date: Date, fmt: string | undefined = DATE_API_FORMAT) =>
  formatInTimeZone(date, 'UTC', fmt)

// Takes a UTC date string and returns a date that preserves the year/month/date values (Date objects will always be in user's locale/offset)
const utcDate = (date: string | null): Date => {
  if (date && isValid(new Date(date))) {
    const d = new Date(date) // need a date, but always in local time

    return new Date(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate(),
      d.getUTCHours(),
      d.getUTCMinutes(),
    )
  }

  return new Date('Invalid Date')
}

// take a string 2022-07-01, return month year, e.g. 'July 2022'
const formatMonthYear = (date: string) => {
  // avoiding Date constructor to avoid tz issues
  // converting beg. of month to date shows negative GMTs as prev month
  const regex = /^\d{4}-\d{2}-\d{2}$/
  if (!regex.exec(date)) {
    throw new Error('date passed to formatMonthYear must be in yyyy-mm-dd format')
  }

  const [year, month] = date.split('-')
  const monthIndex = Number(month) - 1

  return `${MONTHS[monthIndex]} ${year}`
}

// take a Date, return string as yyyy-MM-dd, e.g. 2001-04-22
const formatDatepickerDate = (date: Date) => format(date, DATE_API_FORMAT)

// take a string in format yyyy-MM-dd and return a Date
const parseTerminationDate = (formattedDateString: string) =>
  parse(formattedDateString, DATE_API_FORMAT, new Date())

export {
  utcDate,
  formatDate,
  formatUtcDate,
  formatTime,
  formatTimezoneOffset,
  formatDatepickerDate,
  parseTerminationDate,
  formatMonthYear,
  DATE_DISPLAY_FORMAT,
  DATE_API_FORMAT,
  MONTHS,
  TIMEZONES,
  TIME_FORMAT,
}
