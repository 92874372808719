import {
  grey,
  black,
  white,
  Icon,
  Headline,
  Body,
  Support,
} from '@pelotoncycle/design-system'
import React, { ReactNode } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled, { ThemeProvider } from 'styled-components'
import { Loading } from 'ui/components/Loading'
import { media } from 'ui/responsive'
import bg from './Challenges/static/moduleBg.png'
import { DateRangeSelector, DateRange } from './DateRangeSelector'

type TextProps = {
  [key: string]: string | number
}

type Props = {
  children?: ReactNode
  cta?: ReactNode
  legend?: ReactNode
  padding?: string
  label?: string
  labelProps?: TextProps
  support?: string
  supportProps?: TextProps
  loading?: boolean
  empty?: boolean
  emptyHeight?: string
  emptyText?: string
  dateRangeSelectId?: string
  dateRangeSelect?: ((dateRange: DateRange) => void) | null
  dark?: boolean
}

type ContainerProps = {
  padding: string
} & ThemeProps

type EmptyProps = {
  height: string
}

type ThemeProps = {
  theme: {
    bgColor: string
    color: string
  }
}

const lightTheme = {
  bgColor: white,
  color: black,
}

const darkTheme = {
  bgColor: grey[90],
  color: white,
}

const Module: React.FC<Props> = ({
  children,
  cta,
  legend,
  padding = '30px 40px 40px',
  label,
  labelProps = {},
  support,
  supportProps = {},
  loading = false,
  empty = false,
  emptyHeight = '250px',
  emptyText,
  dateRangeSelectId = '',
  dateRangeSelect = null,
  dark = false,
}) => {
  const { t } = useTranslation()

  let content = children
  if (loading) {
    content = (
      <Empty height={emptyHeight}>
        <Loading />
      </Empty>
    )
  } else if (!children || empty) {
    content = (
      <Empty height={emptyHeight}>
        <EmptyInner>
          <IconStyled height={20} name="variety" primaryColor={grey[70]} />
          <Body>{emptyText || t('insights.empty_module')}</Body>
        </EmptyInner>
      </Empty>
    )
  }

  let header
  if (label || dateRangeSelect) {
    header = (
      <div>
        <Header>
          {label && <Title size="small">{t(label, labelProps)}</Title>}
          {cta && cta}
          {support && (
            <SupportStyled size="large">
              <Trans
                i18nKey={support}
                components={{ bold: <strong /> }}
                values={supportProps}
              />
            </SupportStyled>
          )}
        </Header>
        {dateRangeSelect && (
          <Controls>
            <DateRangeSelector id={dateRangeSelectId} dateRangeSelect={dateRangeSelect} />
            {legend}
          </Controls>
        )}
      </div>
    )
  }

  return (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <Container padding={padding}>
        {dark && <Dots />}
        <Inner>
          {header}
          {content}
        </Inner>
      </Container>
    </ThemeProvider>
  )
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  padding: ${props => props.padding};
  margin-bottom: 1rem;
  background: ${(props: ContainerProps) => props.theme.bgColor};
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.02);

  ${media.atTablet`
    margin-bottom: 1.5rem;
  `}
`

const Dots = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  right: 0;
  background: url(${bg}) no-repeat top right;
  opacity: 0.25;
`

const Inner = styled.div`
  position: relative;
  z-index: 2;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1rem;

  ${media.atDesktop`
    flex-direction: row;
  `}
`

const Controls = styled.div`
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
  align-items: center;

  @media (min-width: 530px) {
    align-items: stretch;
  }

  ${media.atDesktop`
    flex-direction: row;
    justify-content: space-between;
  `}
`

const Title = styled(Headline)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.color};
`

const SupportStyled = styled(Support)`
  line-height: 40px;
  color: ${grey[90]};
`

const IconStyled = styled(Icon)`
  margin-bottom: 0.5rem;
`

const Empty = styled.div<EmptyProps>`
  display: flex;
  height: ${props => props.height};
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${grey[70]};
`

const EmptyInner = styled.div`
  max-width: 590px;
  margin: 0 auto;
`

export { Module }
