import { Body, Label, Support, grey, BreakpointWidths } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useWindowWidth } from 'ui/hooks'
import { InfoSection } from '..'
import { Box } from '../../../..'

const { tablet } = BreakpointWidths

const INTERNAL_ID = 'internal_id'
const FIRST_NAME = 'first_name'
const LAST_NAME = 'last_name'
const EMAIL_ADDRESS = 'email_address'
const EFFECTIVE_DATE = 'effective_date'
const TERMINATION_DATE = 'termination_date'
const REQUIRED = 'required'
const OPTIONAL = 'optional'
const OPTIONAL_DISCLAIMER = 'optional_with_disclaimer'
const STRING = 'string'
const STRING_ALPHA = 'string_alphanumeric'
const STRING_DATE = 'string_date_format'
const getFieldName = (key: string) => `support.data_specification.field_names.${key}`
const getExample = (key: string) => `support.data_specification.examples.${key}`
const getDescription = (key: string) =>
  `support.data_specification.field_descriptions.${key}`

type RowDataProps = {
  fieldName: string
  example: string
  isRequired: string
  description: {
    format: string
    detail: string
  }
}

const rowData: RowDataProps[] = [
  {
    fieldName: getFieldName(INTERNAL_ID),
    example: getExample(INTERNAL_ID),
    isRequired: REQUIRED,
    description: {
      format: getDescription(STRING_ALPHA),
      detail: getDescription(INTERNAL_ID),
    },
  },
  {
    fieldName: getFieldName(FIRST_NAME),
    example: getExample(FIRST_NAME),
    isRequired: REQUIRED,
    description: {
      format: getDescription(STRING),
      detail: getDescription(FIRST_NAME),
    },
  },
  {
    fieldName: getFieldName(LAST_NAME),
    example: getExample(LAST_NAME),
    isRequired: REQUIRED,
    description: {
      format: getDescription(STRING),
      detail: getDescription(LAST_NAME),
    },
  },
  {
    fieldName: getFieldName(EMAIL_ADDRESS),
    example: getExample(EMAIL_ADDRESS),
    isRequired: REQUIRED,
    description: {
      format: getDescription(STRING),
      detail: getDescription(EMAIL_ADDRESS),
    },
  },
  {
    fieldName: getFieldName(EFFECTIVE_DATE),
    example: getExample(EFFECTIVE_DATE),
    isRequired: OPTIONAL,
    description: {
      format: getDescription(STRING_DATE),
      detail: getDescription(EFFECTIVE_DATE),
    },
  },
  {
    fieldName: getFieldName(TERMINATION_DATE),
    example: getExample(TERMINATION_DATE),
    isRequired: OPTIONAL_DISCLAIMER,
    description: {
      format: getDescription(STRING_DATE),
      detail: getDescription(TERMINATION_DATE),
    },
  },
]

const tableHeadings = ['field_name', 'example', 'required_slash_optional', 'description']

type DescProps = {
  value: {
    format: string
    detail: string
  }
}

// TODO - make SingleLine and TwoLines one component
const SingleLine = ({ value }: { value: string }) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowWidth()
  const fontSize = isMobile ? '14px' : '16px'

  return (
    <Body size="small" overrideSize={{ lineHeight: '24px', fontSize }}>
      {t(value)}
    </Body>
  )
}

const TwoLines = ({ value }: DescProps) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowWidth()
  const fontSize = isMobile ? '14px' : '16px'

  return (
    <Body size="small" overrideSize={{ lineHeight: '24px', fontSize }}>
      <span>{t(value.format)}</span>
      <br />
      <span>{t(value.detail)}</span>
    </Body>
  )
}

const DataSpecification = () => {
  const { t } = useTranslation()
  const tableRef = React.useRef<HTMLTableElement>(null)
  const disclaimerWidth = tableRef?.current?.clientWidth

  return (
    <InfoSection heading={t('data_specification')} omitUnderline>
      <DataSpecTable ref={tableRef}>
        <thead>
          <tr>
            {tableHeadings.map(headingKey => (
              <Label key={headingKey} as="th" size="extraLarge" weight="medium">
                {t(headingKey)}
              </Label>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowData.map(row => (
            <tr key={row.fieldName}>
              {Object.entries(row).map(([key, value], i) => {
                const isValueString = typeof value === 'string'
                const headerLabel = tableHeadings[i]

                return (
                  <td key={key} aria-label={t(headerLabel)}>
                    {isValueString ? (
                      <SingleLine value={value} />
                    ) : (
                      // @ts-ignore tried many versions of this TS always yells, need to move on
                      <TwoLines value={value} />
                    )}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </DataSpecTable>
      <Box width={disclaimerWidth ? `${disclaimerWidth}px` : '100%'}>
        <Support size="large" as="p" marginBottom="32px">
          <Italic>{t('support.data_specification.header_conflicts_note')}</Italic>
        </Support>
        <Support size="large" as="p" marginBottom="32px">
          {t('support.data_specification.data_disclaimer_1')}
        </Support>
        <Support size="large" as="p">
          {t('support.data_specification.data_disclaimer_2')}
        </Support>
      </Box>
    </InfoSection>
  )
}

const borderStyle = `1px solid ${grey[40]}`

const DataSpecTable = styled.table`
  border: ${borderStyle};
  margin-top: 16px;
  display: block;
  overflow-x: auto;
  margin-bottom: 32px;

  th,
  td {
    padding: 8px;
    border: ${borderStyle};
    line-height: 24px;
    text-align: left;
  }

  th {
    display: table-cell;
  }

  td {
    max-width: 233px;
  }

  @media (min-width: ${tablet}px) {
    display: table;

    th,
    td {
      padding: 24px 24px 24px 16px;
    }
  }
`

const Italic = styled.i`
  font-style: italic;
`

export { DataSpecification }
