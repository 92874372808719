/* eslint-disable react/jsx-props-no-spreading */
import { Body, grey, white, typography } from '@pelotoncycle/design-system'
import { AddFileFunc } from 'data/hooks'
import React from 'react'
import * as Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Flex } from './Flex'

type SectionProps = {
  opacity: number
}

type AddOrDropProps = {
  onDrop: AddFileFunc
  onDropRejected: (fileRejections: Array<Dropzone.FileRejection>) => void
  disabled?: boolean
}

const StyledSection = styled(Flex)<SectionProps>`
  display: flex;
  height: 115px;
  align-items: center;
  justify-content: center;
  border-radius: 21px;
  border: 2px dashed ${grey[40]};
  opacity: ${props => props.opacity};
  background-color: ${white};
  min-width: 300px;

  :not(.disabled):hover {
    cursor: pointer;
  }

  :focus {
    outline: none;
  }
`

const AddOrDropFile = ({ onDrop, onDropRejected, disabled }: AddOrDropProps) => {
  const { t } = useTranslation()
  const { getRootProps, getInputProps, isDragActive } = Dropzone.useDropzone({
    onDrop,
    accept: '.csv',
    maxFiles: 1,
    onDropRejected,
    disabled,
  })

  const shouldLighten = isDragActive || disabled

  return (
    <StyledSection
      {...getRootProps()}
      opacity={shouldLighten ? 0.6 : 1}
      data-testid="file-dropzone"
      role="button"
      className={disabled ? 'disabled' : ''}
    >
      <input {...getInputProps()} />
      <Body size="small" textColor={typography.dark}>
        {t('drop_or_click_to_select_file')}
      </Body>
    </StyledSection>
  )
}

export { AddOrDropFile }
