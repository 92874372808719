import { useQuery } from '@apollo/client'
import { GET_FILES } from '../queries'
import { Files } from '../queries/types/Files'

const useFiles = () => {
  const { loading, error, data, refetch, startPolling, stopPolling } =
    useQuery<Files>(GET_FILES)

  return {
    loading,
    error,
    data,
    refetch,
    startPolling,
    stopPolling,
  }
}

export { useFiles }
