import { Audiences } from 'data/types/graphql-global-types'
import { Factory } from 'fishery'
import { Partner_partner as TPartner } from '../queries/types/Partner'
import { partnerContactFactory } from './partnerContact'

const baseFields = {
  salesforceCustomerId: '',
  slug: '',
  partnerType: null,
  contacts: null,
  enrolledTotal: null,
  eligibleTotal: null,
  dateFormat: null,
  domains: [],
  domainOnboarding: false,
  overrideWelcomeEmail: false,
  overrideReminderEmail: false,
  welcomeEmailDate: null,
  welcomeEmailTemplate: null,
  reminderEmailTemplate: null,
  reminderEmailDays: null,
  terminationDate: null,
  program: null,
}

const partnerFactory = Factory.define<TPartner>(({ sequence }) => ({
  ...baseFields,
  __typename: 'Partner',
  id: `${sequence}`,
  hashId: `${sequence}`,
  name: `Partner ${sequence}`,
}))

const rapidPartnerFactory = Factory.define<TPartner>(({ sequence }) => ({
  ...baseFields,
  __typename: 'Partner',
  id: `${sequence}`,
  hashId: `${sequence}`,
  name: `Partner ${sequence}`,
  slug: `rapid-partner-slug-${sequence}`,
  partnerType: Audiences.partnership,
}))

const corporatePartnerFactory = Factory.define<TPartner>(
  ({ sequence, associations }) => ({
    ...baseFields,
    __typename: 'Partner',
    id: `${sequence}`,
    hashId: `${sequence}`,
    name: `Partner ${sequence}`,
    salesforceCustomerId: 'biznessd3ta1lz',
    partnerType: Audiences.corporate,
    contacts: associations.contacts || partnerContactFactory.buildList(1),
    program: associations.program || null,
  }),
)

export { partnerFactory, rapidPartnerFactory, corporatePartnerFactory }
