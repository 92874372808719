import * as React from 'react'

enum AlertVariants {
  error = 'error',
  success = 'success',
  info = 'info',
}

type TAlertProviderProps = {
  children: React.ReactNode
}
export type TAlert = {
  message: string
  variant: AlertVariants
  autoClose?: 4000 | 8000
}
export type TAlertWithId = TAlert & {
  id: number
}
type TState = TAlertWithId[]
type TActions = {
  addAlert: (alertArgs: TAlert) => void
  removeAlert: (id: number) => void
  clearAlerts: () => void
}
type TContext = TActions & {
  alerts: TState
}

const AlertsContext = React.createContext<TContext | undefined>(undefined)

const AlertProvider = ({ children }: TAlertProviderProps) => {
  const [alerts, setAlerts] = React.useState<TState>([])

  const addAlert = ({ message, variant, autoClose }: TAlert) =>
    setAlerts(prevAlerts => {
      const id = prevAlerts.length + 1
      const newMsg = { id, variant, message, autoClose }

      return [...prevAlerts, newMsg]
    })

  const removeAlert = (id: number) =>
    setAlerts(prevAlerts => prevAlerts.filter(item => item.id !== id))

  const clearAlerts = () => setAlerts([])

  const context = React.useMemo(
    () => ({
      alerts,
      addAlert,
      removeAlert,
      clearAlerts,
    }),
    [alerts],
  )

  return <AlertsContext.Provider value={context}>{children}</AlertsContext.Provider>
}
export { AlertsContext, AlertProvider, AlertVariants }
