import { Factory } from 'fishery'
import {
  Partner_partner_program_programBenefits_benefit as TBenefit,
  Partner_partner_program_programBenefits_benefit_retailRates as TRetailRate,
} from '../queries/types/Partner'
import { ProgramBenefitCountry } from '../types/graphql-global-types'

// only using benefits with names that we dont have to translate
const BENEFIT_NAMES = ['bike', 'bike plus', 'guide', 'tread']
const retailRateFactory = Factory.define<TRetailRate>(() => ({
  __typename: 'RetailRate',
  country: ProgramBenefitCountry.US,
  retailRateInCents: 10000,
  currency: 'USD',
}))

const benefitFactory = Factory.define<TBenefit>(({ sequence }) => ({
  __typename: 'Benefit',
  id: `${sequence}`,
  name: BENEFIT_NAMES[sequence] || 'no name',
  subscriptionType: 'digital',
  retailRates: retailRateFactory.buildList(3),
}))

export { benefitFactory, BENEFIT_NAMES }
