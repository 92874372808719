import React from 'react'
import styled from 'styled-components'

const LoadingEllipsis = () => (
  <Container>
    <First>.</First>
    <Second>.</Second>
    <Third>.</Third>
  </Container>
)

const Container = styled.div`
  display: flex;

  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const First = styled.span`
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0s;
  animation: dot 1.3s infinite;
  animation-delay: 0s;
`

const Second = styled.span`
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
`

const Third = styled.span`
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
`

export { LoadingEllipsis }
