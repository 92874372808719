import { Factory } from 'fishery'
import { MONTHS } from 'utils/date'
import { Reports_reports as TReports } from '../queries/types/Reports'

const year = 2021

const enrollmentReportFactory = Factory.define<TReports>(({ sequence }) => ({
  __typename: 'Report',
  hashId: `${sequence}`,
  filename: `Employee Enrollment List - ${MONTHS[sequence]} ${year}`,
}))

export { enrollmentReportFactory }
