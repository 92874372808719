import { useMinutesPerEngaged } from 'data/hooks/useInsights'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { defaultSelection, DateRange } from './DateRangeSelector'
import { Bar, Datum } from './graphs'
import { Module } from './Module'

type Props = {
  defaultDateRange?: DateRange
}

const WorkoutMinutesBarChart: React.FC<React.PropsWithChildren<Props>> = ({
  defaultDateRange = defaultSelection,
}) => {
  const { t } = useTranslation()
  const { partnerId } = usePartnerContext()
  const [range, setRange] = useState<DateRange>(defaultDateRange)
  const { data, hasData, loading, error } = useMinutesPerEngaged({ ...range, partnerId })
  const dateRangeSelect = (dateRange: DateRange) => setRange(dateRange)

  if (loading) {
    return (
      <Module
        loading
        emptyHeight="350px"
        label="insights.median_workout_minutes"
        dateRangeSelectId="workoutMinutes"
        dateRangeSelect={dateRangeSelect}
      />
    )
  }
  if (error || !hasData) {
    return (
      <Module
        empty
        emptyHeight="350px"
        label="insights.median_workout_minutes"
        dateRangeSelectId="workoutMinutes"
        dateRangeSelect={dateRangeSelect}
      />
    )
  }

  return (
    <Module
      label="insights.median_workout_minutes"
      dateRangeSelectId="workoutMinutes"
      dateRangeSelect={dateRangeSelect}
      padding="30px 40px 10px"
    >
      <Bar
        title={t('insights.median_workout_minutes')}
        tooltipLabel="insights.minute"
        data={data as Datum[]}
      />
    </Module>
  )
}

export { WorkoutMinutesBarChart }
