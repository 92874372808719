import { Body } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { sectionSpacing, LI, UL } from '.'
import { DownloadTemplateButton, InfoSection } from '..'
import { Box } from '../../../..'

const FileFormat = () => {
  const { t } = useTranslation()

  return (
    <InfoSection heading={t('file_format')} marginBottom={sectionSpacing}>
      <Body size="small" marginBottom="24px">
        {t('support.file_format.format_header')}
      </Body>
      <Body marginBottom="24px" size="small">
        <strong>{t('support.file_format.csv')}</strong>
      </Body>
      <Box marginBottom="24px">
        <UL>
          <LI>{t('support.file_format.format_instructions.0')}</LI>
          <LI>{t('support.file_format.format_instructions.1')}</LI>
          <LI>{t('support.file_format.format_instructions.2')}</LI>
        </UL>
      </Box>
      <DownloadTemplateButton to="/eligibility_template.csv" />
    </InfoSection>
  )
}

export { FileFormat }
