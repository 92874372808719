import { Label, grey } from '@pelotoncycle/design-system'
import { ReactNode } from 'react'
import styled from 'styled-components'

type TFieldProps = {
  label: string
  value?: string | ReactNode | null
}

const NO_VALUE_INDICATOR = '--'

const Field = ({ label, value }: TFieldProps) => {
  return (
    <FieldContainer data-testid={label}>
      <FieldCol size="large">{label}</FieldCol>
      <FieldCol size="large">{value || NO_VALUE_INDICATOR}</FieldCol>
    </FieldContainer>
  )
}
const FieldContainer = styled.div`
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: 238px 1fr;
`

const FieldCol = styled(Label)`
  &:first-child {
    color: ${grey[70]};
  }
`

export { Field, NO_VALUE_INDICATOR }
