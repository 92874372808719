import styled from 'styled-components'

type TextAlignProps = {
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'center'
    | 'right'
    | 'justify'
    | 'initial'
    | 'inherit'
    | '-moz-initial'
    | 'revert'
    | 'unset'
    | 'match-parent'
    | undefined
}

type Props = {
  backgroundColor?: string
  border?: string
  borderColor?: string
  borderBottom?: string
  borderTop?: string
  float?: 'right' | 'left'
  height?: string
  margin?: string | number
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
  marginTop?: string
  maxWidth?: string
  minWidth?: string
  padding?: string
  paddingBottom?: string
  paddingLeft?: string
  paddingRight?: string
  paddingTop?: string
  position?: 'absolute' | 'relative' | 'initial' | 'fixed' | 'static'
  width?: string
}

type BoxProps = Props & TextAlignProps

const Box = styled('div')<BoxProps>(props => ({
  backgroundColor: props.backgroundColor,
  border: props.border,
  borderColor: props.borderColor,
  borderBottom: props.borderBottom,
  borderTop: props.borderTop,
  display: 'block',
  height: props.height,
  margin: props.margin,
  marginBottom: props.marginBottom,
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  marginTop: props.marginTop,
  maxWidth: props.maxWidth,
  minWidth: props.minWidth,
  padding: props.padding,
  paddingBottom: props.paddingBottom,
  paddingLeft: props.paddingLeft,
  paddingRight: props.paddingRight,
  paddingTop: props.paddingTop,
  position: props.position || 'static',
  textAlign: props.textAlign || 'start',
  width: props.width,
  float: props.float,
}))

export { Box }
