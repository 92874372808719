const insightsSharedCopy = {
  insights: 'Insights',
  title: '{{partner}} Insights',
  enrolled_employee_one: 'Employee Joined',
  enrolled_employee_other: 'Employees Joined',
  class_completed_one: 'Class Taken',
  class_completed_other: 'Classes Taken',
  workout_minute_one: 'Minute Completed',
  workout_minute_other: 'Minutes Completed',
  enrollment_rate: 'Enrollment rate',
  enrolled: '{{num}} enrolled',
  eligible: '{{num}} employees eligible',
  engagement_rate: 'Activity rate',
  engaged: '{{num}} active',
  enrolled_engaged_users: 'Benefit utilization over time',
  membership_types: 'Memberships',
  class_types: 'Classes',
  median_workout_minutes: 'Average minutes per active member',
  total_classes_completed: 'Classes taken',
  total_classes_completed_empty: 'Classes taken',
  total_workout_minutes: 'Minutes completed',
  total_workout_minutes_empty: 'Minutes completed',
  class_one: 'class',
  class_other: 'classes',
  minute_one: 'minute',
  minute_other: 'minutes',
  today: 'TODAY',
  delay_notice: 'Data may take up to 48 hours to display',
  disclaimer: `
      Only significant values may be shown. 
      All insights displayed are for informational purposes only and shall not be used to track or collect personally identifiable information of any Members. 
      Information on this page is considered Peloton Confidential Information and is subject to the agreement between the parties.<br/><br/>

      Please note that Peloton may access and view the insights data included in this portal and use such data for Peloton's internal business purposes and analysis. 
      If you have any questions about such access, please reach out to your Peloton account manager.
      `,
  empty_module: 'Not enough data',
  empty_page:
    'Your team may be in motion, but current metrics are too low to display. Check back to view updated activity.',
  dateSelector: {
    quarterLabel: 'Select quarter',
    yearLabel: 'Select year',
    weeks: 'Weeks',
    months: 'Months',
  },
  employees_applied_progress:
    '<bold>{{amount}}</bold> out of {{total}} employees applied',
  employees_active_progress: '<bold>{{amount}}</bold> out of {{total}} employees active',
  total_classes: '{{num}} Total classes',
  total_minutes: '{{num}} Total minutes',
  total_memberships: '{{num}} Total memberships',
  employees_participated: 'Employees Participated',
  challenges: 'Challenges',
  challenge_finishers: 'Challenge Finishers',
  days_into_challenge: 'Days Into Challenge',
  final_day_of_challenge: 'Final Day of Challenge',
  challenge_complete: 'Challenge Complete',
  enrolled_key: 'Enrolled',
  active_key: 'Active',
  challenge_date: 'Challenge date',
  export_list: 'Export List',
  export_failed: 'Export Failed',
  export_loading: 'Export Loading...',
  challenges_disclaimer:
    'The information you see here may only be accessed for use by this organization. You agree to not attempt to use Insights to track or collect personally identifiable information of any members. Only significant values may be shown.',
  view_challenge_details: 'View Challenge Details',
  challenge_participants: 'Challenge Participants',
  back_to_insights: 'Back to Insights',
  percent_participated: '<bold>{{percent}}</bold>% of enrolled employees',
  percent_finished: '<bold>{{percent}}</bold>% of challenge participants',
  percent_duration: '<bold>{{percent}}</bold>% of challenge duration',
  classes_taken: 'Classes Taken',
  total_minutes_completed: 'Total Minutes Completed',
  high_fives_given: 'High Fives Given',
  top_five_instructors: 'Top Five Instructors',
  top_five_class_types: 'Top Five Classes',
}

export { insightsSharedCopy }
