import { white } from '@pelotoncycle/design-system'
import styled from 'styled-components'
import { Flex } from './Flex'

const Card = styled(Flex)`
  border-radius: 5px;
  background-color: ${p => p.backgroundColor || white};
  padding: ${p => p.padding || '46px 0'};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
  margin-bottom: ${p => p.marginBottom || '32px'};
`

export { Card }
