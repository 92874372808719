import {
  white,
  grey,
  black,
  BreakpointWidths,
  secondaryFamily,
  Eyebrow,
} from '@pelotoncycle/design-system'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { media } from '../responsive'
import { Flex } from './Flex'

type NavLinkProps = {
  activeColor?: string
}

type TStepNavigationProps = {
  to: string
  color?: string
  complete?: boolean
  stepNumber: string
  text?: string
}

type TNumberProps = {
  color: string
  borderColor: string
  backgroundColor?: string
}

const activeClassName = 'active'
const { tablet } = BreakpointWidths

const eyebrowStyles = css`
  font-family: ${secondaryFamily};
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 1.23;

  ${media.atTablet`
    font-size: 14px;
    line-height: 1.42;
  `}
`

const sharedNavStyles = css`
  display: inline-block;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;

  :not(:last-child) {
    margin-right: 16px;
  }

  ${media.atTablet`
    transition: border-bottom 250ms linear 0s;

    :not(:last-child) {
      margin-right: 32px;
    }
  `}
`

const StyledStepNavigationLink = styled(NavLink).withConfig({
  shouldForwardProp: prop => !['color'].includes(prop),
})`
  ${eyebrowStyles}
  ${sharedNavStyles}
  color: ${p => p.color};

  .linkText {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
  }

  :hover .linkText {
    border-bottom: 2px solid ${p => p.color};
  }

  &.${activeClassName} .linkText {
    border-bottom: 2px solid ${p => p.color};
  }
`

const NavigationLink = styled(NavLink).withConfig({
  shouldForwardProp: prop => !['activeColor', 'color'].includes(prop),
})<NavLinkProps>`
  ${eyebrowStyles}
  ${sharedNavStyles}
  color: ${p => p.color};

  :hover {
    border-bottom: 2px solid ${p => p.color};
    color: ${p => p.color};
  }

  &.${activeClassName} {
    border-bottom: 2px solid ${p => p.activeColor};
    color: ${p => p.activeColor};

    :hover {
      border-bottom: 2px solid ${p => p.activeColor};
      color: ${p => p.activeColor};
    }
  }
`

const HeaderNavLink = styled(NavigationLink)`
  ${sharedNavStyles}

  color: ${white};
  font-size: 18px;
  margin-bottom: 32px;
  width: fit-content;

  @media (min-width: ${tablet}px) {
    font-size: 14px;
    margin-bottom: 0;

    :hover {
      color: ${grey[50]};
      border-bottom: 2px solid ${grey[50]};
    }

    &.${activeClassName} {
      border-bottom: 2px solid ${white};
      color: ${white};

      :hover {
        border-bottom: 2px solid ${white};
        color: ${white};
      }
    }
  }
`

const Number = styled.div<TNumberProps>`
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  padding-left: 1.5px;
  margin-right: 8px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ borderColor }) => borderColor};
`

const StepNavigationLink = ({
  to,
  color = black,
  complete,
  stepNumber,
  text,
}: TStepNavigationProps) => {
  const primaryColor = complete ? black : grey[60]

  return (
    <StyledStepNavigationLink to={to} color={color} data-testid="step-navigation-link">
      <Flex alignItems="center">
        <Number
          color={complete ? white : grey[60]}
          backgroundColor={complete ? black : ''}
          borderColor={primaryColor}
        >
          {stepNumber}
        </Number>
        {text && (
          <Eyebrow className="linkText" color={primaryColor}>
            {text}
          </Eyebrow>
        )}
      </Flex>
    </StyledStepNavigationLink>
  )
}

export { NavigationLink, HeaderNavLink, StepNavigationLink, Number }
