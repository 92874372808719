import '@reach/dialog/styles.css'
import { grey } from '@pelotoncycle/design-system'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Flex, Box } from '.'
import { NavLinks } from './@web'
import { HamburgerMenu, X } from './svg'

type TimeoutType = ReturnType<typeof setTimeout>

// source: https://github.com/reach/reach-ui/issues/568#issuecomment-622114793
const useDelayUnmount = (showModal: boolean, delayTime: number) => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    let timeoutId: TimeoutType | undefined

    if (showModal && !shouldRender) {
      setShouldRender(true)
    } else if (!showModal && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime)
    }

    return () => clearTimeout(timeoutId as TimeoutType)
  }, [showModal, delayTime, shouldRender])

  return shouldRender
}

const MobileMenu = () => {
  const [showModal, setShowModal] = useState(false)
  const shouldRenderChild = useDelayUnmount(showModal, 380)
  const { t } = useTranslation()
  const dropIn = { animation: `dropIn 400ms ease 0s` }
  const liftOut = { animation: `liftOut 400ms ease 0s` }
  const ariaLabel: string = t('navigation_menu')

  return (
    <Box marginLeft="24px" data-testid="mobile-menu">
      <button
        style={{ height: '100%' }}
        type="button"
        onClick={() => setShowModal(true)}
        aria-label={t('open_menu')}
        tabIndex={shouldRenderChild ? -1 : 0}
      >
        <HamburgerMenu />
      </button>
      {shouldRenderChild && (
        <StyledOverlay>
          <StyledContent style={showModal ? dropIn : liftOut} aria-label={ariaLabel}>
            <Flex width="100%" justifyContent="flex-end" marginBottom="24px">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                aria-label={t('close_menu')}
              >
                <X width="20px" height="20px" />
              </button>
            </Flex>
            <NavLinks handleClick={() => setShowModal(false)} />
          </StyledContent>
        </StyledOverlay>
      )}
    </Box>
  )
}

const StyledOverlay = styled(DialogOverlay)`
  background: transparent;
`

// works for fade in but not out
const StyledContent = styled(DialogContent)`
  width: 100vw;
  height: 100vh;
  background-color: ${grey[90]};
  margin: 0;

  @keyframes dropIn {
    from {
      transform: translate3d(0px, -100%, 0px);
    }
    to {
      transform: translate3d(0px, 0px, 0px);
    }
  }

  @keyframes liftOut {
    from {
      transform: translate3d(0px, 0px, 0px);
    }
    to {
      transform: translate3d(0px, -103%, 0px);
    }
  }
`

export { MobileMenu }
