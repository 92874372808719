import { black, Headline, Body } from '@pelotoncycle/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LogoAdmin, Logo as LogoPortal } from './svg'

type Props = {
  isAdmin?: boolean
}

const DowntimeScreen = ({ isAdmin = false }: Props) => {
  const { t } = useTranslation()

  let logo = <LogoPortal fill={black} width="100%" height="53px" />
  if (isAdmin) {
    logo = <LogoAdmin fill={black} width="100%" height="53px" />
  }

  return (
    <Container>
      <Inner>
        <LogoContainer>{logo}</LogoContainer>
        <Title size="large" textAlign="center">
          {t('downtime_page.title')}
        </Title>
        <Body textAlign="center">{t('downtime_page.body')}</Body>
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: -10px auto;
  padding: 0;
`

const Inner = styled.div`
  display: flex;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LogoContainer = styled.div`
  margin-bottom: 5rem;
`

const Title = styled(Headline)`
  margin-bottom: 1rem;
`

export { DowntimeScreen }
