import { white } from '@pelotoncycle/design-system'

const Avatar = () => (
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none">
    <circle cx="12" cy="6" r="5" stroke="white" strokeWidth="2" />
    <path
      d="M23 21C23 16.0294 18.0751 12 12 12C5.92487 12 1 16.0294 1 21"
      stroke={white}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export { Avatar }
