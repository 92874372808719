import {
  black,
  yellow,
  white,
  slate,
  accessibility,
  red,
} from '@pelotoncycle/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { media } from '../responsive'
import { useAlerts, AlertVariants, TAlertWithId } from './Alerts'
import { Box } from './Box'
import { Flex } from './Flex'
import { Check, XCircle } from './svg'

const Alert = ({ alert }: { alert: TAlertWithId }) => {
  const { removeAlert } = useAlerts()
  const { id, message, variant = AlertVariants.info, autoClose } = alert
  const { t } = useTranslation()
  const isSuccess = variant === AlertVariants.success
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null

    if (autoClose) {
      timeout = setTimeout(() => removeAlert(id), autoClose)
    }

    return () => {
      if (timeout != null) {
        clearTimeout()
      }
      timeout = null
    }
  }, [autoClose, id, removeAlert])

  const getVariantColors = (variantName: AlertVariants) => {
    switch (variantName) {
      case AlertVariants.error:
        return [white, red[80]]
      case AlertVariants.info:
        return [black, yellow[80]]
      case AlertVariants.success:
      default:
        return [white, slate[50]]
    }
  }

  const [primaryColor, secondaryColor] = getVariantColors(variant)

  return (
    <StyledAlert
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      data-testid={`alert-${variant}`}
      role="alert"
    >
      {isSuccess && (
        <Box marginRight="8px">
          <Check fill={primaryColor} />
        </Box>
      )}

      <Message>{message}</Message>

      <Flex paddingLeft="40px" justifyContent="flex-end">
        <CloseButton
          type="button"
          onClick={() => removeAlert(id)}
          data-testid="close-alert-btn"
          aria-label={t('close_alert')}
        >
          <CloseIcon primaryColor={primaryColor} secondaryColor={secondaryColor} />
        </CloseButton>
      </Flex>
    </StyledAlert>
  )
}

const AlertDisplay = () => {
  const { alerts, hasAlerts } = useAlerts()
  if (!hasAlerts) return null

  return (
    <AlertsContainer>
      {alerts.map(alert => (
        <Alert key={`alert-${alert.id}`} alert={alert} />
      ))}
    </AlertsContainer>
  )
}

const Message = styled.p`
  display: block;
  margin: 0;
  flex-grow: 1;
`

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 25px;
  height: 16px;

  &:focus {
    outline: 2px solid ${accessibility};
  }
`

type TVariantProps = {
  primaryColor: string
  secondaryColor: string
}

const CloseIcon = styled(XCircle).attrs<TVariantProps>(
  ({ primaryColor, secondaryColor }) => ({
    primaryFill: secondaryColor,
    backgroundFill: primaryColor,
  }),
)<TVariantProps>``

const StyledAlert = styled.div<TVariantProps>`
  display: flex;
  align-items: center;
  position: static;
  margin: 0 auto;
  padding: 16px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: ${props => props.primaryColor};
  background-color: ${props => props.secondaryColor};

  ${media.atTablet`
    position: relative;
    border-radius: 25px;
    max-width: 700px;
    margin: 16px auto 0;
    padding: 4px 16px;
  `};
`

const AlertsContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 4;
`

export { AlertDisplay }
