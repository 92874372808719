import { Radio, SingleSelect } from '@pelotoncycle/design-system'
import { epoch } from 'data/hooks/useInsights'
import {
  addYears,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  format,
  startOfYear,
  endOfYear,
} from 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useWindowWidth } from 'ui/hooks'
import { yearsSinceEpoch, quartersSinceEpoch } from './constants'
import { Period, DateRange } from './types'

const selectionToDateRange = (period: Period, item: string): DateRange => {
  let offset: number
  let selection: Date
  let dateRange: DateRange

  if (period === Period.Weekly) {
    offset = quartersSinceEpoch.indexOf(item)
    selection = addQuarters(epoch, offset)
    dateRange = {
      startDate: format(startOfQuarter(selection), 'yyyy-MM-dd'),
      endDate: format(endOfQuarter(selection), 'yyyy-MM-dd'),
      period: Period.Weekly,
    }
  } else {
    offset = yearsSinceEpoch.indexOf(item)
    selection = addYears(epoch, offset)
    dateRange = {
      startDate: format(startOfYear(selection), 'yyyy-MM-dd'),
      endDate: format(endOfYear(selection), 'yyyy-MM-dd'),
      period: Period.Monthly,
    }
  }

  return dateRange
}

/*
for UX reasons, use the second to last quarter as default since 
the latest quarter's data is usually sparse or empty
*/
const defaultItem = quartersSinceEpoch[Math.max(0, quartersSinceEpoch.length - 2)]
const defaultSelection = selectionToDateRange(Period.Weekly, defaultItem)

type Props = {
  id: string
  dateRangeSelect: (dateRange: DateRange) => void
}

const DateRangeSelector: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  dateRangeSelect,
}) => {
  const { t } = useTranslation()
  const { isAboveMobile } = useWindowWidth()
  const [period, setPeriod] = useState<Period>(Period.Weekly)
  const [selectedItem, setSelectedItem] = useState(defaultItem)

  let selectLabel: string
  let selectItems: string[]
  if (period === Period.Weekly) {
    selectLabel = t('insights.dateSelector.quarterLabel')
    selectItems = quartersSinceEpoch
  } else {
    selectLabel = t('insights.dateSelector.yearLabel')
    selectItems = yearsSinceEpoch
  }

  return (
    <Container>
      <SelectWrapper>
        <SingleSelect
          label={selectLabel}
          items={selectItems}
          selectedItem={selectedItem}
          width={isAboveMobile ? '280px' : '180px'}
          handleSelectedItemChange={(item: string) => {
            if (!item) {
              return
            }

            setSelectedItem(item)
            const dateRange = selectionToDateRange(period, item)

            dateRangeSelect(dateRange)
          }}
        />
      </SelectWrapper>
      <ToggleContainer>
        <RadioWrapper>
          <Radio
            name={id}
            checked={period === Period.Weekly}
            labelText={t('insights.dateSelector.weeks')}
            value={Period.Weekly}
            handleChange={() => setPeriod(Period.Weekly)}
          />
        </RadioWrapper>
        <RadioWrapper>
          <Radio
            name={id}
            checked={period === Period.Monthly}
            labelText={t('insights.dateSelector.months')}
            value={Period.Monthly}
            handleChange={() => setPeriod(Period.Monthly)}
          />
        </RadioWrapper>
      </ToggleContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;

  @media (min-width: 530px) {
    flex-direction: row;
  }
`

const SelectWrapper = styled.div`
  li:first-child {
    display: none;
  }
`

const RadioWrapper = styled.div`
  display: inline-block;

  &:first-child {
    margin-right: 2rem;
  }
`

const ToggleContainer = styled.div`
  margin: 1rem 0 0 0;

  @media (min-width: 530px) {
    margin: 0 0 0 2rem;
  }
`

export { DateRangeSelector, defaultSelection, selectionToDateRange }
