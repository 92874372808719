import { gql } from '@apollo/client'

const GET_BENEFITS = gql`
  query Benefits {
    benefits {
      id
      name
      subscriptionType
    }
  }
`

export { GET_BENEFITS }
