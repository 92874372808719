import { BreakpointWidths } from '@pelotoncycle/design-system'
import styled from 'styled-components'

type ListItemProps = {
  $indent?: boolean
  $addSpace?: boolean
}

const { tablet } = BreakpointWidths

const UL = styled.ul`
  list-style-position: outside;
  list-style-type: disc;
  padding-left: 32px;
`

const LI = styled.li<ListItemProps>`
  line-height: 24px;
  margin-left: ${({ $indent }) => ($indent ? '8px' : '0')};
  margin-bottom: ${({ $addSpace }) => ($addSpace ? '16px' : '0')};
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.3px;

  @media (min-width: ${tablet}px) {
    font-size: 16px;
    margin-left: ${({ $indent }) => ($indent ? '40px' : '0')};
    margin-bottom: ${({ $addSpace }) => ($addSpace ? '24px' : '0')};
  }
`

export { UL, LI }
