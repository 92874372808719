import { Eyebrow, black, grey, red, typography } from '@pelotoncycle/design-system'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useWindowWidth } from '../hooks'
import { Flex } from './Flex'
import { IconProps } from './svg/IconTypes'

type BlockLinkProps = {
  to: string
  text: string
  Icon?: React.FC<React.PropsWithChildren<IconProps>>
  marginRight?: string
}

type LinkProps = {
  marginRight?: string
}

const BlockLink = ({
  to = '',
  Icon = undefined,
  text = '',
  marginRight = undefined,
}: BlockLinkProps) => {
  const { isAboveMobile } = useWindowWidth()

  return (
    <StyledLink to={to} style={{ marginRight }}>
      {Icon && (
        <Flex
          marginRight="16px"
          width="40px"
          minWidth="40px"
          height="40px"
          alignItems="center"
          justifyContent="center"
        >
          <Icon
            width={isAboveMobile ? '100%' : '60%'}
            height={isAboveMobile ? '100%' : '60%'}
          />
        </Flex>
      )}
      <Eyebrow as="span" textColor={typography.dark}>
        {text.toUpperCase()}
      </Eyebrow>
    </StyledLink>
  )
}

const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  text-decoration: none;
  background-color: ${grey[20]};
  flex: 1;
  align-items: center;
  padding: 16px 32px;
  border-bottom: 2px solid ${grey[50]};
  color: ${black};

  :hover {
    border-color: ${red[50]};
    cursor: pointer;
  }
`

export { BlockLink }
