import { useContext } from 'react'
import { AlertsContext } from './AlertsContext'

function useAlerts() {
  const context = useContext(AlertsContext)

  if (context === undefined) {
    throw new Error('useAlerts must be used within an AlertProvider')
  }

  const { alerts, addAlert, removeAlert, clearAlerts } = context
  const hasAlerts = !!alerts.length

  return { alerts, hasAlerts, addAlert, removeAlert, clearAlerts }
}

export { useAlerts }
