import { white } from '@pelotoncycle/design-system'
import React from 'react'
import { VictoryTooltip, VictoryTooltipProps } from 'victory'

const Tooltip: React.FC<React.PropsWithChildren<VictoryTooltipProps>> = props => (
  <VictoryTooltip
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    dy={-13}
    cornerRadius={4}
    pointerLength={9}
    pointerWidth={16}
    flyoutPadding={16}
    flyoutStyle={{
      fill: 'rgba(0, 0, 0, 0.7)',
      strokeWidth: 0,
      filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16))',
    }}
    style={{
      fill: white,
      fontFamily: 'Roboto,brandon-grotesque,sans-serif',
      fontSize: '12px',
      fontWeight: 400,
    }}
    constrainToVisibleArea
  />
)

export { Tooltip }
