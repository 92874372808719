import { grey, Headline, Eyebrow, Icon, IconNames } from '@pelotoncycle/design-system'
import { useChallenge } from 'data/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

enum Metric {
  ClassesCompletedCount = 'classesCompletedCount',
  MinutesCompletedCount = 'totalMinutes',
}

type Props = {
  challengeId: string
  metric: Metric
  icon: IconNames
  label: string
}

type MetricProps = {
  challengeId: string
}

const NumberMetric: React.FC<Props> = ({ challengeId, metric, icon, label }) => {
  const { t } = useTranslation()
  const { challenge } = useChallenge({ challengeId })

  if (!challenge) {
    return null
  }

  const count = challenge[metric]

  return (
    <Container>
      <IconStyled name={icon} primaryColor={grey[60]} height={48} />
      <Headline size="extraLarge">{count.toLocaleString()}</Headline>
      <Eyebrow size="medium" textColor={grey[50]}>
        {t(label)}
      </Eyebrow>
    </Container>
  )
}

const ClassesTakenMetric: React.FC<MetricProps> = ({ challengeId }) => (
  <NumberMetric
    challengeId={challengeId}
    metric={Metric.ClassesCompletedCount}
    icon="upperBody"
    label="insights.classes_taken"
  />
)

const MinutesCompletedMetric: React.FC<MetricProps> = ({ challengeId }) => (
  <NumberMetric
    challengeId={challengeId}
    metric={Metric.MinutesCompletedCount}
    icon="clock"
    label="insights.total_minutes_completed"
  />
)

const Container = styled.div`
  padding-top: 2rem;
  text-align: center;
`

const IconStyled = styled(Icon)`
  margin-bottom: 1.5rem;
`

export { ClassesTakenMetric, MinutesCompletedMetric }
