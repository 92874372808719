import { useHasChallenges } from 'data/hooks'
import React, { useState } from 'react'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { Challenge } from './Challenge'
import { useChallengeIdForKey, useLastChallengeKey } from './data'

const Challenges: React.ElementType = () => {
  const { partnerId } = usePartnerContext()
  const hasChallenges = useHasChallenges({ partnerId })
  const defaultKey = useLastChallengeKey()
  const [selection, setSelection] = useState(defaultKey)
  const curSelection = selection || defaultKey
  const challengeId = useChallengeIdForKey(curSelection)

  if (!hasChallenges || !challengeId) {
    return null
  }

  return (
    <Challenge
      challengeId={challengeId}
      selection={curSelection}
      challengeSelect={item => setSelection(item)}
    />
  )
}

export { Challenges }
