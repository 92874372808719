import { SingleSelect } from '@pelotoncycle/design-system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useWindowWidth } from 'ui/hooks'
import { media } from 'ui/responsive'
import { useChallengeKeys } from './data'

type Props = {
  selection: string
  challengeSelect: (challenge: string) => void
  className?: string
}

const Selector: React.FC<React.PropsWithChildren<Props>> = ({
  selection: defaultSelection,
  challengeSelect,
  className,
}) => {
  const { t } = useTranslation()
  const { isAboveMobile } = useWindowWidth()
  const keys = useChallengeKeys()
  const [selection, setSelection] = useState(defaultSelection)

  return (
    <Container className={className}>
      <SingleSelect
        label={t('insights.challenge_date')}
        theme="dark"
        width={isAboveMobile ? '224px' : '100%'}
        selectedItem={selection}
        items={keys}
        handleSelectedItemChange={(item: string) => {
          if (item) {
            setSelection(item)
            challengeSelect(item)
          }
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  flex-grow: 1;

  li:first-child {
    display: none;
  }

  ${media.atTablet`
    flex-grow: initial;
  `}
`

export { Selector }
