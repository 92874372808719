import { Badge, Eyebrow, Headline, white, grey } from '@pelotoncycle/design-system'
import { useTotals, useChallenge, useActiveChallenge } from 'data/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Flex } from 'ui/components'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { media } from 'ui/responsive'
import bg from './Challenges/static/moduleBg.png'
import { Module } from './Module'

const truncate = (value: number): string => {
  let val
  if (value >= 1e6) {
    val = `${Math.floor(value / 1e5) / 10}M`
  } else if (value >= 1e4) {
    val = `${Math.floor(value / 1e3)}K`
  } else {
    val = value.toLocaleString()
  }

  return val
}

type NumProps = {
  value: number
  label: string
}

type ChallengeNumProps = {
  challengeId: string
}

const Num: React.FC<React.PropsWithChildren<NumProps>> = ({ value, label }) => {
  const { t } = useTranslation()

  const val = truncate(value)

  return (
    <NumContainer>
      <Headline size="extraLarge">{val}</Headline>
      <Eyebrow textColor={grey[70]}>{t(label, { count: value })}</Eyebrow>
    </NumContainer>
  )
}

const ChallengeNum: React.FC<ChallengeNumProps> = ({ challengeId }) => {
  const { t } = useTranslation()
  const { loading, error, challenge } = useChallenge({ challengeId })

  if (loading || error || !challenge) {
    return null
  }

  const val = challenge.participantCount

  return (
    <ChallengeNumContainer>
      <ChallengeLink to={`/insights/challenges/${challenge.id}`}>
        <ChallengeBadge variant="diagonal" position="absolute" top="0" right="0">
          {t('insights.active_key')}
        </ChallengeBadge>
        <ChallengeDots />
        <ChallengeInner>
          <Headline size="extraLarge">{val.toLocaleString()}</Headline>
          <ChallengeEyebrow>
            {t('insights.challenge_participants', { count: val })}
          </ChallengeEyebrow>
        </ChallengeInner>
      </ChallengeLink>
    </ChallengeNumContainer>
  )
}

const Numbers = () => {
  const { partnerId } = usePartnerContext()
  const { getTotal, hasTotal, loading, error } = useTotals({ partnerId })
  const { activeChallenge } = useActiveChallenge()

  const enrolled = getTotal('enrolled_total')
  const classesCompleted = getTotal('classes_completed_total')
  const workoutMinutes = getTotal('workout_minutes_total')
  const hasData =
    hasTotal('enrolled_total') &&
    hasTotal('classes_completed_total') &&
    hasTotal('workout_minutes_total')

  if (loading) {
    return <Module loading />
  }
  if (error || !hasData) {
    return <Module empty emptyHeight="150px" />
  }

  return (
    <Module padding="0">
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Num value={enrolled} label="insights.enrolled_employee" />
        <Num value={classesCompleted} label="insights.class_completed" />
        <Num value={workoutMinutes} label="insights.workout_minute" />
        {activeChallenge && <ChallengeNum challengeId={activeChallenge.id} />}
      </Flex>
    </Module>
  )
}

const NumContainer = styled.div`
  padding: 3rem 1rem;
  flex: 1 0 50%;
  text-align: center;

  ${media.atDesktop`
    flex: 1 0 auto;
  `}
`

const ChallengeNumContainer = styled(NumContainer)`
  position: relative;
  color: ${white};
  background: ${grey[80]};

  ${media.atDesktop`
    flex: 0 0 300px;
  `}
`

const ChallengeInner = styled.div`
  position: relative;
  z-index: 2;
`

const ChallengeEyebrow = styled(Eyebrow)`
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 150ms;
`

const ChallengeLink = styled(Link)`
  &:hover {
    ${ChallengeEyebrow} {
      border-bottom-color: ${white};
    }
  }
`

const ChallengeBadge = styled(Badge)`
  z-index: 2;
`

const ChallengeDots = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  right: 0;
  background: url(${bg}) no-repeat top right;
  transform: scaleY(-1);
  opacity: 0.25;
`

export { Numbers, truncate }
