import { grey, data as dataColor } from '@pelotoncycle/design-system'
import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { VictoryChart, VictoryArea, VictoryAxis, VictoryVoronoiContainer } from 'victory'
import { Tooltip } from './Tooltip'
import { ChartProps, LabelData } from './types'
import { defaultTickLabel, rangeTickFormat } from './utils'

type AreaProps = {
  tooltipLabel: string
} & ChartProps

const Area: React.FC<React.PropsWithChildren<AreaProps>> = ({
  title,
  tooltipLabel,
  data,
}) => {
  const { t } = useTranslation()

  const Container = (
    <VictoryVoronoiContainer
      title={title}
      voronoiDimension="x"
      labels={(d: LabelData) =>
        `${d.datum.y?.toLocaleString()} ${t(tooltipLabel, {
          count: d.datum.y,
        })}\nweek of ${format(d.datum.x, 'MM/dd/yyyy')}`
      }
      labelComponent={<Tooltip />}
    />
  )

  return (
    <div>
      <VictoryChart height={300} containerComponent={Container}>
        <VictoryAxis
          dependentAxis
          tickCount={5}
          tickFormat={rangeTickFormat}
          style={{
            axis: {
              stroke: 'none',
            },
            tickLabels: defaultTickLabel,
            grid: {
              stroke: grey[40],
            },
          }}
        />
        <VictoryAxis
          style={{
            axis: {
              stroke: grey[40],
            },
            tickLabels: defaultTickLabel,
          }}
          tickFormat={val => format(val as Date, 'MM/dd/yyyy')}
          tickValues={[data[0].x, data[data.length - 1].x]}
        />
        <VictoryArea
          data={data}
          style={{
            data: {
              fill: dataColor.teal,
              fillOpacity: 0.12,
              stroke: dataColor.teal,
              strokeWidth: 3,
            },
          }}
        />
      </VictoryChart>
    </div>
  )
}

export { Area }
