import React from 'react'
import { Dashboard } from 'ui/components/Insights/Challenges'
import { PartnerProvider } from 'ui/components/PartnerContext'

const Challenges = () => (
  <PartnerProvider>
    <Dashboard />
  </PartnerProvider>
)

export { Challenges }
