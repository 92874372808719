import { white } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

export const HamburgerMenu = ({
  height = '19px',
  width = '26px',
  stroke = white,
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 19" fill="none">
      <path
        d="M9.375 9.4375H24.25"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M1.5 1.5625H24.25" stroke={white} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M9.375 17.3125H24.25"
        stroke={white}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
