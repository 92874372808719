import { gql } from '@apollo/client'

const REQUIRED_REPORT_FIELDS = gql`
  fragment RequiredReportFields on Report {
    hashId
    filename
  }
`

const REPORT_FIELDS = gql`
  fragment ReportFields on Report {
    id
    partnerName
    startDate
    rowCount
    approved
    reportType
  }
`
export { REQUIRED_REPORT_FIELDS, REPORT_FIELDS }
