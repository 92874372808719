import { grey, Eyebrow } from '@pelotoncycle/design-system'
import React from 'react'
import styled from 'styled-components'
import { Box } from '../../..'

type InfoSectionProps = {
  heading: string
  children: React.ReactNode
  marginBottom?: string
  omitUnderline?: boolean
}

const InfoSection = ({
  heading,
  children,
  marginBottom,
  omitUnderline = false,
}: InfoSectionProps) => {
  return (
    <Box marginBottom={marginBottom}>
      <Eyebrow>{heading}</Eyebrow>
      {!omitUnderline && <Underline />}
      {children}
    </Box>
  )
}

const Underline = styled.div`
  border-bottom: 1px solid ${grey[40]};
  width: 100%;
  margin: 16px 0;
`

export { InfoSection }
