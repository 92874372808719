import { useTotals, useWorkoutMinutes } from 'data/hooks/useInsights'
import { useTranslation } from 'react-i18next'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { Area, Datum } from './graphs'
import { Module } from './Module'

const WorkoutMinutesAreaChart = () => {
  const { t } = useTranslation()
  const { partnerId } = usePartnerContext()
  const totals = useTotals({ partnerId })
  const workoutMinutes = useWorkoutMinutes({ partnerId })

  const total = totals.getTotal('workout_minutes_total')
  const hasData = totals.hasTotal('workout_minutes_total') && workoutMinutes.hasData
  const loading = totals.loading || workoutMinutes.loading
  const empty = totals.error || workoutMinutes.error || !hasData

  if (loading) {
    return <Module label="insights.total_workout_minutes_empty" loading />
  }
  if (empty) {
    return <Module label="insights.total_workout_minutes_empty" empty />
  }

  return (
    <Module
      label="insights.total_workout_minutes"
      support="insights.total_minutes"
      supportProps={{ num: total.toLocaleString() }}
      padding="30px 40px 10px"
    >
      <Area
        title={t('insights.total_workout_minutes')}
        tooltipLabel="insights.minute"
        data={workoutMinutes.data as Datum[]}
      />
    </Module>
  )
}

export { WorkoutMinutesAreaChart }
