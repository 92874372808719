import { Body } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LoadingEllipsis } from '.'

const Loading = () => {
  const { t } = useTranslation()

  return (
    <StyledLoading>
      <Body>{t('loading')}</Body>
      <LoadingEllipsis />
    </StyledLoading>
  )
}

const StyledLoading = styled.div`
  padding: 64px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
`

export { Loading }
