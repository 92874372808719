import { Eyebrow, black, white } from '@pelotoncycle/design-system'
import { Files_files as IFiles } from 'data/queries/types/Files'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from '.'
import { Processing } from './svg'

type THeadingRenderProps = IFiles | Record<string, unknown>

export type THeadingProps = {
  key: string
  translationKey: string
  render(arg?: THeadingRenderProps): ReactElement<THeadingRenderProps> | string
}
export type DataItem = IFiles

type TableProps = {
  headings: THeadingProps[]
  rowData: DataItem[]
  newRowLoading: boolean
}

type TDProps = {
  verticalAlign?: string
  textAlign?: string
  lineHeight?: string
}

const Table = ({ headings, rowData = [], newRowLoading }: TableProps) => {
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <StyledTable>
        <thead>
          <HeaderTR data-testid="header-row">
            {headings.map(heading => (
              <Eyebrow key={heading.key} is="th" size="small" display="table-cell">
                {t(heading.translationKey)}
              </Eyebrow>
            ))}
          </HeaderTR>
        </thead>

        <tbody>
          {newRowLoading && (
            <TR>
              <TD
                colSpan={7}
                verticalAlign="middle"
                textAlign="center"
                lineHeight="unset"
              >
                <Processing width="20px" height="20px" animate />
              </TD>
            </TR>
          )}
          {rowData?.map(row => {
            return (
              <TR data-testid="data-row" key={row.id}>
                {headings.map(({ key, render }) => (
                  <TD data-testid={`td-${key}`} key={key}>
                    {render(row)}
                  </TD>
                ))}
              </TR>
            )
          })}
        </tbody>
      </StyledTable>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)`
  overflow-x: auto;
`

const TR = styled.tr`
  height: 80px;
  background-color: ${white};

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.125);
  }
`

const HeaderTR = styled.tr`
  text-align: left;
  white-space: nowrap;

  & th {
    padding-left: 16px;
  }
`

const TD = styled.td<TDProps>`
  color: ${black};
  font-size: 14px;
  line-height: ${props => props.lineHeight || '24px'};
  min-width: 50px;
  white-space: nowrap;
  padding: 16px;
  vertical-align: ${props => props.verticalAlign || 'top'};
  text-align: ${props => props.textAlign || 'start'};
`

const StyledTable = styled.table`
  display: table;
  width: 100%;
  margin-top: 48px;
  border-collapse: separate;
  border-spacing: 0 8px;
  padding-bottom: 80px;
`

export { Table }
