import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageLayout } from '.'
import { useWindowWidth } from '../../../../hooks'
import {
  DataSpecification,
  Overview,
  FileFormat,
  FileSubmissionCadence,
  EffectiveTerminationDates,
} from './GettingStartedSections'

const GettingStarted = () => {
  const { isDesktop } = useWindowWidth()
  const { t } = useTranslation()

  return (
    <PageLayout
      testId="support-getting-started"
      headline={t('get_started_eligibility')}
      pageStyles={{ padding: isDesktop ? '0 182px 0 16px' : '0 16px' }}
    >
      <Overview />
      <FileFormat />
      <FileSubmissionCadence />
      <EffectiveTerminationDates />
      <DataSpecification />
    </PageLayout>
  )
}

export { GettingStarted }
