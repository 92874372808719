import { black } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const Search = ({ width = '21px', height = '21px', fill = black }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 21 21">
    <title>Search</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5009 13.8393L20.6511 18.9895C21.1114 19.4498 21.1114 20.1979 20.6798 20.6295C20.4496 20.8596 20.1332 20.9747 19.8454 20.9747C19.5289 20.9747 19.2412 20.8596 19.0111 20.6295L13.8321 15.5081C12.3072 16.6877 10.4658 17.2632 8.62439 17.2632C6.40895 17.2632 4.22228 16.4288 2.52474 14.7312C-0.841579 11.3649 -0.841579 5.89825 2.52474 2.53193C4.22228 0.834386 6.40895 0 8.62439 0C10.8398 0 13.0265 0.834386 14.724 2.53193C17.8314 5.61053 18.0904 10.473 15.5009 13.8393ZM4.19351 13.0913C5.40193 14.2709 6.95561 14.9327 8.65316 14.9327C10.3219 14.9327 11.9332 14.2997 13.1128 13.0913C14.2925 11.8829 14.9542 10.3292 14.9542 8.63164C14.9542 6.9341 14.3212 5.35164 13.1128 4.17199C11.9044 2.99234 10.3507 2.33059 8.65316 2.33059C6.95561 2.33059 5.37316 2.99234 4.19351 4.17199C1.7479 6.64638 1.7479 10.6457 4.19351 13.0913Z"
      fill={fill}
    />
  </svg>
)

export { Search }
