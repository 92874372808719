import { ApolloError } from '@apollo/client'
import { Body } from '@pelotoncycle/design-system'
import { ErrorContainer } from './ErrorContainer'

type TRequestErrorProps = {
  error: ApolloError
}

const RequestError = ({ error }: TRequestErrorProps) => {
  const { message, networkError } = error

  let statusText = ''
  if (
    networkError &&
    'response' in networkError &&
    typeof networkError.response.statusText === 'string'
  ) {
    statusText = networkError.response.statusText
  }

  return (
    <ErrorContainer>
      <Body>{message}</Body>
      {statusText && <Body data-testid="request-status-text">{statusText}</Body>}
    </ErrorContainer>
  )
}

export { RequestError }
