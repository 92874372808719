import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'

type TErrorLinkParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleError: (options?: any) => void
}

type TError = {
  statusCode: number
  name: string
}

const SINGLE_RETRY_STATUS_CODES = [401, 403, 429]
const MULTIPLE_RETRY_STATUS_CODES = [500, 502, 503, 504]

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
  },
  attempts: (count: number, _, error: TError) => {
    let retries = 0
    const isNetworkError = error && 'statusCode' in error
    const isRetryable4xx =
      isNetworkError && SINGLE_RETRY_STATUS_CODES.includes(error?.statusCode)
    const isRetryable5xx =
      isNetworkError && MULTIPLE_RETRY_STATUS_CODES.includes(error?.statusCode)

    if (isRetryable4xx) {
      retries = 1
    } else if (isRetryable5xx) {
      retries = 3
    }

    // error might be ServerError or ServerParseError
    return !!error && error?.name.indexOf('Server') > -1 && count <= retries
  },
})

const errorLink = ({ handleError }: TErrorLinkParams): ApolloLink => {
  return onError(({ networkError }) => {
    if (
      networkError &&
      'response' in networkError &&
      SINGLE_RETRY_STATUS_CODES.includes(networkError.response?.status)
    ) {
      handleError()
    }
  })
}

export { retryLink, errorLink }
