import { gql } from '@apollo/client'

const ELIGIBLE_PERSON_FIELDS = gql`
  fragment EligiblePersonFields on EligiblePerson {
    id
    firstName
    lastName
    emailAddress
    isClaimed
    enrollment {
      accessToken
      cmsRiderLink
    }
    currentEligibility {
      effectiveDate
      terminationDate
    }
    eligibilityPeriods {
      id
      effectiveDate
      terminationDate
    }
    sponsor {
      name
    }
    partner {
      name
      hashId
    }
  }
`

export { ELIGIBLE_PERSON_FIELDS }
