import { Factory } from 'fishery'
import { formatDatepickerDate } from 'utils/date'
import { EligiblePersons_eligiblePersonSearch as TEligiblePersonSearch } from '../queries/types/EligiblePersons'

const formattedToday = () => {
  return formatDatepickerDate(new Date())
}
type EligiblePersonTransientParams = {
  terminationDate?: string
}

const eligiblePersonFactory = Factory.define<
  TEligiblePersonSearch,
  EligiblePersonTransientParams
>(({ transientParams, sequence }) => {
  const { terminationDate } = transientParams

  return {
    id: `${sequence}`,
    firstName: `Eligible${sequence}`,
    lastName: `Person${sequence}`,
    emailAddress: `person${sequence}@company.com`,
    isClaimed: true,
    enrollment: {
      __typename: 'Enrollment',
      accessToken: `accessToken${sequence}`,
      cmsRiderLink: `cmsRiderLink${sequence}`,
    },
    currentEligibility: {
      __typename: 'EligibilityPeriod',
      effectiveDate: formattedToday(),
      terminationDate: terminationDate || formattedToday(),
    },
    eligibilityPeriods: [
      {
        __typename: 'EligibilityPeriod',
        id: 'abc123',
        effectiveDate: formattedToday(),
        terminationDate: terminationDate || formattedToday(),
      },
    ],
    sponsor: {
      __typename: 'Sponsor',
      name: 'Sponsor',
    },
    partner: {
      __typename: 'Partner',
      name: 'Partner',
      hashId: 'abc123def456',
    },
    __typename: 'EligiblePerson',
  }
})

export { eligiblePersonFactory }
