import { Headline } from '@pelotoncycle/design-system'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { useWindowWidth } from '../hooks'

type TPageProps = {
  children: ReactNode
  className?: string
  title?: string
  testId?: string
  renderBanner?: () => ReactNode
  renderTopContent?: () => ReactNode
  maxWidth?: string
}

type StyledPageProps = {
  position?: string
  padding?: string
  as?: string
  maxWidth?: string
}

const StyledPage = styled.div<StyledPageProps>`
  max-width: ${p => p.maxWidth};
  margin: auto;
  position: ${p => p.position};
  padding: ${p => p.padding};
`

const MAX_WIDTH_DEFAULT = '1000px'

const Page = ({
  children,
  className,
  renderBanner,
  renderTopContent,
  title,
  testId,
  maxWidth = MAX_WIDTH_DEFAULT,
}: TPageProps) => {
  const { isTablet, isMobile } = useWindowWidth()

  let pagePadding = '64px 0'

  if (isTablet) {
    pagePadding = '64px 16px'
  } else if (isMobile) {
    pagePadding = '32px 16px'
  }

  return (
    <>
      {renderBanner && <>{renderBanner()}</>}

      <StyledPage
        className={className}
        as="main"
        position="relative"
        padding={pagePadding}
        data-testid={testId}
        maxWidth={maxWidth}
      >
        {renderTopContent && <>{renderTopContent()}</>}

        {title && (
          <StyledHeadlineContainer>
            <Headline as="h1" size="small">
              {title}
            </Headline>
          </StyledHeadlineContainer>
        )}

        {children}
      </StyledPage>
    </>
  )
}

const StyledHeadlineContainer = styled.div`
  margin-bottom: 48px;
  position: relative;
  overflow-wrap: break-word;
`

export { Page, StyledPage }
