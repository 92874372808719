import { gql } from '@apollo/client'

const REQUEST_ERRORS = gql`
  fragment RequestErrors on RequestError {
    field
    message
  }
`

export { REQUEST_ERRORS }
