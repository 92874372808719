import { Factory } from 'fishery'
import { Partner_partner_program as TProgram } from '../queries/types/Partner'

const programFactory = Factory.define<TProgram>(({ sequence, associations }) => ({
  __typename: 'Program',
  id: `${sequence}`,
  name: `program-${sequence}`,
  slug: `program-${sequence}`,
  partnerHashId: `${sequence}`,
  monthsInactiveUntilSnooze: null,
  programBenefits: associations.programBenefits || [],
}))

export { programFactory }
