import React from 'react'
import { useTranslation } from 'react-i18next'
import { sectionSpacing, LI, UL } from '.'
import { InfoSection } from '..'

const FileSubmissionCadence = () => {
  const { t } = useTranslation()

  return (
    <InfoSection heading={t('file_submission_cadence')} marginBottom={sectionSpacing}>
      <UL>
        <LI>{t('support.submission_cadence.no_min_cadence_required')}</LI>
        <LI>{t('support.submission_cadence.min_timing_for_processing')}</LI>
        <LI>
          {t('support.submission_cadence.can_be_full_or_change_files')}
          <UL>
            <LI $indent>{t('support.submission_cadence.must_set_termination')}</LI>
          </UL>
        </LI>
      </UL>
    </InfoSection>
  )
}

export { FileSubmissionCadence }
