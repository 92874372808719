import {
  Headline,
  Body,
  Support,
  Eyebrow,
  Icon,
  IconNames,
  grey,
  white,
  data as dataColor,
} from '@pelotoncycle/design-system'
import { useChallenge, useTotals } from 'data/hooks'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { media } from 'ui/responsive'
import { FinishersExport } from './FinishersExport'

type Props = {
  label: string
  amount: number
  total: number
  icon: IconNames
  color: string
  dark?: boolean
  percentageLabel?: string | boolean
  showTotal?: boolean
}

type MetricProps = {
  challengeId: string
  showPercentage?: boolean
  dark?: boolean
}

type ProgressProps = {
  amount: number
  total: number
  color: string
  secondaryColor: string
}

const ProgressMetric: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  amount,
  total,
  icon,
  color,
  dark = false,
  percentageLabel = false,
  showTotal = true,
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <ProgressContainer>
        <Progress
          color={color}
          secondaryColor={dark ? '#393C3F' : grey[80]}
          amount={amount}
          total={total}
        />
      </ProgressContainer>
      <IconStyled height={50} name={icon} primaryColor={grey[60]} />
      <div aria-label={`${amount} of ${total} ${label}`}>
        <Amount aria-hidden="true" color={white} size="extraLarge">
          {amount.toLocaleString()}
        </Amount>
        {showTotal && (
          <Total aria-hidden="true" color={white} size="large">
            /{total.toLocaleString()}
          </Total>
        )}
      </div>
      <Label color={white} size="medium">
        {t(label)}
      </Label>
      {percentageLabel && (
        <Percentage size="large">
          <Trans
            i18nKey={percentageLabel as string}
            components={{ bold: <strong /> }}
            values={{
              percent: Math.round((amount / total) * 100),
            }}
          />
        </Percentage>
      )}
    </Container>
  )
}

const ParticipantsMetric: React.FC<MetricProps> = ({
  challengeId,
  showPercentage,
  dark = false,
}) => {
  const { challenge } = useChallenge({ challengeId })
  const { hasTotal, getTotal } = useTotals()

  if (!challenge || !hasTotal('enrolled_total')) {
    return null
  }

  const employeeCount = getTotal('enrolled_total')

  return (
    <ProgressMetric
      label="insights.employees_participated"
      icon="people"
      amount={challenge.participantCount}
      total={employeeCount}
      color={dataColor.orange}
      dark={dark}
      percentageLabel={showPercentage && 'insights.percent_participated'}
      showTotal={false}
    />
  )
}

const FinishersMetric: React.FC<MetricProps> = ({
  challengeId,
  showPercentage,
  dark = false,
}) => {
  const { challenge } = useChallenge({ challengeId })

  if (!challenge) {
    return null
  }

  return (
    <FinishersContainer>
      <ProgressMetric
        label="insights.challenge_finishers"
        icon="challengeBadge"
        amount={challenge.completedCount}
        total={challenge.participantCount}
        color={dataColor.teal}
        dark={dark}
        percentageLabel={showPercentage && 'insights.percent_finished'}
      />
      <FinishersExport challengeId={challengeId} />
    </FinishersContainer>
  )
}

const DaysMetric: React.FC<MetricProps> = ({
  challengeId,
  showPercentage,
  dark = false,
}) => {
  const { challenge, hasChallengeEnded } = useChallenge({ challengeId })

  if (!challenge) {
    return null
  }

  let daysLabel
  if (challenge.daysElapsed === challenge.daysTotal) {
    if (hasChallengeEnded) {
      daysLabel = 'insights.challenge_complete'
    } else {
      daysLabel = 'insights.final_day_of_challenge'
    }
  } else {
    daysLabel = 'insights.days_into_challenge'
  }

  return (
    <ProgressMetric
      label={daysLabel}
      icon="calendar"
      amount={challenge.daysElapsed}
      total={challenge.daysTotal}
      color={dataColor.darkYellow}
      dark={dark}
      percentageLabel={showPercentage && 'insights.percent_duration'}
    />
  )
}

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;

  ${media.atDesktop`
    width: 220px;
    margin-bottom: 0;
  `}
`

const FinishersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProgressContainer = styled.div`
  position: relative;
  width: 200px;
  height: 99px;
  top: 30px;
  overflow: hidden;
`

const Progress = styled.div<ProgressProps>`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 15px solid ${props => props.secondaryColor};
  border-bottom-color: ${props => props.color};
  border-right-color: ${props => props.color};
  transform: rotate(
    ${props => 45 + 1.8 * Math.min((props.amount / props.total) * 100 || 0, 100)}deg
  );
  transition: transform 750ms cubic-bezier(0.19, 1, 0.22, 1);
`

const IconStyled = styled(Icon)`
  margin-bottom: 10px;
`

const Amount = styled(Headline)`
  display: inline;
  margin-right: 5px;
  color: ${white};
`

const Total = styled(Body)`
  display: inline;
  color: ${white};
`

const Label = styled(Eyebrow)`
  color: ${white};
`

const Percentage = styled(Support)`
  margin-top: 0.5rem;
  color: ${white};
`

export { ParticipantsMetric, DaysMetric, FinishersMetric }
