import { LinkButton } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'

type TButtonProps = {
  to: string
}

const DownloadTemplateButton = ({ to }: TButtonProps) => {
  const { t } = useTranslation()

  return (
    <LinkButton
      width="fixed"
      href={to}
      text={t('download_csv_template').toUpperCase()}
      color="primary"
      variant="solid"
      size="medium"
      style={{ whiteSpace: 'nowrap' }}
    />
  )
}

export { DownloadTemplateButton }
