import { Headline } from '@pelotoncycle/design-system'
import { useFile } from 'data/hooks'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { formatDate, get } from 'utils'
import {
  Box,
  LinkBackTo,
  Loading,
  RequestError,
  EmptyState,
  Tile,
  ErrorsSection,
} from '../..'
import { media } from '../../../responsive'

interface RouteParams {
  fileId: string
}

const FileDetail = () => {
  const { fileId } = useParams<RouteParams>()
  const { loading, error, data } = useFile({
    fileId,
  })
  const { t } = useTranslation()

  if (loading) return <Loading />
  if (error) return <RequestError error={error} />

  const file = data?.file
  if (!file) return <EmptyState message="No file in response" />

  const { name, status, dataRows, fileRuns } = file
  /* need to fix graphene Date type being cast as 'any'. For now, workaround to avoid forbidden destructuring an any value (rolledBackOn) */
  const rolledBackDate = file?.rolledBackOn as string
  const lastFileRun = get(fileRuns, 0)

  const {
    eligibilityRemoved,
    eligibilityWillBeRemoved,
    eligibilityTotalAdded,
    totalRowErrors,
    fileRunErrors,
  } = lastFileRun

  const { fatalErrors, rowErrors } = fileRunErrors
  const hasFatalErrors = !!fatalErrors.length
  const hasRowErrors = !!rowErrors.length
  const failureDisplay = '--'
  const fileFailed = status === 'FAILED'
  const fileRolledBack = status === 'ROLLED_BACK'

  let date = lastFileRun.created as string
  if (fileRolledBack && rolledBackDate) {
    date = rolledBackDate
  }

  const statusText = t(`api.file_status.${status.toLowerCase()}`)
  let totalText = dataRows.toString()
  let removedText = eligibilityRemoved.toString()
  let addedText = eligibilityTotalAdded.toString()
  let dateSubtext = formatDate(new Date(date)).toString()
  let willBeRemovedText
  if (fileFailed) {
    totalText = failureDisplay
    removedText = failureDisplay
    addedText = failureDisplay
    dateSubtext = t('file_details.last_attempt_x', { date: dateSubtext })
  }

  if (eligibilityWillBeRemoved && eligibilityWillBeRemoved > 0) {
    willBeRemovedText = t('file_details.with_termination_date', {
      count: eligibilityWillBeRemoved,
    })
  }

  return (
    <div data-testid="file-details-page">
      <Box marginBottom="-8px">
        <LinkBackTo to="/" title={t('recent_uploads')} />
      </Box>

      <StyledHeadlineContainer>
        <Headline as="h2" size="small">
          {name}
        </Headline>
      </StyledHeadlineContainer>
      <StyledGrid as="section">
        <Tile subject="total" title={t('file.total_in_this_file')} text={totalText} />
        <Tile subject="dropped" title={t('file.dropped')} text={removedText} />
        <Tile
          subject="added"
          title={t('file.added')}
          text={addedText}
          subtext={willBeRemovedText}
        />
        <Tile
          subject="status"
          title={t('file.status')}
          text={statusText}
          subtext={dateSubtext}
          status={status}
        />
      </StyledGrid>

      {hasFatalErrors && (
        <ErrorsSection
          title={t('file_details.critical_error')}
          errors={fatalErrors}
          translationKey="fatal_errors"
          actionRequired
        />
      )}

      {!hasFatalErrors && hasRowErrors && (
        <ErrorsSection
          title={t('file_details.rows_with_errors', { total: totalRowErrors })}
          translationKey="row_errors"
          errors={rowErrors}
        />
      )}
    </div>
  )
}

const StyledGrid = styled.div`
  display: grid;
  row-gap: 8px;
  margin-bottom: 48px;

  ${media.atTablet`
    grid-template-columns: repeat(4, 24%);
    -ms-grid-rows: minmax(152px, auto);
    grid-auto-rows: minmax(152px, auto);
    justify-content: space-between;
  `}
`

const StyledHeadlineContainer = styled.div`
  margin-bottom: 48px;
  position: relative;
  overflow-wrap: break-word;
`

export { FileDetail }
