import { useHasDashboard } from 'data/hooks/useInsights'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Flex } from 'ui/components'
import {
  Challenges,
  ClassesCompletedAreaChart,
  ClassTypeDonut,
  DelayNotice,
  Disclaimer,
  EngagementRateProgressBar,
  EnrolledEngagedLineChart,
  EnrollmentRateProgressBar,
  MembershipTypeDonut,
  Module,
  Numbers,
  WorkoutMinutesAreaChart,
  WorkoutMinutesBarChart,
} from 'ui/components/Insights'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { media } from 'ui/responsive'

const Dashboard = () => {
  const { t } = useTranslation()
  const { partnerId } = usePartnerContext()
  const hasDashboard = useHasDashboard({ partnerId })

  if (!hasDashboard) {
    return (
      <>
        <DelayNotice />
        <Module empty emptyText={t('insights.empty_page')} emptyHeight="500px" />
        <Challenges />
      </>
    )
  }

  return (
    <>
      <DelayNotice />
      <Numbers />
      <InnerBars justifyContent="space-between">
        <EnrollmentRateProgressBar />
        <EngagementRateProgressBar />
      </InnerBars>
      <EnrolledEngagedLineChart />
      <Inner justifyContent="space-between">
        <ClassesCompletedAreaChart />
        <WorkoutMinutesAreaChart />
      </Inner>
      <Inner>
        <MembershipTypeDonut />
        <ClassTypeDonut />
      </Inner>
      <WorkoutMinutesBarChart />
      <Challenges />
      <Disclaimer />
    </>
  )
}

const Inner = styled(Flex)`
  flex-direction: column;

  & > div {
    flex-basis: 50%;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  ${media.atTablet`
    flex-direction: row;

    & > div {
      flex-basis: 50%;
      margin-right: 1.5rem;
    }
  `}
`

const InnerBars = styled(Inner)`
  flex-direction: column;

  ${media.atTablet`
    flex-direction: column;
  `}

  ${media.atDesktop`
    flex-direction: row;
  `}
`

export { Dashboard }
