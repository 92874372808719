import { useTotals, useClassesCompleted } from 'data/hooks/useInsights'
import { useTranslation } from 'react-i18next'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { Area, Datum } from './graphs'
import { Module } from './Module'

const ClassesCompletedAreaChart = () => {
  const { t } = useTranslation()
  const { partnerId } = usePartnerContext()
  const totals = useTotals({ partnerId })
  const classesCompleted = useClassesCompleted({ partnerId })

  const total = totals.getTotal('classes_completed_total')
  const hasData = totals.hasTotal('classes_completed_total') && classesCompleted.hasData
  const loading = totals.loading || classesCompleted.loading
  const empty = totals.error || classesCompleted.error || !hasData

  if (loading) {
    return <Module label="insights.total_classes_completed_empty" loading />
  }
  if (empty) {
    return <Module label="insights.total_classes_completed_empty" empty />
  }

  return (
    <Module
      label="insights.total_classes_completed"
      support="insights.total_classes"
      supportProps={{ num: total.toLocaleString() }}
      padding="30px 40px 10px"
    >
      <Area
        title={t('insights.total_classes_completed')}
        tooltipLabel="insights.class"
        data={classesCompleted.data as Datum[]}
      />
    </Module>
  )
}

export { ClassesCompletedAreaChart }
