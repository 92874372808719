import { data } from '@pelotoncycle/design-system'
import { useTotals } from 'data/hooks/useInsights'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { ProgressBar } from './graphs'
import { Module } from './Module'

const EngagementRateProgressBar = () => {
  const { partnerId } = usePartnerContext()
  const { getTotal, hasTotal, loading, error } = useTotals({ partnerId })

  const engaged = getTotal('engaged_total')
  const enrolled = getTotal('enrolled_total')
  const hasData =
    hasTotal('engaged_total') && hasTotal('enrolled_total') && engaged > 0 && enrolled > 0

  if (loading) {
    return <Module label="insights.engagement_rate" loading />
  }
  if (error || !hasData) {
    return <Module label="insights.engagement_rate" empty emptyHeight="100px" />
  }

  return (
    <Module label="insights.engagement_rate">
      <ProgressBar
        amount={engaged}
        total={enrolled}
        label="insights.employees_active_progress"
        color={data.teal}
      />
    </Module>
  )
}

export { EngagementRateProgressBar }
