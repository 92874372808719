import { gql } from '@apollo/client'
import { FILE_WITH_FILE_RUNS_AND_ERRORS } from '../fragments'

const GET_FILE = gql`
  ${FILE_WITH_FILE_RUNS_AND_ERRORS}
  query File($fileId: ID!) {
    file(fileId: $fileId) {
      ...FileWithFileRunsAndErrors
    }
  }
`

export { GET_FILE }
