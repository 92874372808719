import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'
import React, { ReactNode, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { Box } from './Box'
import { X } from './svg'

type ModalProps = {
  isOpen: boolean
  children: ReactNode
  handleClose: (e?: SyntheticEvent) => void
  showClose: boolean
}

const Modal = ({ isOpen, children, handleClose, showClose = true }: ModalProps) => {
  return (
    <StyledOverlay isOpen={isOpen} onDismiss={handleClose}>
      <StyledDialog>
        {showClose && (
          <StyledCloseButton onClick={handleClose}>
            <X />
          </StyledCloseButton>
        )}
        <StyledContents>{children}</StyledContents>
      </StyledDialog>
    </StyledOverlay>
  )
}

const StyledContents = styled(Box)`
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: inherit;
  min-height: inherit;
`

const StyledDialog = styled(DialogContent)`
  padding: 34px;
  position: relative;
  width: 460px;
  min-height: 200px;
  max-height: 70vh;
  border-radius: 5px;
`

const StyledCloseButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: -30px;
  right: 0;
  cursor: pointer;
`

const StyledOverlay = styled(DialogOverlay)`
  background: rgba(24, 26, 29, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`

export { Modal }
