import { ApolloError } from '@apollo/client'
import { Body, Icon, Support, green, grey, red, white } from '@pelotoncycle/design-system'
import { ValidCssColor } from '@pelotoncycle/design-system/types'
import { useEligiblePersonMutation, useEligiblePerson } from 'data/hooks'
import addDays from 'date-fns/addDays'
import isDate from 'date-fns/isDate'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/lib/style.css'
import parse from 'date-fns/parse'
import { forwardRef, ComponentPropsWithRef } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Calendar, XCircle } from 'ui/components/svg'
import { formatDatepickerDate, DATE_DISPLAY_FORMAT, DATE_API_FORMAT } from 'utils'

type TEligiblePersonRowProps = {
  id: string
  handleUpdated: (id: string) => void
}

type TDateDisplayProps = {
  date: string | null
} & ComponentPropsWithRef<'input'>

type TStatusTextProps = {
  date: string
  error: ApolloError | undefined
}

type TPlaceholderRowProps = {
  text?: string
}

const DateDisplay = forwardRef<HTMLButtonElement, TDateDisplayProps>(
  ({ value, onClick, date }, ref) => {
    const { t } = useTranslation()

    return (
      <StyledDateContainer onClick={onClick}>
        <button type="button" className="example-custom-input" ref={ref}>
          {date ? value : t('benefit_admin.date_format')}
        </button>
        <Calendar fill={grey[70]} />
      </StyledDateContainer>
    )
  },
)

const PlaceholderRow = ({ text }: TPlaceholderRowProps) => (
  <TableRow>
    <td />
    <td />
    <td>
      {text ? (
        <Support as="p" size="medium" textColor={red[80]}>
          {text}
        </Support>
      ) : (
        <Icon name="spinner" />
      )}
    </td>
  </TableRow>
)

const StatusText = ({ error, date }: TStatusTextProps) => {
  const STATUS_COLORS = {
    error: red[80],
    scheduled: green[50],
    unscheduled: grey[70],
  }

  const { t } = useTranslation()
  let statusTextColor: ValidCssColor = date
    ? STATUS_COLORS.scheduled
    : STATUS_COLORS.unscheduled

  let statusText = date
    ? t('benefit_admin.term_date_status.scheduled')
    : t('benefit_admin.term_date_status.unscheduled')

  if (error) {
    statusText = t('benefit_admin.term_date_status.error')
    statusTextColor = STATUS_COLORS.error
  }

  return (
    <Support as="p" size="medium" textColor={statusTextColor}>
      {statusText}
    </Support>
  )
}

const SearchResultRow = ({ id, handleUpdated }: TEligiblePersonRowProps) => {
  const { t } = useTranslation()
  const { data, error, loading } = useEligiblePerson({ id })
  const { updateEligiblePerson, error: mutationError } = useEligiblePersonMutation(id)

  const eligiblePerson = data?.eligiblePersonById

  if (error) return <PlaceholderRow text={t('benefit_admin.error_loading_employee')} />
  if (loading) return <PlaceholderRow />

  const terminationDate = eligiblePerson?.currentEligibility?.terminationDate as string

  const clearTerminationDate = (eligiblePersonId: string | undefined) => {
    if (eligiblePersonId) {
      updateEligiblePerson({ id: eligiblePersonId, terminationDate: '' })
        .then(() => {
          handleUpdated(eligiblePersonId)
        })
        .catch(e => {
          /* eslint-disable-next-line no-console */
          console.log('mutation e ==>', e)
        })
    }
  }

  const handleDateChange = (date: Date, eligiblePersonId: string | undefined) => {
    const formattedTerminationDate = formatDatepickerDate(date)
    if (eligiblePersonId) {
      updateEligiblePerson({
        id: eligiblePersonId,
        terminationDate: formattedTerminationDate,
      })
        .then(() => {
          handleUpdated(eligiblePersonId)
        })
        .catch(e => {
          /* eslint-disable-next-line no-console */
          console.log('mutation e ==>', e)
        })
    }
  }

  const minDate = addDays(new Date(), 1)
  let selectedDate = minDate

  if (terminationDate) {
    const parsedTerminationDate = parse(terminationDate, DATE_API_FORMAT, new Date())
    if (isDate(parsedTerminationDate)) {
      selectedDate = parsedTerminationDate
    }
  }

  return (
    <TableRow key={id} data-testid="search-result-row">
      <td>
        <StyledDatePicker
          placeholderText={DATE_DISPLAY_FORMAT}
          portalId="datepicker-outlet"
          allowSameDay
          selected={selectedDate}
          minDate={minDate}
          customInput={<DateDisplay date={terminationDate} />}
          onChange={(date: Date) => handleDateChange(date, eligiblePerson?.id)}
        />
        {terminationDate && (
          <StyledCloseButton
            type="button"
            onClick={() => clearTerminationDate(eligiblePerson?.id)}
            disabled={!terminationDate}
            aria-label={t('benefit_admin.remove_end_benefit_date')}
          >
            <XCircle backgroundFill={grey[60]} primaryFill={white} />
          </StyledCloseButton>
        )}
      </td>
      <td>
        <Body size="small" display="inline" textColor={grey[90]}>
          {eligiblePerson?.emailAddress}
        </Body>
      </td>
      <td>
        <StatusText error={mutationError} date={terminationDate} />
      </td>
    </TableRow>
  )
}

const TableRow = styled.tr`
  td {
    height: 88px;
    line-height: 88px;
    background-color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 16px;
    margin-top: 8px;

    &:first-child {
      position: relative;
    }
  }
`

const StyledDatePicker = styled(({ className, ...props }: ReactDatePickerProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DatePicker {...props} wrapperClassName={className} />
))`
  width: auto;

  &.react-datepicker-wrapper {
    width: auto;
  }
`

const StyledDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${grey[70]};
  border-radius: 2px;
  width: 160px;
  height: 56px;
  padding: 0 16px;
  margin-right: 16px;
`
const StyledCloseButton = styled.button`
  height: 16px;
  position: absolute;
  top: 50%;
  margin-top: -8px;

  &:disabled {
    cursor: default;
  }
`

export { SearchResultRow }
