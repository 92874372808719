import { grey, Support, Label } from '@pelotoncycle/design-system'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  amount: number
  total: number
  label: string
  color: string
}

type BarInnerProps = {
  width: string
  color: string
}

const ProgressBar: React.FC<React.PropsWithChildren<Props>> = ({
  amount,
  total,
  label,
  color,
}) => {
  const percentage = `${Math.round((amount / total) * 100)}%`

  return (
    <div>
      <Labels>
        <SupportStyled size="large" color={grey[90]}>
          <Trans
            i18nKey={label}
            components={{ bold: <strong /> }}
            values={{
              amount: amount.toLocaleString(),
              total: total.toLocaleString(),
            }}
          />
        </SupportStyled>
      </Labels>
      <BarContainer>
        <Bar>
          <BarInner width={percentage} color={color} />
        </Bar>
        <Label size="extraLarge" color={grey[90]}>
          {percentage}
        </Label>
      </BarContainer>
    </div>
  )
}

const BarContainer = styled.div`
  display: flex;
`

const Bar = styled.div`
  height: 24px;
  width: 100%;
  margin-right: 16px;
  border-radius: 3px;
  background: ${grey[30]};
`

const BarInner = styled.div<BarInnerProps>`
  height: 24px;
  width: ${props => props.width};
  max-width: 100%;
  background: ${props => props.color};
  border-radius: 3px;
`

const SupportStyled = styled(Support)`
  margin-bottom: 16px;
  color: ${grey[90]};
`

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export { ProgressBar }
