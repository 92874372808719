import { gql } from '@apollo/client'
import { PARTNERS_FIELDS } from '../fragments'

const GET_PARTNERS = gql`
  ${PARTNERS_FIELDS}
  query Partners($orderBy: String) {
    partners(orderBy: $orderBy) {
      ...PartnersFields
    }
  }
`

const GET_PARTNER_SEARCH = gql`
  ${PARTNERS_FIELDS}
  query PartnersSearch(
    $searchTerm: String
    $numOfResults: Int!
    $orderBy: PartnerOrderBy
  ) {
    partnersSearch(
      searchTerm: $searchTerm
      numOfResults: $numOfResults
      orderBy: $orderBy
    ) {
      ...PartnersFields
    }
  }
`

export { GET_PARTNERS, GET_PARTNER_SEARCH }
