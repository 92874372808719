import { data as dataColor, TwoColumnLayout } from '@pelotoncycle/design-system'
import React from 'react'
import styled from 'styled-components'
import { media } from 'ui/responsive'
import { VictoryPie } from 'victory'
import { ChartProps } from '../types'
import { Legend } from './Legend'

type DonutProps = {
  colorScale?: string[]
} & ChartProps

const defaultColorScale = [
  dataColor.teal,
  dataColor.lime,
  dataColor.darkYellow,
  dataColor.orange,
  dataColor.watermelon,
]

const Donut: React.FC<React.PropsWithChildren<DonutProps>> = ({
  title,
  data,
  colorScale = defaultColorScale,
}) => {
  return (
    <Container>
      <SvgContainer>
        <SvgInner>
          <Svg viewBox="0 120 160 160" role="img">
            <title>{title}</title>
            <VictoryPie
              standalone={false}
              width={160}
              padding={0}
              innerRadius={64}
              colorScale={colorScale}
              labels={() => ' '}
              data={data.map(({ x, y }) => ({ x, y }))}
            />
          </Svg>
        </SvgInner>
      </SvgContainer>
      <LegendContainer>
        <LegendInner>
          <Legend data={data} colorScale={colorScale} />
        </LegendInner>
      </LegendContainer>
    </Container>
  )
}

const Container = styled(TwoColumnLayout)`
  margin-top: 1.5rem;
`

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SvgInner = styled.div`
  display: flex;
  align-content: center;
`

const Svg = styled.svg`
  width: 200px;
`

const LegendContainer = styled.div`
  display: flex;
  margin: 2rem 0 -1.25rem;
  justify-content: center;

  ${media.atDesktop`
    margin: 0
  `}
`

const LegendInner = styled.div`
  display: flex;
  align-items: center;
`

export { Donut }
