import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { translation as german } from './de/translation.js'
import { translation as english } from './en/translation.js'

const resources = {
  en: {
    translation: english,
  },
  de: {
    translation: german,
  },
} as const

const format = (val: string, fmt: string | undefined) => {
  if (fmt === 'replaceUnderscores') {
    if (val === 'internal_id') {
      return val.replace(/(internal_id)/g, 'internal ID')
    }

    return val.replace(/_/g, ' ')
  }

  return val
}

const options = {
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
    format,
  },
}

const i18nInstance = i18n.use(initReactI18next).init(options)

// eslint-disable-next-line import/no-default-export
export default i18nInstance
