import { epoch, today } from 'data/hooks/useInsights'
import {
  isSameYear,
  addYears,
  getYear,
  isSameQuarter,
  addQuarters,
  startOfQuarter,
  endOfQuarter,
  format,
} from 'date-fns'

const yearsSinceEpoch: string[] = []
for (let year = epoch; !isSameYear(year, addYears(today, 1)); year = addYears(year, 1)) {
  yearsSinceEpoch.push(getYear(year).toString())
}

const quartersSinceEpoch: string[] = []
for (
  let quarter = epoch;
  !isSameQuarter(quarter, addQuarters(today, 1));
  quarter = addQuarters(quarter, 1)
) {
  const quarterStart = startOfQuarter(quarter)
  const quarterEnd = endOfQuarter(quarter)
  const quarterString = `${format(quarterStart, 'MMM')}-${format(
    quarterEnd,
    'MMM',
  )} ${format(quarter, 'yyyy')}`
  quartersSinceEpoch.push(quarterString)
}

export { epoch, today, yearsSinceEpoch, quartersSinceEpoch }
