import { gql } from '@apollo/client'
import { CONTACT_FIELDS } from '../fragments'

const CREATE_PARTNER_CONTACT = gql`
  ${CONTACT_FIELDS}
  mutation PartnerContactCreate($input: PartnerContactInput!, $partnerId: ID!) {
    contactCreate(contactInput: $input, partnerId: $partnerId) {
      requestOk
      contact {
        ...ContactFields
        partner {
          id
        }
      }
    }
  }
`

const UPDATE_PARTNER_CONTACT = gql`
  ${CONTACT_FIELDS}
  mutation PartnerContactUpdate($input: PartnerContactInput!, $contactId: ID!) {
    contactUpdate(contactInput: $input, contactId: $contactId) {
      requestOk
      contact {
        ...ContactFields
      }
    }
  }
`

const DELETE_PARTNER_CONTACT = gql`
  mutation PartnerContactDelete($contactId: ID!) {
    contactDelete(contactId: $contactId) {
      requestOk
    }
  }
`

export { CREATE_PARTNER_CONTACT, UPDATE_PARTNER_CONTACT, DELETE_PARTNER_CONTACT }
