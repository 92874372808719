import { black } from '@pelotoncycle/design-system'
import { IconProps } from './IconTypes'

const Spreadsheet = ({ width = '40px', height = '42px', fill = black }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 40 42" fill="none">
    <path
      d="M36.8176 0H28.9882H19.5412H10.0941H2.26471C0.970588 0 0 1.03529 0 2.26471V7.31176V12.9412V18.5059V24.0706V29.6353V35.2V39.1471C0 40.4412 1.03529 41.4118 2.26471 41.4118H10.0941H19.5412H28.9882H36.8176C38.1118 41.4118 39.0824 40.3765 39.0824 39.1471V35.2V29.6353V24.0706V18.5059V12.9412V7.37647V2.26471C39.0824 1.03529 38.0471 0 36.8176 0ZM37.7882 12.2941H29.6353V8.02353H37.7882V12.2941ZM37.7882 17.8588H29.6353V13.5882H37.7882V17.8588ZM37.7882 23.4235H29.6353V19.1529H37.7882V23.4235ZM37.7882 28.9882H29.6353V24.7176H37.7882V28.9882ZM1.29412 24.7176H9.44706V28.9882H1.29412V24.7176ZM1.29412 19.1529H9.44706V23.4235H1.29412V19.1529ZM1.29412 13.5882H9.44706V17.8588H1.29412V13.5882ZM10.7412 19.1529H18.8941V23.4235H10.7412V19.1529ZM20.1882 19.1529H28.3412V23.4235H20.1882V19.1529ZM28.3412 17.8588H20.1882V13.5882H28.3412V17.8588ZM18.8941 17.8588H10.7412V13.5882H18.8941V17.8588ZM10.7412 24.7176H18.8941V28.9882H10.7412V24.7176ZM20.1882 24.7176H28.3412V28.9882H20.1882V24.7176ZM28.3412 12.2941H20.1882V8.02353H28.3412V12.2941ZM18.8941 12.2941H10.7412V8.02353H18.8941V12.2941ZM9.44706 12.2941H1.29412V8.02353H9.44706V12.2941ZM1.29412 30.2824H9.44706V34.5529H1.29412V30.2824ZM10.7412 30.2824H18.8941V34.5529H10.7412V30.2824ZM20.1882 30.2824H28.3412V34.5529H20.1882V30.2824ZM29.6353 30.2824H37.7882V34.5529H29.6353V30.2824ZM37.7882 2.26471V6.66471H29.6353V1.29412H36.8176C37.3353 1.29412 37.7882 1.74706 37.7882 2.26471ZM28.3412 6.72941H20.1882V1.29412H28.3412V6.72941ZM18.8941 6.72941H10.7412V1.29412H18.8941V6.72941ZM1.29412 2.26471C1.29412 1.74706 1.74706 1.29412 2.26471 1.29412H9.44706V6.72941H1.29412V2.26471ZM1.29412 39.1471V35.8471H9.44706V40.1176H2.26471C1.74706 40.1176 1.29412 39.6647 1.29412 39.1471ZM10.7412 35.8471H18.8941V40.1176H10.7412V35.8471ZM20.1882 35.8471H28.3412V40.1176H20.1882V35.8471ZM37.7882 39.1471C37.7882 39.7294 37.3353 40.1176 36.8176 40.1176H29.6353V35.8471H37.7882V39.1471Z"
      fill={fill}
    />
    <path
      d="M13.5236 4.65883H16.1118C16.5 4.65883 16.7589 4.40001 16.7589 4.01177C16.7589 3.62354 16.5 3.36471 16.1118 3.36471H13.5236C13.1353 3.36471 12.8765 3.62354 12.8765 4.01177C12.8765 4.40001 13.1353 4.65883 13.5236 4.65883ZM22.9706 4.65883H25.5589C25.9471 4.65883 26.2059 4.40001 26.2059 4.01177C26.2059 3.62354 25.9471 3.36471 25.5589 3.36471H22.9706C22.5824 3.36471 22.3236 3.62354 22.3236 4.01177C22.3236 4.40001 22.5824 4.65883 22.9706 4.65883ZM32.4177 4.65883H35.0059C35.3942 4.65883 35.653 4.40001 35.653 4.01177C35.653 3.62354 35.3942 3.36471 35.0059 3.36471H32.4177C32.0295 3.36471 31.7706 3.62354 31.7706 4.01177C31.7706 4.40001 32.0942 4.65883 32.4177 4.65883ZM4.20592 3.42942C4.0118 3.30001 3.68828 3.36471 3.55886 3.55883C3.49416 3.62354 3.42945 3.68824 3.36475 3.75295C3.36475 3.81765 3.36475 3.94707 3.36475 4.01177C3.36475 4.20589 3.42945 4.3353 3.55886 4.46471C3.55886 4.52942 3.68828 4.59412 3.75298 4.59412C3.81769 4.65883 3.88239 4.65883 4.0118 4.65883C4.14122 4.65883 4.20592 4.65883 4.27063 4.59412C4.33533 4.59412 4.40004 4.52942 4.46475 4.46471C4.59416 4.3353 4.65886 4.20589 4.65886 4.01177C4.65886 3.81765 4.59416 3.68824 4.46475 3.55883C4.40004 3.49412 4.33533 3.42942 4.20592 3.42942ZM6.98828 3.42942C6.72945 3.30001 6.47063 3.36471 6.27651 3.55883C6.2118 3.62354 6.1471 3.68824 6.1471 3.75295C6.1471 3.81765 6.08239 3.94707 6.08239 4.01177C6.08239 4.20589 6.1471 4.3353 6.27651 4.46471C6.40592 4.59412 6.60004 4.65883 6.72945 4.65883C6.79416 4.65883 6.92357 4.65883 6.98828 4.59412C7.05298 4.59412 7.11769 4.52942 7.18239 4.46471C7.3118 4.3353 7.37651 4.20589 7.37651 4.01177C7.37651 3.81765 7.3118 3.68824 7.18239 3.55883C7.11769 3.49412 7.05298 3.42942 6.98828 3.42942Z"
      fill={fill}
    />
  </svg>
)

export { Spreadsheet }
