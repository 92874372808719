import { data } from '@pelotoncycle/design-system'
import { useTotals } from 'data/hooks/useInsights'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { ProgressBar } from './graphs'
import { Module } from './Module'

const EnrollmentRateProgressBar = () => {
  const { partnerId } = usePartnerContext()
  const { getTotal, hasTotal, loading, error } = useTotals({ partnerId })

  const enrolled = getTotal('enrolled_total')
  const eligible = getTotal('eligible_total')
  const hasData =
    hasTotal('enrolled_total') &&
    hasTotal('eligible_total') &&
    enrolled > 0 &&
    eligible > 0

  if (loading) {
    return <Module label="insights.enrollment_rate" loading />
  }
  if (error || !hasData) {
    return <Module label="insights.enrollment_rate" empty emptyHeight="100px" />
  }

  return (
    <Module label="insights.enrollment_rate">
      <ProgressBar
        amount={enrolled}
        total={eligible}
        label="insights.employees_applied_progress"
        color={data.orange}
      />
    </Module>
  )
}

export { EnrollmentRateProgressBar }
