import { Support, grey } from '@pelotoncycle/design-system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const DelayNotice = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Support as="p" size="small" textColor={grey[70]}>
        {t('insights.delay_notice')}
      </Support>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 1.5rem;
  text-align: right;
`

export { DelayNotice }
