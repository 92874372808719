import styled from 'styled-components'
import { Box } from './Box'

type FlexProps = {
  flexDirection?: string
  alignItems?: string
  justifyContent?: string
  flexWrap?: string
  flexFlow?: string
}

const Flex = styled(Box)`
  display: flex;
  flex-direction: ${(props: FlexProps) => props.flexDirection};
  justify-content: ${(props: FlexProps) => props.justifyContent};
  align-items: ${(props: FlexProps) => props.alignItems};
  flex-wrap: ${(props: FlexProps) => props.flexWrap};
  flex-flow: ${(props: FlexProps) => props.flexFlow};
`

export { Flex }
