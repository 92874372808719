import { yellow, grey, white } from '@pelotoncycle/design-system'
import { FileStatus } from 'data/types/graphql-global-types'
import React from 'react'
import { BangCircle, CheckCircleFill, Processing, XCircle } from './svg'

const StatusIcon = ({ status }: { status: FileStatus }) => {
  let icon

  switch (status) {
    case FileStatus.RUNNING:
      icon = <Processing animate />
      break
    case FileStatus.SUCCEEDED:
      icon = <CheckCircleFill />
      break
    case FileStatus.SUCCEEDED_WITH_ERRORS:
      icon = <BangCircle fill={yellow[80]} />
      break
    case FileStatus.ROLLED_BACK:
      icon = <XCircle primaryFill={white} backgroundFill={grey[60]} />
      break
    case FileStatus.FAILED:
    default:
      icon = <BangCircle />
  }

  return icon
}

export { StatusIcon }
