import { Eyebrow, Support, grey } from '@pelotoncycle/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from 'ui/components'
import { useWindowWidth } from 'ui/hooks'
import { media } from '../../../../responsive'
import { SearchResultRow } from './SearchResultRow'

type TResultsTableProps = {
  results: string[]
  onUpdateItem: (item: string) => void
}

const SearchResultsTable = ({ results, onUpdateItem }: TResultsTableProps) => {
  const { t } = useTranslation()
  const hasResults = !!results.length
  const { isAboveMobile } = useWindowWidth()

  return (
    <>
      <TableWrapper>
        <TableGrid>
          <thead>
            <tr>
              <th>
                <Eyebrow size="small">{t('benefit_admin.offboarding_date')}</Eyebrow>
              </th>
              <th>
                <Eyebrow size="small">{t('benefit_admin.employee_email')}</Eyebrow>
              </th>
              <th>
                <Eyebrow size="small">{t('benefit_admin.status')}</Eyebrow>
              </th>
            </tr>
          </thead>
          {hasResults && (
            <tbody>
              {results.map(result => (
                <SearchResultRow key={result} id={result} handleUpdated={onUpdateItem} />
              ))}
            </tbody>
          )}
        </TableGrid>
      </TableWrapper>
      <div id="datepicker-outlet" />

      {!hasResults && (
        <StyledEmptyState data-testid="none-selected">
          {t('benefit_admin.no_employees_selected')}
        </StyledEmptyState>
      )}
      <Box width={isAboveMobile ? '50%' : '100%'} marginTop="24px">
        <Support as="p" size="large">
          {t('benefit_admin.update_explanation')}
        </Support>
      </Box>
    </>
  )
}

const TableGrid = styled.table`
  display: grid;
  border-collapse: separate;
  min-width: 100%;
  empty-cells: hide;
  grid-template-columns: minmax(232px, 1fr) minmax(200px, 1fr) minmax(220px, 1fr);

  ${media.atTablet`
    grid-template-columns: minmax(250px,1fr) 3fr 2fr;
  `}

  thead,
  tbody,
  tr {
    display: contents;
  }

  thead {
    padding-left: 16px;
  }

  th,
  .th {
    background-color: ${grey[40]};
    height: 32px;
    line-height: 32px;
    text-align: left;
    padding: 0 16px;
    white-space: nowrap;
  }
`
const StyledEmptyState = styled(Box)`
  height: 88px;
  line-height: 88px;
  border: 1px solid ${grey[40]};
  padding-left: 16px;
  font-size: 14px;
  color: ${grey[90]};
  font-style: italic;
  margin-top: 8px;
`

const TableWrapper = styled.div`
  overflow-x: auto;
`

export { SearchResultsTable }
