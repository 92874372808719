import { data as dataColor } from '@pelotoncycle/design-system'
import { useTotals } from 'data/hooks/useInsights'
import { useTranslation } from 'react-i18next'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { Donut, Datum } from './graphs'
import { Module } from './Module'

const MembershipTypeDonut = () => {
  const { t } = useTranslation()

  const { partnerId } = usePartnerContext()
  const { getTotal, hasTotal, loading, error } = useTotals({ partnerId })

  const aamUsers = getTotal('aam_users_total')
  const appUsers = getTotal('app_users_total')
  const membershipTotal = aamUsers + appUsers
  const hasData = hasTotal('aam_users_total') || hasTotal('app_users_total')

  if (loading) {
    return <Module label="insights.membership_types" loading />
  }
  if (error || !hasData) {
    return <Module label="insights.membership_types" empty />
  }

  const data = [
    { x: 1, y: appUsers, label: 'App' },
    { x: 2, y: aamUsers, label: 'All-Access' },
  ].sort((a, b) => {
    if (b.y > a.y) {
      return 1
    }
    if (a.y > b.y) {
      return -1
    }

    return 0
  })

  return (
    <Module
      label="insights.membership_types"
      support="insights.total_memberships"
      supportProps={{ num: membershipTotal.toLocaleString() }}
    >
      <Donut
        title={t('insights.membership_types')}
        data={data as Datum[]}
        colorScale={[dataColor.teal, dataColor.darkYellow]}
      />
    </Module>
  )
}

export { MembershipTypeDonut }
