import { gql } from '@apollo/client'
import { ELIGIBLE_PERSON_FIELDS } from '../fragments'

const UPDATE_ELIGIBLE_PERSON = gql`
  ${ELIGIBLE_PERSON_FIELDS}
  mutation EligiblePersonUpdate(
    $id: String!
    $updateData: EligiblePersonUpdateDataInput!
  ) {
    eligiblePersonUpdate(personId: $id, updateData: $updateData) {
      eligiblePerson {
        ...EligiblePersonFields
      }
    }
  }
`

export { UPDATE_ELIGIBLE_PERSON }
