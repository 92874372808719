import { grey } from '@pelotoncycle/design-system'
import styled from 'styled-components'

const Screen = styled.div`
  width: 100%;
  min-width: 420px;
  position: relative;
  min-height: 100vh;
  background-color: ${grey[20]};
`

export { Screen }
