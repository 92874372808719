import { useQuery } from '@apollo/client'
import { GET_REPORTS } from 'data/queries'
import { Reports } from '../../data/queries/types/Reports'

const useEnrollmentReports = () => {
  const { loading, error, data } = useQuery<Reports>(GET_REPORTS)

  return {
    loading,
    error,
    data,
  }
}

export { useEnrollmentReports }
