import { useClassTypes } from 'data/hooks/useInsights'
import { useTranslation } from 'react-i18next'
import { usePartnerContext } from 'ui/components/PartnerContext'
import { Donut, Datum } from './graphs'
import { Module } from './Module'

const ClassTypeDonut = () => {
  const { t } = useTranslation()
  const { partnerId } = usePartnerContext()
  const { loading, error, data, total, hasData } = useClassTypes({ partnerId })

  if (loading) {
    return <Module label="insights.class_types" loading />
  }
  if (error || !hasData) {
    return <Module label="insights.class_types" empty />
  }

  return (
    <Module
      label="insights.class_types"
      support="insights.total_classes"
      supportProps={{ num: total.toLocaleString() }}
    >
      <Donut title={t('insights.class_types')} data={data as Datum[]} />
    </Module>
  )
}

export { ClassTypeDonut }
