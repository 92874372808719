import * as React from 'react'

type TContext = {
  partnerId?: string
  updatePartnerId: (id: string) => void
}

type TProviderProps = {
  children: React.ReactNode
}

const PartnerContext = React.createContext<TContext | undefined>(undefined)

const PartnerProvider = ({ children }: TProviderProps) => {
  const [partnerId, setPartnerId] = React.useState<string>()

  const updatePartnerId = (id: string) => {
    setPartnerId(id)
  }

  const context = React.useMemo(
    () => ({
      partnerId,
      updatePartnerId,
    }),
    [partnerId],
  )

  return <PartnerContext.Provider value={context}>{children}</PartnerContext.Provider>
}

export { PartnerContext, PartnerProvider }
