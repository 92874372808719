import { useQuery } from '@apollo/client'
import { File } from 'data/queries/types/File'
import { GET_FILE } from '../queries'

type TFileProps = {
  fileId?: string
  partnerId?: string
  runCount?: number
}

const useFile = ({ fileId, partnerId, runCount }: TFileProps) => {
  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery<File>(
    GET_FILE,
    {
      variables: { fileId, partnerId, runCount },
      skip: !fileId,
    },
  )

  return {
    loading,
    error,
    data,
    refetch,
    startPolling,
    stopPolling,
  }
}

export { useFile }
